// BookingShow.jsx

import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  NumberField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import { Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from "@windmill/react-ui";

const UserShow = ({ props }) => {
  const AddressDetails = () => {
    const record = useRecordContext();
    const { data, error } = useGetManyReference("customers/address", {
      target: "customer_id",
      id: record.id,
      sort: { field: "created_at", order: "DESC" },
    });
    if (error || data === undefined) {
      return <h2>No addresses retrieved</h2>;
    }
    if (data) {
      return (
        <>
          <TableContainer>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell>Address Line 1</TableCell>
                  <TableCell>Address Line 2</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Postcode</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((address) => (
                  <TableRow key={address.id}>
                    <TableCell>
                      <span className="text-sm">{address.address_line1}</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{address.address_line2}</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{address.address_city}</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {address.address_postcode ? address.address_postcode.toUpperCase() : null}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{address.address_country}</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold ml-2">{address.address_type}</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {address.created_at ? new Date(address.created_at).toLocaleDateString("en-GB") : null}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  };
  return (
    <>
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label="Customer">
            <TextField source="id" label="Customer ID" emptyText="-" />
            <TextField source="first_name" emptyText="-" />
            <TextField source="last_name" emptyText="-" />
            <TextField source="gender" emptyText="-" />
            <DateField source="date_of_birth" emptyText="-" />
            <EmailField label="Email" source="username" emptyText="-" />
            <TextField source="contact_number" emptyText="-" />
            <TextField source="company" emptyText="-" />
            <BooleanField label="Email Consent" source="marketing_subscription" emptyText="-" />
            <BooleanField source="set_verified" label="Verified" emptyText="-" />
            <BooleanField source="active" emptyText="-" />
          </Tab>
          <Tab label="Address">
            <AddressDetails />
          </Tab>
          <Tab label="Reward">
            <TextField source="reward_id" label="Reward ID" emptyText="-" />
            <NumberField source="reward_points" emptyText="0" />
            <BooleanField source="reward_status" emptyText="-" />
          </Tab>
          <Tab label="Membership">
            <ReferenceManyField reference="memberships" target="customer_id" label="Memberships">
              <Datagrid bulkActionButtons={false}>
                <TextField source="membership_id" />
                <TextField source="membership_type" />
                <DateField source="membership_start" />
                <DateField source="membership_end" />
                <TextField source="membership_status" />
                <TextField source="membership_price" />
                <TextField source="billing_period" />
                <DateField source="created_at" />
              </Datagrid>
            </ReferenceManyField>
            <ReferenceManyField reference="memberships" target="customer_id" label="Membership Benefits">
              <Datagrid bulkActionButtons={false}>
                <TextField source="benefit_id" />
                <TextField source="membership_id" />
                <NumberField source="service_discount" label="Service discount (%)" emptyText="0" />
                <TextField source="free_gp_consultation" emptyText="0" />
                <TextField source="specialist_imaging_referral" emptyText="0" />
                <TextField source="medical_interpreter_used" emptyText="0" />
                <TextField source="medical_insurance_claim" emptyText="0" />
                <BooleanField source="benefit_status" />
                <BooleanField source="allow_sharing" />
                <DateField source="created_at" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default UserShow;
