import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Scheduler } from "smart-webcomponents-react/scheduler";
import { getRequest } from "../../helpers/requests";
import Loading from "../../components/preloader/Loading";
import { convertData } from "./helper";

const ClinicianAvailability = () => {
  const scheduler = useRef();
  const calendar = useRef();
  const primaryContainer = useRef();

  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [people, setPeople] = useState([]);
  const [defaultPeople, setDefaultPeople] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [locationFilter, setLocationFilter] = useState([]);

  const locationTags = [
    "London",
    "Cambridge",
    "Cardiff",
    "Edinburgh",
    "Glasgow",
    "Manchester",
    "Leeds",
    "Wales",
    "Coventry",
    "Newcastle",
    "Liverpool",
    "Essex",
    "Exeter",
    "Devon",
    "Colchester",
    "Malpas",
    "Yorkshire",
    "Bradford",
    "Blackwood",
  ];

  const MenuFilterProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const today = new Date();
  const currentDate = today.getDate();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentHours = today.getHours();
  const currentMinutes = today.getMinutes();

  useEffect(() => {
    const fetchClinicians = async () => {
      try {
        const clinicians = await getRequest("users/clinicians");
        const list = clinicians.clinician;
        setPeople(list);
        setDefaultPeople(list);
      } catch (error) {
        console.error("Error fetching clinicians:", error);
      } finally {
        setDataLoaded(true);
      }
    };
    fetchClinicians();
  }, []);

  /**** Start - Scheduler Component config ****/
  const view = "week";
  const views = [
    "day",
    {
      type: "week",
    },
    {
      type: "month",
    },
    "agenda",
    {
      label: "4 days",
      value: "workWeek",
      type: "week",
      shortcutKey: "X",
      hideWeekend: false,
      hideNonworkingWeekdays: true,
    },
  ];

  const hideAllDay = true;
  const firstDayOfWeek = 1;
  const disableDateMenu = false;
  const shadeUntilCurrentTime = true;
  const currentTimeIndicator = true;
  const scrollButtonsPosition = "far";
  /**** End - Scheduler Component config ****/

  const updateData = (e) => {
    const item = e.detail.item;

    for (let i = 0; i < availability.length; i++) {
      const dataItem = availability[i];

      if (dataItem.label === item.label && dataItem.class === item.class) {
        e.type === "itemRemove" ? availability.splice(i, 1) : availability.splice(i, 1, item);
        return;
      }
    }
  };

  const addNew = () => {
    scheduler.current.openWindow({
      class: "event",
    });
  };

  const handleDateChange = (e) => {
    scheduler.current.selectedDates = [e.detail.value];
  };

  const toggleAccordionContent = async (id) => {
    setLoading(true);
    const request = await getRequest(`bookings/availability/5/clinician/${id}`);
    if (request) {
      const availability = convertData(request);
      setAvailability(availability);
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilter(typeof value === "string" ? value.split(",") : value);
    if (value.length === 0) {
      setPeople(defaultPeople);
    } else {
      const filteredData = people.filter(
        (person) => person.tags && value.map((location) => location.toLowerCase()).includes(person.tags.toLowerCase()),
      );
      setPeople(filteredData);
    }
  };

  return (
    <div className="m-5">
      <div className="grid grid-cols-2 mb-2">
        <div className="col-start-1 col-end-2 flex justify-start">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField variant="outlined" label="Search postcode"></TextField>
          </Box>
        </div>
        <div className="col-end-3 flex justify-end">
          <FormControl sx={{ m: 1, width: 350 }}>
            <InputLabel>Location</InputLabel>
            <Select
              multiple
              value={locationFilter}
              onChange={handleFilterChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuFilterProps}
            >
              {locationTags.map((location) => (
                <MenuItem key={location} value={location}>
                  <Checkbox checked={locationFilter.indexOf(location) > -1} />
                  <ListItemText primary={location} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {dataLoaded ? (
        people.map((person) => (
          <Accordion
            key={person.user_id}
            onChange={(event, isExpanded) => {
              event.stopPropagation();
              if (isExpanded) {
                setOpenAccordion(person.user_id);
                toggleAccordionContent(person.user_id);
              } else {
                setOpenAccordion(null);
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <Typography variant="subtitle1" fontWeight="bold">
                    {person.full_name ? person.full_name : person.first_name + " " + person.last_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {person.username} | {person.address}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {openAccordion === person.user_id && loading && <Loading />}
              {openAccordion === person.user_id && !loading && (
                <div className="flex flex-col">
                  <Scheduler
                    ref={scheduler}
                    id="scheduler"
                    dataSource={availability}
                    view={view}
                    views={views}
                    hideAllDay={hideAllDay}
                    // nonworkingDays={nonworkingDays}
                    firstDayOfWeek={firstDayOfWeek}
                    disableDateMenu={disableDateMenu}
                    currentTimeIndicator={currentTimeIndicator}
                    scrollButtonsPosition={scrollButtonsPosition}
                    // onDragEnd={updateData}
                    // onResizeEnd={updateData}
                    onItemUpdate={updateData}
                    onItemRemove={updateData}
                    onDateChange={handleDateChange}
                    shadeUntilCurrentTime={shadeUntilCurrentTime}
                    disableWindowEditor
                  ></Scheduler>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <>
          <Loading />
        </>
      )}
    </div>
  );
};

export default ClinicianAvailability;
