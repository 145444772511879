import {
  Button,
  Card,
  CardBody,
  Label,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "@windmill/react-ui";
import { useEffect, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

//internal import
import NotFound from "components/table/NotFound";
import PageTitle from "components/Typography/PageTitle";
import TableLoading from "components/preloader/TableLoading";
import { notifyError, notifySuccess } from "utils/toast";
import spinnerLoadingImage from "assets/img/spinner.gif";
import { getRequest, postRequest } from "../../helpers/requests";
import SearchableDropdown from "../../components/searchable-dropdown";
import "./Documents.scss";

const Documents = () => {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState(null);
  const [customers, setCustomers] = useState([]);

  // Upload document hooks
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [documentLocation, setDocumentLocation] = useState(null);
  const [documentExtension, setDocumentExtension] = useState(null);
  const [customerAccess, setCustomerAccess] = useState("true");
  const [file, setFile] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchDocumentList = async () => {
      const response = await getRequest("documents/list");
      if (response) {
        setDocuments(response);
        setLoading(false);
      }
    };
    fetchDocumentList();
  }, []);

  useEffect(() => {
    const fetchCustomerList = async () => {
      const response = await getRequest("customers/list");
      if (response) {
        setCustomers(response);
      }
    };
    fetchCustomerList();
  }, []);

  const dataTableHeaders = {
    document_id: "Document ID",
    customer_id: "Customer ID",
    email_address: "Email Address",
    file_name: "File Name",
    // "file_extension": "File Extension",
    document_type: "Document Type",
    file_location: "File Storage",
    customer_access: "Customer Access",
    deletion_request: "Deletion Request",
    created_at: "Uploaded At",
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmitForAll = async (e) => {
    e.preventDefault();

    // Check if there's a file selected
    if (!file || !selectedCustomer) {
      // Handle the case where no file is selected (you can show an error message)
      notifyError("Please upload file and assign a customer to it!");
      return;
    }

    // Set loading state
    setLoadingUpload(true);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("customer", selectedCustomer.customer_id);
    formData.append("customerEmail", selectedCustomer.email_address);
    formData.append("documentType", documentType);
    formData.append("documentLocation", documentLocation);
    formData.append("customerAccess", customerAccess);
    formData.append("documentExtension", documentExtension);
    formData.append("file", file);

    const response = await postRequest("documents/upload", formData, "form-data");
    if (response && response.message === "success") {
      notifySuccess("Document has been uploaded successfully");
      setLoadingUpload(false);
      window.location.reload();
    } else {
      notifyError("Upload failed, please try again!");
    }
  };

  // Copy filename on click
  const handleFileNameClick = (fileName) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(fileName)
        .then(() => {
          notifySuccess(`Copied: ${fileName}`);
        })
        .catch((error) => {
          notifyError("Copy failed: " + error.message);
        });
    } else {
      notifyError("Copy not supported");
    }
  };

  function renderField(field, document) {
    if (field === "customer_access" || field === "deletion_request") {
      return document[field] ? "✔️" : "❌";
    } else if (field === "created_at") {
      const parsedDate = new Date(document[field]);
      return parsedDate
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        .replace(",", "")
        .replace(/(\d)\s?(AM|PM)/, "$1$2");
    } else if (field === "file_name") {
      const fullFileName = document[field];
      const maxLength = 24;
      if (fullFileName.length <= maxLength) {
        return fullFileName; // If the filename is short, display it as is
      } else {
        const start = fullFileName.substring(0, maxLength / 2);
        const end = fullFileName.substring(fullFileName.length - maxLength / 2);
        return (
          <span title={fullFileName} style={{ cursor: "pointer" }} onClick={() => handleFileNameClick(fullFileName)}>
            {start}...{end}
          </span>
        );
      }
    } else {
      return document[field];
    }
  }

  return (
    <>
      <PageTitle>{"Documents"}</PageTitle>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardBody>
          <form onSubmit={handleSubmitForAll}>
            <div className="grid gap-4 lg:gap-6 xl:gap-6 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-2">
              <div>
                <Label>Customer*</Label>
                <SearchableDropdown
                  options={customers}
                  label="name"
                  id="id"
                  selectedVal={selectedCustomer}
                  handleChange={(val) => setSelectedCustomer(val)}
                  dropdownType="customer"
                />
              </div>
              <div>
                <Label>Document Type*</Label>
                <Select
                  onChange={(e) => setDocumentType(e.target.value)}
                  className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                >
                  <option value="Type" defaultValue hidden>
                    {"Type"}
                  </option>
                  <option value="Pet Document">{"Pet Document"}</option>
                  <option value="Child Document">{"Child Document"}</option>
                  <option value="Medical Results">{"Medical Results"}</option>
                </Select>
              </div>
              <div>
                <Label>File Location*</Label>
                <Select
                  onChange={(e) => setDocumentLocation(e.target.value)}
                  className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                >
                  <option value="Folder" defaultValue hidden>
                    {"Folder"}
                  </option>
                  <option value="Identity Verification">{"Identity Verification"}</option>
                  <option value="Medical Documents">{"Medical Documents"}</option>
                </Select>
              </div>

              <div>
                <Label>Customer Access (Beta)</Label>
                <Select
                  onChange={(e) => setCustomerAccess(e.target.value)}
                  className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                >
                  <option value="Status" defaultValue hidden>
                    {"Status"}
                  </option>
                  <option value="true" selected>
                    {"True"}
                  </option>
                  <option value="false">{"False"}</option>
                </Select>
              </div>
              <div>
                <Label>File Extension</Label>
                <Select
                  onChange={(e) => setDocumentExtension(e.target.value)}
                  className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
                >
                  <option value="" defaultValue hidden>
                    {"pdf / jpg / png / jpeg"}
                  </option>
                  <option value="pdf">{"pdf"}</option>
                  <option value="jpg">{"jpg"}</option>
                  <option value="png">{"png"}</option>
                  <option value="jpeg">{"jpeg"}</option>
                </Select>
              </div>
              <div>
                <Label>Document*</Label>
                <input
                  className="border h-12 text-sm focus:outline-none block w-full border-transparent"
                  type="file"
                  name="documentUpload"
                  accept=".png, .jpeg, .jpg, .pdf, .zip"
                  onChange={handleFileChange}
                />
              </div>
              <div>
                {loadingUpload ? (
                  <Button disabled={true} type="button" className="h-12 w-full">
                    <img src={spinnerLoadingImage} alt="Loading" width={20} height={10} />{" "}
                    <span className="font-serif ml-2 font-light">Processing</span>
                  </Button>
                ) : (
                  <button
                    disabled={!file}
                    type="submit"
                    className={`${
                      !file && "opacity-50 cursor-not-allowed bg-red-300"
                    } flex items-center justify-center text-sm leading-5 h-12 w-full text-center transition-colors duration-150 font-medium focus:outline-none px-6 py-2 rounded-md text-white bg-green-500 border border-transparent active:bg-green-600 hover:bg-green-600 focus:ring focus:ring-purple-300`}
                  >
                    Upload Document
                    <span className="ml-2 text-base">
                      <IoCloudUploadOutline />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </CardBody>
      </Card>

      {loading ? (
        <>
          <PageTitle>{"List of documents"}</PageTitle>
          <TableLoading row={12} col={7} width={160} height={20} />
        </>
      ) : documents !== null && documents?.length !== 0 ? (
        <>
          <div className="hidden md:block">
            <TableContainer className="mb-8 dark:bg-gray-900">
              <Table>
                <TableHeader>
                  <TableRow>
                    <>
                      {Object.keys(dataTableHeaders).map((field) => (
                        <TableCell className="text-left word-break" key={field}>
                          {dataTableHeaders[field]}
                        </TableCell>
                      ))}
                    </>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {documents.map((item, index) => (
                    <TableRow key={index}>
                      <>
                        {Object.keys(dataTableHeaders).map((field) => (
                          <TableCell key={field} className="word-break">
                            {renderField(field, item)}
                          </TableCell>
                        ))}
                      </>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="md:hidden">
            {documents.map((document, index) => (
              <div key={index} className="mb-4 overflow-hidden border rounded-lg">
                <table className="min-w-full">
                  {Object.keys(dataTableHeaders).map((field) => (
                    <tr key={field} className="border-b">
                      <td className="p-2 font-semibold" style={{ width: "170px" }}>
                        {dataTableHeaders[field]}
                      </td>
                      <td className="p-2" style={{ lineBreak: "anywhere" }}>
                        {renderField(field, document)}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ))}
          </div>
        </>
      ) : (
        <NotFound title="Sorry, there are no documents found right now." />
      )}
    </>
  );
};

export default Documents;
