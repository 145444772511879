import { Datagrid, DateField, EmailField, List, ShowButton, TextField } from "react-admin";

const CorporateList = () => (
  <List>
    <Datagrid>
      <TextField source="id" label="Customer ID" emptyText="-" />
      <TextField source="first_name" emptyText="-" />
      <TextField source="last_name" emptyText="-" />
      <TextField source="gender" emptyText="-" />
      <DateField source="date_of_birth" emptyText="-" />
      <EmailField source="email_address" emptyText="-" />
      <TextField source="contact_number" emptyText="-" />
      <TextField source="company_id" emptyText="-" />
      <TextField source="company" emptyText="-" />
      <TextField source="membership_type" emptyText="-" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default CorporateList;
