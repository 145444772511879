import { useEffect, useRef, useState } from "react";
import { Button } from "smart-webcomponents-react/button";
import { Calendar } from "smart-webcomponents-react/calendar";
import { Scheduler } from "smart-webcomponents-react/scheduler";
import "smart-webcomponents-react/source/styles/smart.default.css";
import "./styles.scss";
import { getRequest, patchRequest } from "../../../helpers/requests";
import AvailabilityForm from "./Availability";
import { convertData, getPastThreeWeekdays } from "./helper";
import views from "./config.json";
import { deleteAvailabilitySlot } from "../../../services/AvailabilityServices";

const AvailabilityCalendar = () => {
  const schedulerRef = useRef();
  const calendarRef = useRef();
  const treeRef = useRef();
  const primaryContainerRef = useRef();
  const intervalInput = useRef();
  const shaderSwitch = useRef();
  const indicatorSwitch = useRef();

  // Bookings and availability stored in data
  const [data, setData] = useState([]);
  const [openAvailabilityForm, setOpenAvailabilityForm] = useState(false);
  const user_metadata = JSON.parse(localStorage.getItem("user_metadata"));
  const userID = user_metadata.user_id;

  const today = new Date();
  const currentDate = today.getDate();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentHours = today.getHours();
  const currentMinutes = today.getMinutes();
  const nonworkingDays = getPastThreeWeekdays(today.getDay());
  const view = "week";

  console.log(new Date(currentYear, currentMonth, 10, 13, 15));

  const firstDayOfWeek = 1;
  const disableDateMenu = true;
  const currentTimeIndicator = true;
  const shadeUntilCurrentTime = true;
  const scrollButtonsPosition = "far";

  const updateData = async (event) => {
    let item = event.detail.item;
    if (item.class === "cancelled") {
      // Cancelled appointment deletion is prohibited
      return;
    }
    if (item.class === "booked") {
      // Update booked appointments to cancelled
      const data = {
        class: "cancelled",
        metadata: item,
        clinicianEmail: user_metadata.username,
      };
      return await patchRequest(`appointments/cancel/${item.id}`, data);
    }
    item["clinician_id"] = user_metadata.user_id;
    const response = await deleteAvailabilitySlot(item.id, item);
    if (response.status === "success") {
      const newData = data
        .map((dataItem) => {
          if (dataItem.label === item.label && dataItem.id === item.id) {
            return event.type === "itemRemove" ? null : item;
          } else {
            return dataItem;
          }
        })
        .filter(Boolean);
      // setData(newData);
    }
  };

  const handleToggle = () => {
    const primaryContainer = primaryContainerRef.current;
    const scheduler = schedulerRef.current;

    primaryContainer.classList.toggle("collapse");
    scheduler.disableDateMenu = !primaryContainer.classList.contains("collapse");
  };

  const addNew = () => {
    schedulerRef.current.openWindow({
      class: "event",
    });
  };

  const handleCalendarChange = (event) => {
    schedulerRef.current.dateCurrent = event.detail.value;
  };

  const handleTreeChange = () => {
    const tree = treeRef.current;
    const selectedIndexes = tree.selectedIndexes;
    const types = [];

    for (let i = 0; i < selectedIndexes.length; i++) {
      tree.getItem(selectedIndexes[i]).then((result) => {
        types.push(result.value);

        if (i === selectedIndexes.length - 1) {
          schedulerRef.current.dataSource = data.filter((d) => types.indexOf(d.class) > -1);
        }
      });
    }
  };

  const handleDateChange = (event) => {
    calendarRef.current.selectedDates = [event.detail.value];
  };

  const handleIntervalChange = () => {
    schedulerRef.current.currentTimeIndicatorInterval = parseInt(intervalInput.current.value);
  };

  const handleShadeChange = () => {
    schedulerRef.current.shadeUntilCurrentTime = shaderSwitch.current.checked;
  };

  const handleIndicatorChange = () => {
    schedulerRef.current.currentTimeIndicator = indicatorSwitch.current.checked;
  };

  const restrictDates = () => {
    if (schedulerRef.current)
      schedulerRef.current.restrictedDates = [
        new Date(currentYear, currentMonth, currentDate),
        new Date(currentYear, currentMonth, currentDate - 2),
        new Date(currentYear, currentMonth, currentDate + 2),
        new Date(currentYear, currentMonth, currentDate - 5),
        new Date(currentYear, currentMonth, currentDate + 5),
        new Date(currentYear, currentMonth, currentDate - 10),
        new Date(currentYear, currentMonth, currentDate + 10),
      ];

    schedulerRef.current.nonworkingDays = [0, 5, 6];
    schedulerRef.current.nonworkingHours = [[9, 12], 15];

    // let nonworkingDays = scheduler.nonworkingDays;
    // let nonworkingHours = scheduler.nonworkingHours;
  };

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const fetchedLocations = [
          {
            category_id: 5,
            location_category: "Home Visit",
          },
        ];
        const locationCategoryIds = fetchedLocations.map((item) => item.category_id);
        const availabilityRequests = locationCategoryIds.map((category_id) =>
          getRequest(`availabilities/${category_id}/clinician/${userID}`),
        );
        const availabilities = await Promise.all(availabilityRequests);
        return convertData(availabilities[0].availability, "Availability");
      } catch (error) {
        console.error("Error fetching availability:", error);
        return []; // In case of error, return an empty array for availability
      }
    };

    const fetchAppointments = async () => {
      try {
        const appointments = await getRequest(`/appointments`, { clinician: `${userID}` });
        return convertData(appointments.appointments, "Appointments");
      } catch (error) {
        console.error("Error fetching appointments:", error);
        return []; // In case of error, return an empty array for appointments
      }
    };

    const fetchData = async () => {
      const [availabilityData, appointmentsData] = await Promise.all([fetchAvailability(), fetchAppointments()]);
      // Update state only if component is still mounted
      setData([...data, ...availabilityData, ...appointmentsData]);
    };

    fetchData();
  }, []); // Empty dependency array to run only once on mount

  const triggerReloadUpdate = () => {
    setOpenAvailabilityForm(false);
    window.location.reload();
  };

  return (
    <div id="primaryContainer" className="clinicianCalendar" ref={primaryContainerRef}>
      <div className="content">
        <section id="sideA">
          <div className="button-container">
            <div id="logo"></div>
          </div>
          <div className="controls-container">
            <Calendar
              ref={calendarRef}
              id="calendar"
              scrollButtonsPosition={scrollButtonsPosition}
              onChange={handleCalendarChange}
            ></Calendar>
            <Button id="addNew" onClick={() => setOpenAvailabilityForm(!openAvailabilityForm)}>
              <span>Add availability</span>
            </Button>
          </div>
        </section>
        <section id="sideB">
          {!openAvailabilityForm && (
            <Scheduler
              ref={schedulerRef}
              id="scheduler"
              dataSource={data}
              view={view}
              views={views}
              firstDayOfWeek={firstDayOfWeek}
              disableDateMenu={disableDateMenu}
              currentTimeIndicator={currentTimeIndicator}
              scrollButtonsPosition={scrollButtonsPosition}
              onDragEnd={updateData}
              onResizeEnd={updateData}
              onItemUpdate={updateData}
              onItemRemove={updateData}
              onDateChange={handleDateChange}
              shadeUntilCurrentTime={shadeUntilCurrentTime}
              disableResize
              disableDrag
              disableWindowEditor
            ></Scheduler>
          )}
          {openAvailabilityForm && <AvailabilityForm closeForm={() => triggerReloadUpdate()} />}
        </section>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
