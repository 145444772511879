import React, { useState, useEffect, useRef } from "react";
import styles from "./FormToolTip.module.scss";
import { FiInfo } from "react-icons/fi";

const FormToolTip = ({ children, description }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={tooltipRef} className="relative flex align-items-center">
      {children}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowTooltip(!showTooltip);
        }}
        className="relative ml-1"
        aria-label="Information"
      >
        <FiInfo className="text-gray-500 cursor-pointer" style={{ height: "15px", width: "15px", marginTop: "3px" }} />
        {showTooltip && (
          <div
            className={`${styles.tooltipContainer} absolute left-full top-0 mt-2 ml-2 p-2 w-48 bg-white shadow-md border rounded z-10`}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormToolTip;
