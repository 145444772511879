import LabelArea from "../../../components/form/LabelArea";
import InputArea from "../../../components/form/InputArea";
import Error from "../../../components/form/Error";
import SelectRole from "../../../components/form/SelectRole";
import { Button } from "@windmill/react-ui";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useForm } from "react-hook-form";
import SelectGender from "../../../components/form/SelectGender";
import { postRequest } from "../../../helpers/requests";
import { notifyError, notifySuccess } from "../../../utils/toast";

const SignUpBookingUser = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const onSubmit = async (data) => {
    const response = await postRequest("users/create-booking-user", data);
    if (response.status === "success") {
      notifySuccess("Booking user successfully created");
      navigate("/dashboard");
    } else {
      const errorMessage = response.message ? response.message : "Failed to create booking user";
      notifyError(errorMessage);
    }
  };

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto">
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-auto">
            <div className="w-full">
              <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                {"Create Booking User Account"}
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <LabelArea label="Full Name *" />
                <InputArea register={register} label="Name" name="fullName" type="text" placeholder="Enter full name" />
                <Error errorName={errors.fullName} />

                <LabelArea label="Gender" />
                <div className="col-span-8 sm:col-span-4">
                  <SelectGender register={register} label="Gender" name="gender" />
                </div>

                <LabelArea label="Contact Number" />
                <InputArea
                  register={register}
                  label="Contact number"
                  name="phoneNumber"
                  type="number"
                  placeholder="Enter phone number"
                />

                <LabelArea label="Location Address *" />
                <InputArea
                  register={register}
                  label="Location address"
                  name="address"
                  type="text"
                  placeholder="Enter full address"
                />
                <Error errorName={errors.address} />

                <LabelArea label="Latitude * (Retrieve from Google Maps)" />
                <InputArea
                  register={register}
                  label="Latitude"
                  name="latitude"
                  type="text"
                  placeholder="Enter latitude of the address"
                />
                <Error errorName={errors.latitude} />

                <LabelArea label="Longitude * (Retrieve from Google Maps)" />
                <InputArea
                  register={register}
                  label="Longitude"
                  name="longitude"
                  type="text"
                  placeholder="Enter longitude of the address"
                />
                <Error errorName={errors.latitude} />

                <LabelArea label="Email address *" />
                <InputArea
                  register={register}
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                />
                <Error errorName={errors.email} />

                <LabelArea label="Password *" />
                <InputArea
                  register={register}
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="***************"
                />
                <Error errorName={errors.password} />

                <LabelArea label="Staff Role *" />
                <div className="col-span-8 sm:col-span-4">
                  <SelectRole register={register} label="Role" name="roleId" />
                  <Error errorName={errors.roleId} />
                </div>

                {/*<Label className="mt-6" check>*/}
                {/*  <Input type="checkbox" />*/}
                {/*  <span className="ml-2">*/}
                {/*    I acknowledge that this account is created with permissions*/}
                {/*  </span>*/}
                {/*</Label>*/}

                <Button type="submit" className="mt-4 h-12 w-full" block>
                  Create Account
                </Button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SignUpBookingUser;
