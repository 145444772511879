import { Datagrid, DateField, List, ReferenceField, ShowButton, SimpleList, TextField } from "react-admin";
import { useMediaQuery } from "@mui/material";

const CommentList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
          linkType="show"
          primaryText={(record) => record.comment_content}
          tertiaryText={(record) => new Date(record.comment_date).toLocaleDateString()}
        />
      ) : (
        <Datagrid>
          <TextField source="id" />
          <DateField source="comment_date" />
          <TextField source="comment_content" />
          <ReferenceField resource="comments" source="booking_id" reference="bookings">
            <TextField source="title" />
          </ReferenceField>
          <ShowButton />
        </Datagrid>
      )}
    </List>
  );
};

export default CommentList;
