import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import BookingEntityOverview from "../../components/BookingEntityOverview";
import { Title } from "react-admin";
import { getRequest } from "../../helpers/requests";
import BookingEntityAppointments from "components/BookingEntityAppointments";

const BookingEntity = () => {
  const { id: paramId } = useParams();
  const id = paramId || JSON.parse(localStorage.getItem("user_metadata"))?.booking_entity_id;
  const [entity, setEntity] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  const fetchEntity = async () => {
    try {
      const response = await getRequest(`entities/${id}`);

      if (response.message === "Booking entity found") {
        setEntity(response.entities[0]);
      } else {
        console.error("Error fetching booking entity:", response.message);
      }
    } catch (error) {
      console.error("Error fetching booking entity:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEntity();
    }
  }, [id]);

  const handleChange = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  if (!entity) {
    return (
      <Container>
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} sx={{ padding: 0, marginTop: "16px" }}>
      <Title title={entity.display_name} />
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, width: "100%" }} fullWidth>
        <Tabs value={currentTab} onChange={handleChange} aria-label="booking entity tabs">
          <Tab label="Overview" />
          <Tab label="Appointments" />
        </Tabs>
        {currentTab === 0 && <BookingEntityOverview entity={entity} refreshEntity={fetchEntity} />}
        {currentTab === 1 && (
          <Box sx={{ padding: 2 }}>
            <BookingEntityAppointments entity={entity} />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default BookingEntity;
