import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ListButton, Toolbar } from "react-admin";
import { Button } from "@windmill/react-ui";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import { notifyError, notifySuccess } from "../../utils/toast";
import { postRequest } from "../../helpers/requests";
import LabelArea from "../form/LabelArea";
import InputArea from "../form/InputArea";
import Error from "../form/Error";
import SelectGender from "../form/SelectGender";
import SelectNameTitle from "../form/SelectNameTitle";

const ActionButtons = () => (
  <Toolbar>
    <ListButton label="Customers" />
  </Toolbar>
);

const UserCreate = (props) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const handleDateChange = (date) => {
    const formattedDate = date ? moment(date).format("YYYY-MM-DD") : "";
    setValue("dob", formattedDate);
  };

  const onSubmit = async (data) => {
    const response = await postRequest("auth/signup", data);
    if (response.status === "success") {
      notifySuccess("Customer successfully created");
      navigate("/customers");
    } else {
      const errorMessage = response.message ? response.message : "Failed to create booking user";
      notifyError(errorMessage);
    }
  };

  return (
    <>
      <ActionButtons />
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto">
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-auto">
              <div className="w-full">
                <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                  {"Create Customer Account"}
                </h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <LabelArea label="Title *" />
                  <SelectNameTitle register={register} label="Title" name="nameTitle" />
                  <Error errorName={errors.nameTitle} />

                  <LabelArea label="First Name *" />
                  <InputArea
                    register={register}
                    label="First Name"
                    name="firstName"
                    type="text"
                    placeholder="Enter first name"
                    required
                  />
                  <Error errorName={errors.firstName} />

                  <LabelArea label="Last Name *" />
                  <InputArea
                    register={register}
                    label="Last Name"
                    name="lastName"
                    type="text"
                    placeholder="Enter last name"
                    required
                  />
                  <Error errorName={errors.lastName} />

                  <LabelArea label="Gender" />
                  <div className="col-span-8 sm:col-span-4">
                    <SelectGender register={register} label="Gender" name="gender" />
                  </div>

                  <LabelArea label="Date of Birth" />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      {...register("dob")}
                      id="dob"
                      required
                      // className="mb-2 col-12"
                      format="DD/MM/YYYY"
                      onChange={handleDateChange}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <LabelArea label="Phone Number" />
                  <InputArea
                    register={register}
                    label="Phone number"
                    name="phoneNumber"
                    type="number"
                    placeholder="Enter phone number"
                  />

                  <LabelArea label="Address Line 1 *" />
                  <InputArea
                    register={register}
                    label="Address line 1"
                    name="address_line_1"
                    type="text"
                    placeholder="Enter address"
                    required
                  />
                  <Error errorName={errors.address_line_1} />

                  <LabelArea label="Address Line 2" />
                  <InputArea
                    register={register}
                    label="Address line 2"
                    name="address_line_2"
                    type="text"
                    placeholder="Enter address"
                  />
                  <Error errorName={errors.address_line_2} />

                  <LabelArea label="City *" />
                  <InputArea
                    register={register}
                    label="City"
                    name="city"
                    type="text"
                    placeholder="Enter city"
                    required
                  />
                  <Error errorName={errors.city} />

                  <LabelArea label="Country *" />
                  <InputArea
                    register={register}
                    label="Country"
                    name="country"
                    type="text"
                    placeholder="Enter country"
                    required
                  />
                  <Error errorName={errors.country} />

                  <LabelArea label="Postcode *" />
                  <InputArea
                    register={register}
                    label="Postcode"
                    name="postcode"
                    type="text"
                    placeholder="Enter postcode"
                    required
                  />
                  <Error errorName={errors.postcode} />

                  <LabelArea label="Country *" />
                  <InputArea
                    register={register}
                    label="Country"
                    name="country"
                    type="text"
                    placeholder="Enter country"
                    required
                  />
                  <Error errorName={errors.country} />

                  <LabelArea label="Company" />
                  <InputArea
                    register={register}
                    label="Company"
                    name="company"
                    type="text"
                    placeholder="Enter company"
                  />
                  <Error errorName={errors.latitude} />

                  <LabelArea label="Email address *" />
                  <InputArea
                    register={register}
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                  />
                  <Error errorName={errors.email} />

                  <LabelArea label="Password *" />
                  <InputArea
                    register={register}
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="***************"
                  />
                  <Error errorName={errors.password} />

                  {/*<LabelArea className="mt-6" check />*/}
                  {/*<input*/}
                  {/*  {...register("emailConsent")}*/}
                  {/*  type="checkbox"*/}
                  {/*  id="emailConsent"*/}
                  {/*  name="emailConsent"*/}
                  {/*  className="hidden"*/}
                  {/*></input>*/}
                  {/*<label htmlFor="emailConsent" className="checkbox">*/}
                  {/*  Keep me updated by email on Sirkka Health&apos;s offers and updates*/}
                  {/*</label>*/}

                  <Button type="submit" className="mt-4 h-12 w-full" block>
                    Create Account
                  </Button>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCreate;
