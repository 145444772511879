import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Title } from "react-admin";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getRequest } from "../../helpers/requests";
import BookingEntityCreateModal from "../../components/BookingEntityCreateModal";

dayjs.extend(customParseFormat);

const BookingEntities = () => {
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [entity, setEntity] = useState(null);
  const [entityOptions, setEntityOptions] = useState([]);
  const [entityLoading, setEntityLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [service, setService] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [availability, setAvailability] = useState(null);
  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [filters, setFilters] = useState({});
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal open/close
  const navigate = useNavigate();

  const fetchEntities = async () => {
    try {
      setLoading(true);
      let endpoint = `${process.env.REACT_APP_API_URL}/entities?limit=${rowsPerPage}&page=${page + 1}`;
      const queryParams = new URLSearchParams();

      if (sortBy) {
        queryParams.append("sort", sortBy);
        queryParams.append("order", order);
      }

      if (Object.keys(filters).length) {
        endpoint = `${process.env.REACT_APP_API_URL}/entities/search`;
        queryParams.append("page", page + 1);
        queryParams.append("limit", rowsPerPage);

        if (filters.entity) queryParams.append("entity", filters.entity.booking_entity_id);
        if (filters.location) queryParams.append("location", filters.location.city);
        if (filters.service) queryParams.append("service", filters.service.service_name);
        if (filters.availability) queryParams.append("availability", dayjs(filters.availability).format("YYYY-MM-DD"));
      }

      const url = `${endpoint}${endpoint.includes("?") ? "&" : "?"}${queryParams.toString()}`;
      const response = await fetch(url);
      const data = await response.json();
      const contentRange = response.headers.get("Content-Range");

      if (contentRange) {
        const [range, total] = contentRange.split("/");
        setTotalRows(parseInt(total, 10));
      } else {
        console.error("Content-Range header is missing");
      }

      setEntities(data.entities);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchEntityOptions = async () => {
    try {
      setEntityLoading(true);
      const response = await getRequest("entities/list");
      const uniqueEntities = Array.from(new Set(response.entities.map((e) => JSON.stringify(e)))).map((e) =>
        JSON.parse(e),
      );
      setEntityOptions(uniqueEntities);
      setEntityLoading(false);
    } catch (error) {
      console.error("Error fetching entities:", error);
      setEntityLoading(false);
    }
  };

  const fetchServices = async () => {
    try {
      setServiceLoading(true);
      const response = await getRequest("products");
      setServices(response);
      setServiceLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setServiceLoading(false);
    }
  };

  const fetchLocations = async () => {
    try {
      setLocationLoading(true);
      const response = await getRequest("bookings/location");
      const uniqueCities = Array.from(new Set(response.map((item) => item.city))).sort();
      setLocations(uniqueCities);
      setLocationLoading(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setLocationLoading(false);
    }
  };

  useEffect(() => {
    fetchEntities();
  }, [page, rowsPerPage, order, sortBy, filters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGoToEntity = (id) => {
    navigate(`/booking-entities/${id}`);
  };

  const handleCreateNewEntity = () => {
    setModalOpen(true); // Open the modal
  };

  const handleSearch = () => {
    setFilters({
      entity,
      location,
      service,
      availability,
    });
    setPage(0);
  };

  const handleResetFilters = () => {
    setEntity(null);
    setLocation(null);
    setService(null);
    setAvailability(null);
    setFilters({});
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  const handleEntityOpen = () => {
    if (!entityOptions.length && !entityLoading) fetchEntityOptions();
  };

  const handleLocationOpen = () => {
    if (!locations.length && !locationLoading) fetchLocations();
  };

  const handleServiceOpen = () => {
    if (!services.length && !serviceLoading) fetchServices();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Title title="List Booking Entities" />
      <Container maxWidth="false" sx={{ marginTop: "18px" }}>
        <Box sx={{ marginBottom: 2, display: "flex", gap: 2, justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Autocomplete
              options={entityOptions}
              getOptionLabel={(option) => option.display_name}
              loading={entityLoading}
              onOpen={handleEntityOpen}
              onChange={(event, newValue) => setEntity(newValue)}
              sx={{ width: 200 }}
              value={entity}
              PaperComponent={({ children }) => <Paper style={{ width: 350 }}>{children}</Paper>}
              renderInput={(params) => <TextField {...params} label="Entity" />}
              getOptionSelected={(option, value) => option.booking_entity_id === value.booking_entity_id}
              renderOption={(props, option) => (
                <li {...props} key={option.booking_entity_id}>
                  {option.display_name}{" "}
                  <span style={{ color: "#aaa", fontSize: "12px", paddingLeft: "6px" }}>
                    (ID {option.booking_entity_id})
                  </span>
                </li>
              )}
            />
            <Autocomplete
              options={locations}
              getOptionLabel={(option) => option}
              loading={locationLoading}
              onOpen={handleLocationOpen}
              onChange={(event, newValue) => setLocation({ city: newValue })}
              sx={{ width: 200 }}
              value={location ? location.city : null}
              renderInput={(params) => <TextField {...params} label="Location" />}
            />
            <Autocomplete
              options={services}
              getOptionLabel={(option) => option.service_name}
              loading={serviceLoading}
              onOpen={handleServiceOpen}
              onChange={(event, newValue) => setService(newValue)}
              sx={{ width: 200 }}
              value={service}
              renderInput={(params) => <TextField {...params} label="Service" />}
            />
            <DatePicker
              label="Availability"
              value={availability}
              onChange={(newValue) => setAvailability(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <Button variant="contained" onClick={handleSearch} sx={{ margin: "8px 0 4px" }}>
              Apply
            </Button>
            <Button variant="text" onClick={handleResetFilters} sx={{ margin: "8px 0 4px" }}>
              Reset
            </Button>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateNewEntity}
            sx={{ margin: "8px 0 4px" }}
          >
            Create New
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={sortBy === "booking_entity_id" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "booking_entity_id"}
                    direction={sortBy === "booking_entity_id" ? order : "asc"}
                    onClick={createSortHandler("booking_entity_id")}
                  >
                    Booking Entity ID
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "display_name" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "display_name"}
                    direction={sortBy === "display_name" ? order : "asc"}
                    onClick={createSortHandler("display_name")}
                  >
                    Display Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "is_published" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "is_published"}
                    direction={sortBy === "is_published" ? order : "asc"}
                    onClick={createSortHandler("is_published")}
                  >
                    Is Published
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "tags" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "tags"}
                    direction={sortBy === "tags" ? order : "asc"}
                    onClick={createSortHandler("tags")}
                  >
                    Tags
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "type" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "type"}
                    direction={sortBy === "type" ? order : "asc"}
                    onClick={createSortHandler("type")}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "ranking" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "ranking"}
                    direction={sortBy === "ranking" ? order : "asc"}
                    onClick={createSortHandler("ranking")}
                  >
                    Rank
                  </TableSortLabel>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (
                entities.map((entity) => (
                  <TableRow key={entity.booking_entity_id}>
                    <TableCell>{entity.booking_entity_id || "-"}</TableCell>
                    <TableCell>{entity.display_name || "-"}</TableCell>
                    <TableCell>{entity.is_published !== null ? (entity.is_published ? "Yes" : "No") : "-"}</TableCell>
                    <TableCell>{entity.tags || "-"}</TableCell>
                    <TableCell>{entity.type || "-"}</TableCell>
                    <TableCell>{entity.ranking !== null ? entity.ranking : "-"}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleGoToEntity(entity.booking_entity_id)}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
      <BookingEntityCreateModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </LocalizationProvider>
  );
};

export default BookingEntities;
