import React, { useEffect, useRef, useState } from "react";
import { Button } from "smart-webcomponents-react/button";
import { Calendar } from "smart-webcomponents-react/calendar";
import { Tree, TreeItem, TreeItemsGroup } from "smart-webcomponents-react/tree";
import { Scheduler } from "smart-webcomponents-react/scheduler";
import "smart-webcomponents-react/source/styles/smart.default.css";
import "./styles.scss";
import { getRequest } from "../../../helpers/requests";
import AvailabilityForm from "./Availability";
import views from "./config.json";
import { createTimeBlocks } from "../../../services/AvailabilityServices";

const AvailabilityCalendar = () => {
  const schedulerRef = useRef();
  const calendarRef = useRef();
  const treeRef = useRef();
  const primaryContainerRef = useRef();
  const intervalInput = useRef();
  const shaderSwitch = useRef();
  const indicatorSwitch = useRef();

  // Bookings and availability stored in data
  const [data, setData] = useState([]);
  const [locationCategories, setLocationCategories] = useState([]);
  const [openAvailabilityForm, setOpenAvailabilityForm] = useState(false);

  const today = new Date();
  const currentDate = today.getDate();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const view = "week";

  console.log(new Date(currentYear, currentMonth, 10, 13, 15));

  const firstDayOfWeek = 1;
  const disableDateMenu = true;
  const currentTimeIndicator = true;
  const shadeUntilCurrentTime = true;
  const scrollButtonsPosition = "far";

  const updateData = async (event) => {
    const item = event.detail.item;
    const response = await createTimeBlocks(item);
    if (response.status === "success") {
      const newData = data
        .map((dataItem) => {
          if (dataItem.label === item.label && dataItem.class === item.class) {
            return event.type === "itemRemove" ? null : item;
          } else {
            return dataItem;
          }
        })
        .filter(Boolean);

      // setData(newData);
    }
  };

  const handleToggle = () => {
    const primaryContainer = primaryContainerRef.current;
    const scheduler = schedulerRef.current;

    primaryContainer.classList.toggle("collapse");
    scheduler.disableDateMenu = !primaryContainer.classList.contains("collapse");
  };

  const addNew = () => {
    schedulerRef.current.openWindow({
      class: "event",
    });
  };

  const handleCalendarChange = (event) => {
    schedulerRef.current.dateCurrent = event.detail.value;
  };

  const handleTreeChange = () => {
    const tree = treeRef.current;
    const selectedIndexes = tree.selectedIndexes;
    const types = [];

    for (let i = 0; i < selectedIndexes.length; i++) {
      tree.getItem(selectedIndexes[i]).then((result) => {
        types.push(result.value);

        if (i === selectedIndexes.length - 1) {
          schedulerRef.current.dataSource = data.filter((d) => types.indexOf(d.class) > -1);
        }
      });
    }
  };

  const handleDateChange = (event) => {
    calendarRef.current.selectedDates = [event.detail.value];
  };

  const handleIntervalChange = () => {
    schedulerRef.current.currentTimeIndicatorInterval = parseInt(intervalInput.current.value);
  };

  const handleShadeChange = () => {
    schedulerRef.current.shadeUntilCurrentTime = shaderSwitch.current.checked;
  };

  const handleIndicatorChange = () => {
    schedulerRef.current.currentTimeIndicator = indicatorSwitch.current.checked;
  };

  const restrictDates = () => {
    if (schedulerRef.current)
      schedulerRef.current.restrictedDates = [
        new Date(currentYear, currentMonth, currentDate),
        new Date(currentYear, currentMonth, currentDate - 2),
        new Date(currentYear, currentMonth, currentDate + 2),
        new Date(currentYear, currentMonth, currentDate - 5),
        new Date(currentYear, currentMonth, currentDate + 5),
        new Date(currentYear, currentMonth, currentDate - 10),
        new Date(currentYear, currentMonth, currentDate + 10),
      ];

    schedulerRef.current.nonworkingDays = [0, 5, 6];
    schedulerRef.current.nonworkingHours = [[9, 12], 15];
  };

  useEffect(() => {
    const fetchLocations = async () => {
      const fetchedLocations = await getRequest("bookings/locationCategory");
      if (Array.isArray(fetchedLocations) && fetchedLocations.length) {
        setLocationCategories(fetchedLocations);
      }
    };

    fetchLocations();
  }, []);

  // useEffect(() => {
  //   // restrictDates();
  //   let locationCategoryIds = [];
  //
  //   const fetchAvailability = async () => {
  //     const fetchedLocations = await getRequest("bookings/locationCategory/temp/all");
  //
  //     if (!Array.isArray(fetchedLocations)) {
  //       return [];
  //     }
  //
  //     locationCategoryIds = fetchedLocations.map((item) => item.category_id);
  //
  //     const availabilityRequests = locationCategoryIds.map((category_id) =>
  //       getRequest(`bookings/availability/${category_id}`),
  //     );
  //
  //     const availabilities = await Promise.all(availabilityRequests);
  //
  //     const reformatted = convertData(locationCategoryIds, fetchedLocations, availabilities);
  //
  //     setData([...data, ...reformatted]);
  //   };
  //
  //   fetchAvailability();
  // }, []);

  const triggerReloadUpdate = () => {
    window.location.reload();
  };

  const handleCloseForm = () => {
    setOpenAvailabilityForm(false);
  };

  return (
    <div id="primaryContainer" className="adminCalendar" ref={primaryContainerRef}>
      <div className="content">
        <section id="sideA">
          <div className="button-container">
            <div id="logo"></div>
          </div>
          <div className="controls-container">
            <Calendar
              ref={calendarRef}
              id="calendar"
              scrollButtonsPosition={scrollButtonsPosition}
              onChange={handleCalendarChange}
            ></Calendar>
            <Tree
              ref={treeRef}
              id="tree"
              selectionMode="checkBox"
              toggleElementPosition="far"
              onChange={handleTreeChange}
            >
              <TreeItemsGroup expanded>
                Locations
                {locationCategories?.map((locationCategory) => (
                  <TreeItem key={locationCategory} value={locationCategory.replace(/\s/g, "").toLowerCase()} selected>
                    {locationCategory}
                  </TreeItem>
                ))}
              </TreeItemsGroup>
            </Tree>
            <Button id="addNew" onClick={() => setOpenAvailabilityForm(!openAvailabilityForm)}>
              <span>Add availability</span>
            </Button>
          </div>
        </section>
        <section id="sideB">
          {!openAvailabilityForm && (
            <Scheduler
              ref={schedulerRef}
              id="scheduler"
              dataSource={data}
              view={view}
              views={views}
              firstDayOfWeek={firstDayOfWeek}
              disableDateMenu={disableDateMenu}
              currentTimeIndicator={currentTimeIndicator}
              scrollButtonsPosition={scrollButtonsPosition}
              disableWindowEditor
              disableResize
              disableDrag
              onItemUpdate={updateData}
              onItemRemove={updateData}
              onDateChange={handleDateChange}
              shadeUntilCurrentTime={shadeUntilCurrentTime}
            ></Scheduler>
          )}
          {openAvailabilityForm && (
            <AvailabilityForm
              closeForm={handleCloseForm}
              onSuccess={triggerReloadUpdate}
              isOpen={openAvailabilityForm}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
