export const translationEn = {
  // DashBoard
  DashboardOverview: "Dashboard Overview",
  TodayOrder: "Today Orders",
  YesterdayOrder: "Yesterday Orders",
  ThisMonth: "This Month",
  AllTimeSales: "All-Time Sales",
  TotalOrder: "Total Orders",
  OrderPending: "Orders Pending",
  OrderProcessing: "Orders Processing",
  OrderDelivered: "Orders Delivered",
  POSOrders: "POS Orders",
  OnlineOrders: "Online Orders",
  WeeklySales: "Weekly Sales",
  BestSellingProducts: "Best Selling Products",
  Cash: "Cash",
  Card: "Card",
  Credit: "Credit",

  // dashBoard Chart
  ConversionsThisYear: "Conversions This Year",
  ConversionsThisWeek: "Conversions This Week",
  FreshVegetable: "Fresh Vegetable",
  TopRevenueProduct: "Top Revenue Product",

  //Line Chart
  Sales: "Sales",

  //Recent Order Table
  RecentOrder: "Recent Order",
  OrderTime: "Order Time",
  DeliveryAddress: "Delivery Address",
  Phone: "Phone",
  Orderamount: "Order amount",

  // Side Menu
  Dashboard: "Dashboard",
  Setting: "Settings",
  Catalog: "Catalog",
  Products: "Products",
  SideMenuCategory: "Category",
  Attributes: "Attributes",
  Attribute: "Attribute",
  Coupons: "Coupons",
  OurStaff: "Our Staff",
  Customers: "Customers",
  International: "International",
  Localization: "Localization",
  Locations: "Locations",
  Taxes: "Taxes",
  Translations: "Translations",
  ViewStore: "Online Store",
  Shop: "View your Store ",
  StoreHome: "Shop Home",
  StoreSetting: "Settings",
  GlobalSettings: "Global Settings",
  UseCompositionProduct: "Use Composition Product",
  CashiersCanOverrideInuseItems: "Cashiers can override Inuse items",
  EditProfile: "Edit Profile",
  LogOut: "Log Out",
  StockHandlingMethod: "Stock Handling Method",
  Pages: "Pages",

  //Products
  ProductsPage: "Products",
  Category: "Category",
  Price: "Price",
  LowtoHigh: "Low to High",
  HightoLow: "High to Low",
  AddProduct: "Add Product",
  BulkAction: "Bulk Action",
  DropCSVfile: "Drop CSV file",
  Upload: "Upload",
  Download: "Download",
  SearchProduct: "Search by product name",
  Published: "Published",
  Unpublished: "Unpublished",
  StatusSelling: "Status - Selling",
  StatusStock: " Status - Out of Stock",
  DateAddedAsc: "Date Added (Asc)",
  DateAddedDesc: "Date Added (Desc)",
  DateUpdatedAsc: "Date Updated (Asc)",
  DateUpdatedDesc: "Date Updated (Desc)",

  //Products Table
  SKUTbl: "SKU",
  ProductNameTbl: "PRODUCT NAME",
  CategoryTbl: "CATEGORY",
  PriceTbl: "price",
  StockTbl: "STOCK",
  StatusTbl: "STATUS",
  VIEW: "VIEW",
  DiscountTbl: "DISCOUNT",
  DetailsTbl: "View",
  PublishedTbl: "PUBLISHED",
  ActionsTbl: "ACTIONS",
  Off: "Off",
  Selling: "Selling",
  SoldOut: "Sold Out",
  Delete: "Delete",
  Edit: "Edit",
  TypeTbl: "Type",
  SelectedCountries: "Selected Countries",

  //Products Drawer
  ThisProductHaveVariants: "Does this product have variants?",
  UpdateProduct: "Update Products",
  UpdateProductDescriptionBulk: "Apply changes to the selected products from the list.",
  UpdateProductDescription: "Update products info, combinations and extras.",
  DrawerAddProduct: "Add Product",
  AddProductDescription: "Add your product and necessary information from here",
  ProductImage: "Product Images",
  DragYourImage: "Drag your images here",
  imageFormat: "(Only *.jpeg, *.webp and *.png images will be accepted)",
  ProductID: "Product ID",
  ProductSKU: "Product SKU",
  ProductBarcode: "Product Barcode",
  ProductTitleName: "Product Title/Name",
  ProductSlug: "Product Slug",
  ProductDescription: "Product Description",
  ParentCategory: "Parent Category",
  ChildCategory: "Child Category",
  ProductType: "Product Type",
  Unit: "Unit (kg/pc/lb/ml/g...etc)",
  ShowingIn: "Showing In",
  Store: "Store",
  ProductQuantity: "Product Quantity",
  ProductPrice: "Product Price",
  SalePrice: "Sale Price",
  ProductTag: "Product Tags",
  ProductTagPlaseholder: "Product Tag (Write then press enter to add new tag )",
  AddVariantBtn: "Add Variant",
  CancelBtn: "Cancel",
  UpdateBtn: "Update",
  AddBtn: "Add",
  Selectparentcategory: "Select parent category",
  Selectchildcategory: "Select child category",
  ProductPriceWithTax: "Product Price (tax incl.)",
  ProductPriceWithoutTax: "Product Price",
  ProductPriceTaxExcl: "Product Price (tax excl.)",
  PROFIT: "PROFIT",
  EXCLVAT: "EXCL VAT",
  PRICEINCLVAT: "PRICE INCL VAT",
  TaxRate: "Tax Rate",
  TaxName: "Tax Name",
  AddTaxesBtn: "Add Tax",
  DefaultCategory: "Default Category",
  MasterProduct: "Master Product",
  SellIndividualUnits: "Sell Individual Units",
  Taxexcl: "tax excl.",
  Taxincl: "tax incl.",
  CostPrice: "Cost Price",
  AllowOutStockOrder: "Allow Out Of Stock Order",
  AllowRelatedProductsAndCategories: "Allow Related Products And Categories",
  AllowNotesOnCheckout: "Allow Notes On Checkout",
  ProductMeasurements: "Product Measurements",
  DeleteCombinations: "Delete Combinations",
  Apply: "Apply",
  SelectedCurrencies: "Selected Currencies",
  SelectedLanguages: "Selected Languages",

  //Product Details
  ProductDetails: "Product Details",
  ThisProductShowing: "This product Showing",
  ThisProductHidden: "This product Hidden",
  StockOut: "Stock Out",
  InStock: "In Stock",
  EditProduct: "Edit Product",
  ProductVariantList: "Product Variant List",

  //Products Drawer Combination TBL
  Combination: "Combination",
  Sku: "Sku",
  Barcode: "Barcode",
  PriceTxExcl: "Price(tx excl.)",
  PriceTxIncl: "Price(tx incl.)",
  TotalVol: "Total Vol.",
  QuantityTbl: "Quantity",
  Measurement: "Measurement",
  Change: "Change",
  quart: "quart",
  pint: "pint",
  ounce: "ounce",
  pound: "pound",
  foot: "foot",
  inch: "inch",
  SelectMeasurement: "Select Measurement",

  //Products  Delete Modal
  DeleteModalH2: "Are You Sure! Want to Delete",
  DeleteModalPtag:
    "Do you really want to delete these records? You can't view this in your list anymore if you delete!",
  modalKeepBtn: "No, Keep It",
  modalDeletBtn: "Yes, Delete It",
  Processing: "Processing",

  //Order Invoice Modal
  OrderInvoiceDownload: "Order Invoice & Download",
  PrintReceipt: "Print Receipt",
  DoReStockProducts: "Do you want to Re stock the products?",

  //NotificationModalTwo
  AreSureWantDeleteItem: " Are you sure you want to delete this Item?",
  YesDeleteIt: "Yes, Delete It",

  //Category
  SelectedAttributes: "Selected Attributes",
  CategoryPageTitle: "Category",
  CategorySearchplaceholder: "Search by category type",
  AddCategoryBtn: "Add Category",
  AddCategory: "Add Category",
  CatTbName: "Name",
  CatTbDescription: "Description",
  SearchCategory: "Search by Category name",
  ParentsOnly: "Parents Only",
  All: " All",
  Categoryformenu: "Category for menu",
  SelectCategory: "Select Category",

  //CategoryTable
  catIdTbl: "ID",
  catIconTbl: "Icon",
  catParentTbl: "PARENT",
  catChildrenTbl: "CHILDREN",
  catTypeTbl: "TYPE",
  catPublishedTbl: "PUBLISHED",
  catActionsTbl: "ACTIONS",
  VALUES: "VALUES",

  //Category Drawer
  UpdateCategory: "Update Category",
  UpdateCategoryDescription: "Updated your Product category and necessary information from here",
  AddCategoryTitle: "Add Category",
  AddCategoryDescription: "Add your Product category and necessary information from here",
  CategoryIcon: "Category Image",
  ChildCategoryPlaceholder: "Child category  (Write then press enter to add new child category )",
  ParentCategoryPlaceholder: "Category title",
  CatName: "Name",
  CatDescription: "Description",

  //Coupons
  CouponspageTitle: "Coupon",
  SelectedCoupon: "Selected Coupon",
  AttributeTitle: "Attributes",
  SearchAttributePlaceholder: "Search by attribute name",
  CouponsAddAttributeBtn: "Add Attribute",
  AddCouponsBtn: "Add Coupon",
  CouponBannerImage: "Coupon Banner Image",
  SearchCoupon: "Search by coupon code/name",

  //Coupons table
  CoupTblId: "Id",
  CoupTblStartDate: "START DATE",
  CoupTblEndDate: "END DATE",
  CoupTblCampaignsName: "CAMPAIGN NAME",
  CoupTblCode: "CODE",
  CoupTblPercentage: "PERCENTAGE",
  CoupTblProductType: "PRODUCT TYPE",
  CoupTblStatus: "STATUS",
  CoupTblActions: "ACTIONS",

  //Coupon Drawer
  UpdateCoupon: "Update Coupon",
  UpdateCouponDescription: "Updated your coupon and necessary information from here",
  AddCoupon: "Add Coupon",
  AddCouponDescription: "Add your coupon and necessary information from here",
  CampaignName: " Campaign Name",
  CampaignCode: "Campaign Code",
  CouponValidityTime: "Coupon Validity Time",
  DiscountPercentage: "Discount Percentage",
  MinimumAmount: "Minimum Amount",
  MinimumAmountPlasholder: "Minimum amount required",
  DiscountType: "Discount Type",
  Values: "Values",
  //Attribute table
  Id: "ID",
  AName: "NAME",
  ADisplayName: "DISPLAY NAME",
  AOption: "OPTION",
  APublished: "PUBLISHED",
  Avalues: "VALUES",
  AAction: "ACTION",

  //Attribute Drawer
  UpdateAttribute: "Update Attribute Value",
  UpdateAttributeDesc: "Updated your attribute values and necessary information from here",
  AddAttribute: "Add Attribute Value",
  AddAttributeDesc: "Add your attribute values and necessary information from here",
  DrawerAttributeTitle: "Attribute Title",
  DisplayName: "Display Name",
  DrawerOptions: "Options",
  DrawerSelecttype: "Select type",
  Variants: "Variants",
  Dropdown: "Dropdown",
  Radio: "Radio",
  AddExtra: "Add Extra",
  SearchExtraName: "Search by Extra name",
  //Extra Drawer
  UpdateExtraValue: "Update Extra Value",
  UpdateExtraDescription: "Update your Extra values and necessary information from here",
  AddExtraValue: "Add Extra Value",
  AddExtraDescription: "Add your Extra values and necessary information from here",

  //Orders
  OrdersPageTitle: "Orders",
  OrdersSearchPhone: "Search by phone",
  OrderStatus: "Status",
  PageOrderDelivered: "Delivered",
  PageOrderPending: "Pending",
  PageOrderProcessing: "Processing",
  OrderCancel: "Cancel",
  Orderlimits: "Order limits",
  DaysOrders5: "Last 5 days orders",
  DaysOrders7: "Last 7 days orders",
  DaysOrders15: "Last 15 days orders",
  DaysOrders30: "Last 30 days orders",
  DownloadOrdersBtn: "Download All Orders",
  ParkOrders: " Park Orders",
  POSCompleted: "POS Completed",
  SearchByInvoice: "Search By Invoice",
  DeleteAllBarOrders: "Delete All Bar Orders",
  DeleteAllKitchenOrders: " Delete All Kitchen Orders",
  StartDate: "Start Date",
  EndDate: "End Date",

  //customer orders
  CustomerOrderList: "Customer Order List",
  CustomerOrderEmpty: "This Customer have no order Yet!",
  CustomerOrderId: "Order ID",
  CustomerOrderTime: "Time",
  CustomerShippingAddress: "Shipping Address",
  CustomerOrderMethod: "Method",
  CustomerOrderStatus: "Status",
  CustomerOrderAction: "Action",

  //Park Orders
  ParkOrdersTitle: "Park Orders",
  TableName: "Table Name",
  SearchCustomerTableName: "Search Customer / Table Name",

  //Orders Table
  InvoiceNo: "INVOICE NO",
  TimeTbl: "ORDER TIME",
  ShippingAddressTbl: "SHIPPING ADDRESS",
  PhoneTbl: "PHONE",
  MethodTbl: "METHOD",
  AmountTbl: "AMOUNT",
  UserTbl: "USER",
  OderStatusTbl: "STATUS",
  ActionTbl: "ACTION",
  InvoiceTbl: "INVOICE",
  ViewInvoice: "View Invoice",
  CustomerName: "Customer Name",
  OrderNumber: "Order No.",
  SearchOrderNumberCustomer: "Search by Order number or customer",

  //Orders Invoice

  InvoicePageTittle: "Invoice",
  Order: "Order",
  InvoiceStatus: "Status",
  InvoiceDate: "DATE",
  InvoiceTo: "INVOICE TO",
  Sr: "SR.",
  ProductName: "PRODUCT NAME",
  Quantity: "QUANTITY",
  ItemPrice: "ITEM PRICE",
  Amount: "AMOUNT",
  InvoicepaymentMethod: "PAYMENT METHOD",
  ShippingCost: "SHIPPING COST",
  InvoiceDicount: "DISCOUNT",
  InvoiceTotalAmount: "TOTAL AMOUNT",
  DownloadInvoice: "Download Rechnung  ",
  PrintInvoice: "Print Invoice",
  ShippingCostLower: "Shipping Cost",
  VATTotal: "VAT Total",
  GrossTotal: "Gross Total",
  Date: "Date",
  Item: "Item",
  QTY: "QTY",
  // Amount: 'Amount',
  ThankYouMsg: "Thank you for your order. Come Again...!",
  NoofItems: "No of Items",
  BillNo: "Bill No",
  Discount: "DISCOUNT",
  DiscountLower: "Discount",
  TotalAmount: "TOTAL AMOUNT",
  DownloadInvoiceBtn: "Download Invoice",
  OrderHistory: "Order History",
  ShippingMethod: "SHIPPING METHOD",
  ShippingMethodLower: "Shipping Method",
  OrderType: "Order type",
  CategorySorting: "Category Sorting",
  TableInfo: "Table info",
  StatusChanged: "Status changed by",
  Seller: "Seller",
  User: "User",
  Extras: "Extras",
  Notes: "Notes",
  SubTotal: "SubTotal",
  VAT: "VAT",
  Total: "Total",
  Return: "Return",
  Inuse: "Inuse",
  InuseOrder: "InuseOrder",

  //Returns
  Returns: "Returns",
  SearchCodeName: "Search by code/name",
  SelectClickItemsWantReturn: "Select/Click on the Items want to return",
  CartEmpty: "Cart is empty",
  NoItem: "No items added in your cart.",
  ItemReturned: "Item returned in this bill.",
  ReloadAll: "Reload All",
  DoWantReceiptReturnOrder: "Do you want to receipt for this return order?",
  NoDontReturn: "No, Don t Return",
  ReturnOrder: "Return Order",
  TotalItems: "Total Items",
  SubTotalWithoutVAT: "Sub Total Without VAT",
  TotalCost: "Total Cost",
  ThisOrderAlreadyHadReturnitems: "This Order Already had return items!",

  //Staff

  StaffPageTitle: "All Staff",
  StaffSearchBy: "Search by name/email/phone",
  StaffRole: "Staff Role",
  StaffRoleAdmin: "Admin",
  StaffRoleCeo: "CEO",
  SelectCashiers: "Cashier",
  SelectSuperAdmin: "Super Admin",
  StaffRoleManager: "Manager",
  StaffRoleAccountant: "Accountant",
  StaffRoleDriver: "Driver",
  StaffRoleSecurity: "Security Guard",
  StaffRoleDelivery: "Delivery Person",
  AddStaff: "Add Staff",

  //Staff Table
  StaffIdTbl: "ID",
  StaffNameTbl: "Name",
  StaffEmailTbl: "Email",
  StaffContactTbl: "Contact",
  StaffJoiningDateTbl: "Joining Date",
  StaffRoleTbl: "Role",
  StaffShopStatusTbl: "Shop Status",
  StaffPosStatusTbl: "POS Status",
  StaffActionsTbl: "ACTIONS",

  //Staff Drawer
  UpdateStaff: "Update Staff",
  UpdateStaffdescription: "Updated your staff necessary information from here",
  AddStaffTitle: "Add Staff",
  AddStaffdescription: "Add your staff necessary information from here",
  StaffImage: "Staff Image",
  StaffName: "Name",
  StaffNamePlaceholder: "Staff Name",
  StaffEmail: "Email",
  StaffPassword: "Password",
  StaffContactNumber: "Contact Number",
  StaffJoiningDate: "Joining Date",
  DrawerStaffRole: "Staff Role",
  sorryProductNotFound: "Sorry, There are no products right now.",

  // Customers
  CustomersPage: "Customers",
  CustomersPageSearchPlaceholder: "Search by name/email/phone",
  CustomersId: "Id",
  CustomersJoiningDate: "Joining Date",
  CustomersName: "Name",
  CustomersEmail: "Email",
  CustomersPhone: "Phone",
  CustomersActions: "Actions",

  // Customer Orders List

  CustomerOrderListPageTitle: "Customer Orders List",
  NoOrder: "This Customer have no order Yet!",

  // Customer Orders List Table
  OrderIDTbl: "Order ID",
  OrderTimeTbl: "Time",
  OrderShippingAddressTbl: "ShippingAddress",
  OrderPhoneTbl: "Phone",
  OrderMethodTbl: "Method",
  OrderAmountTbl: "Amount",
  OrderStatusTbl: "Status",
  OrderActionsTbl: "Actions",
  ViewOrder: "View Order",

  // Login Box

  LoginTitle: "Login",
  labelEmail: "Email",
  labelPassword: "Password",
  LoginBtn: "Log in",
  ForgotPassword: "Forgot your password",
  CreateAccount: "Create account",

  //Edit Profile and forget password
  updateProfile: " Update Profile",
  ProfilePicture: "Profile Picture",
  ProfileName: "Name",
  ProfileEmail: "Email",
  ProfileContactNumber: "Contact Number",
  ProfileYourRole: "Your Role",

  //  Create account
  LoginWithGoogle: "Login With Google",
  LoginWithFacebook: "Login With Facebook",
  CreateAccountTitle: "Create account",
  CreateAccountName: "Name",
  CreateAccountCompanyName: "Company Name",
  CreateAccountEmail: "Email",
  CreateAccountPassword: "Password",
  privacyPolicy: "privacy policy",
  TermAndCondition: "Terms and Conditions",
  Iagree: "I agree to the",
  CreateAccountBtn: "Create Account",
  AlreadyAccount: "Already have an account? Login",
  Welcome: "Welcome to Dashtar!",
  Cookies: "Cookies",
  License: "License",
  Content: "Content Liability",
  Disclaimer: "Disclaimer",
  DisclaimerDes:
    "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:1. limit or exclude our or your liability for death or personal injury;2. limit or exclude our or your liability for fraud or fraudulent misrepresentation;3. limit any of our or your liabilities in any way that is not permitted under applicable law; or4. exclude any of our or your liabilities that may not be excluded under applicable law.The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.",
  ContentDescription:
    "We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.",
  LicenseDescription:
    "Unless otherwise stated, Dashtar and/or its licensors own the intellectual property rights for all material on Dashtar. All intellectual property rights are reserved. You may access this from Dashtar for your own personal use subjected to restrictions set in these terms and conditions.This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms And Conditions Generator. You must not:1. Identifiers (e.g. name, mailing address, email address, phone number, credit/debit card number)2. Characteristics of protected classifications (e.g. gender, age)3. Commercial information (e.g. products or services purchased, purchase history)4. Internet or other electronic network activity (e.g. browse or search history)5. Geo location data (e.g. latitude or longitude)6. Audio, electronic, visual, or similar information (e.g. recording of Guest service calls)7. Inferences drawn from any of the above (e.g. preferences or characteristics)Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Dashtar does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Dashtar,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Dashtar shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.",
  CookiesDescription:
    "We employ the use of cookies. By accessing Dashtar, you agreed to use cookies in agreement with the Dashtar's Privacy Policy. Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.",

  WelcomeDescription:
    "These terms and conditions outline the rules and regulations for the use of Dashtar's Website, located at https://dashter.com/. By accessing this website we assume you accept these terms and conditions. Do not continue to use Dashtar if you do not agree to take all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: 'Client', 'You' and 'Your' refers to you, the person log on this website and compliant to the Company’s terms and conditions. 'The Company', 'Ourselves', 'We', 'Our' and 'Us', refers to our Company. 'Party', 'Parties', or 'Us', refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.",

  //Forgot password

  ForgotpasswordTitle: "Forgot password",
  RecoverpasswordBtn: "Recover password",

  //POS Setting
  InvoiceNumberLength: "Invoice Number Length",
  DefaultDateFormat: "Default Date Format",
  PosSetting: "POS Setting",
  StoreDetails: "Store Details",
  ShopLogo: "Shop logo",
  ShopName: "Shop name",
  CompanyName: "Company name",
  VATNumber: "VAT number",
  ShopAddressLine1: "Shop address line 1",
  ShopAddressLine2: "Shop address line 2",
  ShopAddressLine3: "Shop address line 3",
  ContactNumber1: "Contact number 1",
  ContactNumber2: "Contact number 2",
  Email: "Email",
  WebSite: "Web site",
  AnyInfoDisplayinSlip: "Additional info to display in receipt",
  AnyInfoDisplayinSlip1: "Any additional info display in the bottom of the receipt",
  Configuration: "Configuration",
  ShowImages: "Show images",
  DefaultPOSCustomer: "Default POS customer",
  EmailInvoiceToAdmin: "Email Invoice To Admin(S)",
  ReceiptSize: "Receipt size (width)",
  PreviewReceipt: "Preview receipt",
  Alloworderingofoutofstockproducts: "Allow ordering of out of stock products",
  EnablePinBeforeAction: "Enable pin before any action",
  Quantitypopupforextras: "Quantity popup for extras",
  ShowPaymentOptions: "Show payment options",
  DefaultPaymentMethod: "Default payment method",
  AllowDiscounts: "Allow discounts",
  PrintSlip: "Print slip",
  EmailSlip: "Email Slip to the Customer",

  OrderTypeCapText: "This will shown in POS product card",
  DefaultTaxRate: "Default tax rate",
  SaveBtn: "Save",
  previous: "Previous",
  Next: "Next",
  PaymentMethods: "Payment methods",
  Combined: "Combined",
  NumberProductShowPOSHomePage: "Number Of Products to Show in POS Home Page",
  ProductSorting: "Product sorting",
  Billing: "Billing",
  NumberOfCategoriestoShowinPOSHomePage: "Number Of Categories to Show in POS Home Page",

  ApplyTheCostOfTheHighestDefinedRange: "Apply the cost Of the highest defined range",
  AddNewRange: " Add new range",

  ShowStockDetailsinProductPage: "Show stock details in product page",
  ShowStockDetailsInStore: "Show stock details in store (global)",
  ShowLowStockNotification: "Show low stock notification",
  LowStockNotificationValue: "Low stock notification value",
  Poshomepageview: "POS home page view",
  Defaultposcategory: " Default POS category",
  DefaultposcategorySmText: "Products in this category will be shown in the POS by default",
  TableNavigation: "Table navigation",
  sorryCategoryNotFound: "Sorry, There are no categories right now.",
  ContactDetails: "Contact Details",
  PostCode: "Post Code",
  Theme: "Theme",
  ICON: "ICON",
  ThemeSmallText: "Selected theme will shown in POS",
  EmailRecipients: "Email Recipients",

  // International (Localization)

  Languages: "Languages",
  Currencies: "Currencies",
  Geolocation: "Geolocation",

  DefaultLanguage: "Default language",
  SetLanguageFromBrowser: "Set language from browser",
  DefaultCountry: "Default country",
  DefaultCurrency: "Default currency",
  TimeZone: "Default time zone",
  LocalUnits: "Local Units",
  WeightUnit: "Weight Unit",
  DistanceUnit: "Distance Unit",
  VolumeUnit: "Volume Unit",
  DimensionUnit: "Dimension Unit",
  globalDescription:
    "You already have child products in the system. Turning this feature off will unpublish them. Would you like to continue ?",
  UnitChangeBoxTitle: "Are you sure want to change this?",
  UnitChangeBoxDiscription:
    "Changing unit system could cause misbehavior of existing products and stocks. Are you sure you want to do this ?",

  YesChangeItBtn: "Yes, Change It",

  InternationalCustom: "International / Custom",
  MetricSystemImperialSystem: "Metric System / Imperial System",

  Custom: "Custom",

  Metric: "Metric",
  Imperial: "Imperial",

  Length: "Length",
  SelectLength: "Select Length",
  Centimeter: "Centimeter (cm)",
  Meter: "Meter (m)",
  Kilometer: "Kilometer (Km)",
  Weight: "Weight",
  SelectWeight: "Select Weight",
  Gram: "Gram (g)",
  Volume: "Volume",
  SelectVolume: "Select Volume",
  Milliliter: "Milliliter (ml)",
  Liter: "Liter (l)",
  Mile: "Mile",
  Yard: "Yard",
  Foot: "Foot",
  Inch: "Inch",
  Ounce: "Ounce",
  Pound: "Pound",
  Gallon: "Gallon",
  Pint: "Pint",
  Quart: "Quart",
  TotalVolume: "Total Volume",
  Width: "Width",
  Height: "Height",
  GenerateVariants: "Generate Variants",
  ClearVariants: "Clear Variants",
  AttributesFeatures: "Attributes & Features",
  AddCombinationsDiscription:
    "Please add combinations or enable your attribute, you first need to create proper attributes and values in",
  AddCombinationsDiscriptionTwo:
    '. When done, you may enter the wanted attributes (like "size" or "color") and their respective values ("XS","red", "all", etc.) in the field below; or simply select them from the right column. Then click on "Generate": it will automatically create all the combinations for you!',
  GenerateExtras: "Generate Extras",
  ClearExtras: "Clear Extras",
  BulkActions: "Bulk Actions",
  // Quantity: 'Quantity',
  SelectedExtra: "Selected Extra",
  // International (Languages)

  LanguagesSr: "SR",
  LanguagesNname: "NAME",
  LanguagesIsoCode: "ISO CODE",
  LanguageCode: "LANGUAGE CODE",
  LanguagesFlag: "FLAG",
  LanguagesPublished: "PUBLISHED",
  LanguagesActions: "ACTIONS",

  AddLanguageName: "Language Name",
  AddLanguagesIsoCode: "Iso Code",
  AddLanguageCode: "Languages Code",
  AddLanguagesFlag: "Flag",
  AddLanguagesPublished: "Published",

  SearchLanguage: "Search by country name and iso code, language code",

  // International (Currencies)
  CurrenciesName: "Name",
  Currencyisocode: "Iso Code",
  CurrenciesSymbol: "Symbol",
  CurrenciesIsoCode: "Iso Code",
  CurrenciesExchangeRate: "Exchange Rate",
  CurrenciesEnabled: "Enabled",
  CurrenciesLiveExchangeRates: "Live Exchange Rates",
  CurrenciesActions: "Actions",
  SearchIsoCode: "Search by iso code",

  // Language Drawer
  AddLanguage: "Add Language",
  AddLanguageText: "Add your Language necessary information from here",
  UpdateLanguage: "Update Language",
  UpdateLanguageText: "Updated your Language necessary information from here",
  LanguageName: "Language Name",

  // Update Drawer
  UpdateCurrency: "Update Currency",
  UpdateCurrencyText: "Updated your currency and necessary information from here",
  AddCurrency: "Add Currency",
  AddCurrencyText: "Add your Currency and necessary information from here",

  // International (Locations)
  Countries: "Countries",
  States: "States",
  TaxZone: "Tax Zone",
  ShippingZone: "Shipping Zone",
  SearchBy: "Search by zone name",
  Status: "Status",
  Show: "Show",
  Hide: "Hide",
  NameTbl: "NAME",
  SRTbl: "SR",
  IsoCode: "Iso Code",
  CallPrefix: "Call Prefix",

  Country: "Country",
  Zones: "Zones",
  Zone: "Zone",
  Rates: "Rates",
  SearchByStateNameAndIsoCode: "Search by state name and iso code",
  UpdatedZone: "Update Zone",
  UpdatedZoneText: "Updated your Zone necessary information from here",
  AddZone: "Add Zone",
  AddZoneText: "Add your Zone necessary information from here",
  ZoneName: "Zone Name",
  UpdateCountry: "Update Country",
  UpdateCountryText: "Updated your Country necessary information from here",
  AddCountry: "Add Country",
  AddCountryText: "Add your Country necessary information from here",
  CountryName: "Country Name",
  UpdateState: "Update State",
  UpdateStateText: "Updated your State necessary information from here",
  AddState: "Add State",
  AddStateText: "Add your State necessary information from here",
  StateName: "State Name",
  StateBtn: "State",
  AddTaxZone: "Add Tax Zone",
  AddTaxZoneText: "Add your tax zone necessary information from here",
  UpdateTaxZone: "Update Tax Zone",
  UpdateTaxZoneText: "Updated your tax zone necessary information from here",
  TaxZoneName: "Tax Zone Name",
  State: "State",
  TaxZoneBtn: "Tax Zone",
  UpdateShippingZone: "Update Shipping Zone",
  UpdateShippingZoneText: "Updated your Shipping Zone necessary information from here",
  AddShippingZone: "Add Shipping Zone",
  AddShippingZoneText: "Add your Shipping Zone necessary information from here",
  ShippingZoneName: "Shipping Zone Name",
  ShippingRate: "Shipping Rate",
  ShippingZoneBtn: "Shipping Zone",
  AddTaxes: "Add Taxes",
  AddTaxesText: "Add your Taxes and necessary information from here",
  UpdateTaxes: "Update Taxes",
  UpdateTaxesText: "Update your Taxes and necessary information from here",
  TaxesBtn: "Taxes",
  shippingZoneSearch: "Search by shipping zone name",
  taxNameSearch: "Search by tax name",

  // Store Customization
  HomeSettings: "Home Page Settings",
  SingleSetting: "Single Product Page Setting",
  AboutUsSetting: "About Us",
  PrivacyTCSetting: "Privacy Policy and T&C",
  FAQSetting: "FAQs",
  OffersStting: "Offers",
  ContactUsStting: "Contact Us",

  StoreCustomizationPageTitle: "Store Customizations",
  Header: "Header",
  HeaderContacts: "Header Contacts",
  HeaderText: "Header Text",
  PhoneNumber: "Phone Number",
  HeaderLogo: "Header Logo",
  weAreAvailable: "We are available 24/7, Need help?",
  MainSlider: "Main Slider",
  Slider: "Slider",
  SliderImages: "Slider Images",
  SliderTitle: "Slider Title",
  SliderDescription: "Slider Description",
  SliderButtonName: "Slider Button Name",
  SliderButtonLink: "Slider Button Link",
  Options: "Options",
  LeftRighArrows: "Left and Right Arrows",
  BottomDots: "Bottom Dots",
  Both: "Both",
  DiscountCouponTitle1: "Discount Coupon Code Box",
  DiscountCouponCodeTitle2: "Discount Coupon Code",
  ShowHide: "Show / Hide",
  SuperDiscountActiveCouponCode: "Super Discount Active Coupon Code",
  SliderFullWidth: "Slider Full Width",
  PlaceHolderImage: "Place Holder Image",
  ImagesResolution: "Images resolution 500X400px",

  PromotionBanner: "Promotion Banner",
  EnableThisBlock: "Enable This Block",
  Title: "Title",
  Description: "Description",
  DESCRIPTION: "DESCRIPTION",
  PromotionDescription: "Promotion Description",
  ButtonName: "Button Name",
  ButtonLink: "Button Link",
  FeaturedCategories: "Featured Categories",
  ProductsLimit: "Products Limit",
  PopularProductsTitle: "Popular Products",
  PopularDescription: "Popular Description",
  QuickDeliverySectionTitle: "Quick Delivery Section",
  SubTitle: "Sub Title",
  Image: "Image",
  LatestDiscountedProductsTitle: "Latest Discounted Products",
  GetYourDailyNeedsTitle: "Get Your Daily Needs",
  LatestDiscountDescription: "Latest Discount Description",
  DailyNeedDescription: "Daily Need Description",
  Button1Link: "Button 1 Link",
  Button2Link: "Button 2 Link",
  Button1image: "Button 1 Image",
  Button2image: "Button 2 Image",
  ImageLeft: "Image Left",
  ImageRight: "Image Right",
  FeaturePromoSectionTitle: "Feature Promo Section",
  FreeShipping: "Free Shipping",
  Support: "Support",
  SecurePayment: "Secure Payment",
  LatestOffer: "Latest Offer",
  TopCategory: "Top Category",
  FooterTitle: "Footer",
  Block: "Block",
  Link: "Link",
  FooterLogo: "Footer Logo",
  FooterAddress: "Address",
  FooterPhone: "Phone",
  FooterEmail: "Email",
  FishAndMeat: "Fish & Meat",
  FooterBottomContact: "Footer Bottom Contact Number",
  SocialLinksTitle: "Social Links",
  PaymentMethod: "Payment Method",
  Statement: "Statement",
  SearchStaffName: "Search by open or closing staff name",
  SoftDrinks: "Soft Drinks",
  BabyCare: "Baby Care",
  BeautyAndHealth: "Beauty & Health",
  MyAccount: "My Account",
  MyOrders: "My Orders",
  RecentOrders: "Recent Orders",
  UpdatedProfile: "Updated Profile",
  SocialLinks: "Social Links",

  //Online Store Setting
  OnlineStorePageTitle: "Online Store Setting",
  General: "General",
  MainShopActivity: "Main Shop Activity",
  StripePublicKey: "Stripe Public Key",
  AllowStripe: "Allow Stripe",
  AllowOrderingOfOutOfStock: "Allow Ordering Of Out-Of-Stock Products",
  CustomDomainName: "Custom Domain Name",
  ShopDomain: "Shop Domain",
  Verify: "Verify",
  AllowPaypal: "Allow Paypal",
  PaypalPublicKey: "Paypal Public Key",
  // Global Settings

  UseMultipleLanguagesInShop: "I'd like to use multiple languages in my shop, admin and POS",
  UseMultipleCurrenciesInShop: "I'd like to use multiple currencies in my shop, admin and POS",

  NumberOfImagesPerProduct: "Number of images per product",
  PosView: "POS view",
  WorkingHours: "Working Hours",
  Open: "Open",
  Close: "Close",
  InvoiceNumberStartValue: "Invoice number start value",
  InvoiceNumberLeadingZeros: "Invoice number leading zeros",
  RetailPOS: "Retail POS",
  Restaurant: "Restaurant",

  Shipping: "Shipping",
  AddShipping: "Add Shipping ",
  SearchByShippingName: "Search by shipping name",
  ShippingName: "Shipping Name",
  ShippingDescription: "Shipping Description",
  Logo: "Logo",
  AddShippingText: "Add your Shipping and necessary information from here",
  UpdateShipping: "Update Shipping",
  UpdateShippingText: "Update your Shipping and necessary information from here",
  CarrierLogo: "Carrier Logo",

  Reports: "Reports",
  Orders: "Orders",
  Source: "Source",
  SHOP: "SHOP",

  Actions: "Actions",

  Speedgrade: "Speed grade",
  TrackingURL: "Tracking URL",
  Select: "Select",
  ApplyTheCostTheHighestDefinedRange: "Apply The Cost Of The Highest Defined Range",
  DisableCarrier: "Disable Carrier",
  InvoiceNumberType: "Invoice number type",
  AddressLine: "Address",
  GlobalContactNumber: "Contact",
  InvoiceCompanyName: "Company Name",
  VatNumber: "Vat Number",
  Vat: "Vat",

  // Menu Editor
  MenuEditor: "Menu Editor",
  Categories: "Categories",
  AboutUs: "About Us",
  ContactUs: "Contact Us",
  Careers: "Careers",
  LatestNews: "Neuesten Nachrichten",
  Offers: "Offers",
  FAQ: "FAQ",
  PrivacyPolicy: "Privacy Policy",
  TermsConditions: "Terms & Conditions",

  // About Page

  PageHeader: "Page Header",
  PageHeaderBg: "Page Header Background",
  PageTitle: "Page Title",
  AboutPageTopContentLeft: "About Page Top Content Left",
  TopTitle: "Top Title",
  TopDescription: "Top Description",
  BoxOneTitle: "Box One Title",
  BoxOneSubtitle: "Box One Subtitle",
  BoxOneDescription: "Box One Description",
  BoxTwoTitle: "Box Two Title",
  BoxTwoSubtitle: "Box Two Subtitle",
  BoxTwoDescription: "Box Two Description",
  PageTopContentRight: "Page Top Content Right",
  TopContentRightImage: "Top Content Right Image",
  RightBox: "Right Box",
  MiddleContentSection: "Content Section",
  MiddleDescriptionOne: "First Paragraph",
  MiddleDescriptionTwo: "Second Paragraph",
  MiddleContentImage: "Content Image",
  OurFounder: "Our Team",
  OurFounderTitle: "Our Team Title",
  OurFounderDescription: "Our Team Description",
  OurFounderOneImage: "Our Team One Image",
  OurFounderOneTitle: "Our Team One Title",
  OurFounderOneSubTitle: "Our Team One Sub Title",
  OurFounderTwoImage: "Our Team Two Image",
  OurFounderTwoTitle: "Our Team Two Title",
  OurFounderTwoSubTitle: "Our Team Two Sub Title",
  OurFounderThreeImage: "Our Team Three Image",
  OurFounderThreeTitle: "Our Team Three Title",
  OurFounderThreeSubTitle: "Our Team Three Sub Title",
  OurFounderFourImage: "Our Team Four Image",
  OurFounderFourTitle: "Our Team Four Title",
  OurFounderFourSubTitle: "Our Team Four Sub Title",
  OurFounderFiveImage: "Our Team Five Image",
  OurFounderFiveTitle: "Our Team Five Title",
  OurFounderFiveSubTitle: "Our Team Five Sub Title",
  OurFounderSixImage: "Our Team Six Image",
  OurFounderSixTitle: "Our Team Six Title",
  OurFounderSixSubTitle: "Our Team Six Sub Title",
  OurTeam: "Member",

  PrivacyPolicyTermsTitle: "Privacy Policy and T&C",
  PageText: "Page Text",
  LeftImage: "Left Image",
  FaqTitleOne: "Faq Title One",
  FaqDescriptionOne: "Faq Description One",
  FaqTitleTwo: "Faq Title Two",
  FaqDescriptionTwo: "Faq Description Two",
  FaqTitleThree: "Faq Title Three",
  FaqDescriptionThree: "Faq Description Three",
  FaqTitleFour: "Faq Title Four",
  FaqDescriptionFour: "Faq Description Four",
  FaqTitleFive: "Faq Title Five",
  FaqDescriptionFive: "Faq Description Five",
  FaqTitleSix: "Faq Title Six",
  FaqDescriptionSix: "Faq Description Six",
  FaqTitleSeven: "Faq Title Seven",
  FaqDescriptionSeven: "Faq Description Seven",
  FaqTitleEight: "Faq Title Eight",
  FaqDescriptionEight: "Faq Description Eight",
  FAQPageHeader: "FAQs Page Header",
  FaqLeftCol: "FAQs Left Column",

  EmailUs: "Email Us Box",
  EboxTitle: "Title",
  EboxEmail: "Email",
  Eboxtext: "Text",

  CallUs: "Call Us Box",
  CallusboxTitle: "Title",
  CallUsboxPhone: "Phone",
  CallUsboxText: "Text",

  Address: "Address Box",
  AddressboxTitle: "Title",
  AddressboxAddressOne: "Address",
  AddressboxAddressTwo: " Address Two",
  AddressboxAddressThree: " Address Three",

  MidLeftCol: "Middle Left Colum",
  MidLeftImage: "Middle Left Image",

  ContactForm: "Contact Form",
  ContactFormTitle: "Contact Form Title",
  ContactFormDescription: "Contact Form Description",

  FAQS: "FAQs",

  // Reset password

  ResetPassword: "Reset password",
  ConfirmPassword: "Confirm Password",
  Reset: "Reset",
  AlreadyHaveAccount: "Already have an account? Login",

  DoesProductHaveCombinations: " Does this product have combinations?",
};

//////////////////////////// German //////////////////////////////////////////////////////////////////////////////////////////////////////////

export const translationDe = {
  // DashBoard
  PROFIT: "PROFITIEREN",
  EXCLVAT: "EXKL. MEHRWERTSTEUER",
  PRICEINCLVAT: "PREIS INKL. MWST",
  INCLVAT: "INKL. MWST",
  DashboardOverview: "Dashboard-Übersicht ",
  TodayOrder: "Heute bestellen",
  YesterdayOrder: "Gestern bestellts",
  ThisMonth: "Diesen Monat",
  AllTimeSales: "Allzeit-Verkauf",
  TotalOrder: "Gesamtbestellungs",
  OrderPending: "Bestellung anstehend",
  OrderProcessing: "Auftragsabwicklung",
  OrderDelivered: "Bestellung geliefert",
  POSOrders: "POS-Bestellungen",
  OnlineOrders: "Online-Bestellungen",
  WeeklySales: "Wöchentliche Verkäufe",
  BestSellingProducts: "Meistverkaufte Produkte",
  Pages: "Seiten",
  Cash: "Kasse",
  Card: "Karte",
  Credit: "Kredit",

  // dashBoard Chart
  ConversionsThisYear: "Konvertierungen in diesem Jahr",
  ConversionsThisWeek: "Konvertierungen in diesem Jahr",
  FreshVegetable: "Frisches Gemüse",
  TopRevenueProduct: "Top-Umsatzprodukt",

  //Line Chart
  Sales: "Verkauf",
  Orders: "Aufträge",

  //Recent Order Table
  RecentOrder: "Letzte Bestellung",
  OrderTime: "Bestellzeitpunkt",
  DeliveryAddress: "Lieferadresse",
  Phone: "Telefon",
  Orderamount: "Bestellbetrag",

  //Sidemenu
  Dashboard: "Armaturenbrett",
  Setting: "Einstellung",
  Catalog: "Katalog",
  Products: "Produkte",
  SideMenuCategory: "Kategorie",
  Attributes: "Attribute",
  Attribute: "Attribut",
  Coupons: "Gutscheine",

  OurStaff: "Unsere Angestelten",
  Customers: "Kunden",
  International: "International",
  Localization: "Lokalisierung",
  Locations: "Standorte",
  Taxes: "Steuern",
  Translations: "Übersetzungen",
  ViewStore: "Online-Shop",
  Shop: "Aussicht dein Speichern",
  StoreSetting: "Einstellung",
  EditProfile: "Profil bearbeiten",
  LogOut: "Ausloggen",
  StockHandlingMethod: "Stock-Handling-Methode",

  //Products
  ProductsPage: "Produkte",
  Category: "Kategorie",
  Price: "Preis",
  LowtoHigh: "Niedrig bis hoch",
  HightoLow: "Absteigend",
  AddProduct: "Produkt hinzufügen",
  BulkAction: " Massenaktion",
  DropCSVfile: "CSV-Datei löschen",
  Upload: "Hochladen",
  Download: "Download",
  SearchProduct: "Suche nach Produktnamen",
  Published: "Published",
  Unpublished: "Unpublished",
  StatusSelling: "Status - Selling",
  StatusStock: " Status - Out of Stock",
  DateAddedAsc: "Date Added (Asc)",
  DateAddedDesc: "Date Added (Desc)",
  DateUpdatedAsc: "Date Updated (Asc)",
  SelectProductLimitPerPage: "Wählen Sie Produktlimit pro Seite",
  //Products Table
  SKUTbl: "SKU",
  ProductNameTbl: "PRODUKT NAME",
  CategoryTbl: "KATEGORIE",
  PriceTbl: "PREIS",
  StockTbl: "LAGER",
  StatusTbl: "STATUS",
  DiscountTbl: "RABATT",
  DetailsTbl: "EINZELHEITEN",
  PublishedTbl: "VERÖFFENTLICHT",
  ActionsTbl: "AKTIONEN",
  Off: "Angebot",
  Selling: "Verkauf",
  SoldOut: "Ausverkauft",
  Delete: "Löschen",
  Edit: "Bearbeiten",
  TypeTbl: "Type",
  SelectedCountries: "Ausgewählte Länder",

  //Products Drawer
  ThisProductHaveVariants: "Hat dieses Produkt Varianten?",
  UpdateProduct: "Produkt aktualisieren",
  UpdateProductDescriptionBulk: "Aktualisierte Ihr Produkt und die erforderlichen Informationen von hier aus",
  UpdateProductDescription: "Aktualisieren Sie Produktinformationen, Kombinationen und Extras.",
  DrawerAddProduct: "Produkt hinzufügen",
  AddProductDescription: "Fügen Sie hier Ihr Produkt und die erforderlichen Informationen hinzu",
  ProductImage: "Produktbilds",
  DragYourImage: "Ziehen Sie Ihr Bild hierher",
  imageFormat: "(Es werden nur *.jpeg, *.webp und *.png Bilder akzeptiert)",
  ProductSKU: "Produkt-SKU",
  ProductTitleName: "Titel/Name des Produkts",
  ProductSlug: "Produkt-Slug",
  ProductDescription: "Produktbeschreibung",
  ParentCategory: "Eltern-Kategorie",
  ChildCategory: "Kinderkategorie",
  ProductType: "Produktart",
  Unit: "Einheit (kg/pc/lb/ml/g...etc)",
  ShowingIn: "Eingeblendet",
  Store: "Speichern",
  ProductQuantity: "Produktmenge",
  ProductPrice: "Produktpreis",
  SalePrice: "Verkaufspreis",
  ProductTag: "Produkt-Tag",
  ProductTagPlaseholder: "Produkt-Tag (Schreiben und dann die Eingabetaste drücken, um ein neues Tag hinzuzufügen)",
  AddVariantBtn: "Variante hinzufügen",
  CancelBtn: "Absagen",
  UpdateBtn: "Aktualisieren",
  AddBtn: "Hinzufügen",
  Selectparentcategory: "Übergeordnete Kategorie auswählen",
  Selectchildcategory: "Unterkategorie auswählen",
  ProductPriceWithTax: "Product Price (tax incl.)",
  ProductPriceWithoutTax: "Product Price",
  ProductPriceTaxExcl: "Product Price (tax excl.)",
  AddTaxesBtn: "Add Tax",
  DefaultCategory: "Default Category",
  MasterProduct: "Master Product",
  SellIndividualUnits: "Sell Individual Units",
  Taxexcl: "tax excl.",
  Taxincl: "tax incl.",
  CostPrice: "Cost Price",
  AllowOutStockOrder: "Nicht vorrätige Bestellung zulassen",
  AllowRelatedProductsAndCategories: "Verwandte Produkte und Kategorien",
  AllowNotesOnCheckout: "Allow Notes On Checkout",
  ProductMeasurements: "Product Measurements",
  DeleteCombinations: "Delete Combinations",
  Apply: "Apply",
  SelectedCurrencies: "Ausgewählte Währungen",
  SelectedLanguages: "Ausgewählte Sprachen",

  //Product Details
  ProductDetails: "Product Details",
  ThisProductShowing: "This product Showing",
  ThisProductHidden: "This product Hidden",
  StockOut: "Stock Out",
  InStock: "In Stock",
  EditProduct: "Edit Product",
  ProductVariantList: "Product Variant List",

  //Products Drawer Combination TBL

  Combination: "Combination",
  Sku: "Sku",
  Barcode: "Barcode",
  PriceTxExcl: "Price(tx excl.)",
  PriceTxIncl: "Price(tx incl.)",
  TotalVol: "Total Vol.",
  QuantityTbl: "Quantity",
  Measurement: "Measurement",
  Change: "Change",
  quart: "quart",
  pint: "pint",
  ounce: "ounce",
  pound: "pound",
  foot: "foot",
  inch: "inch",
  SelectMeasurement: "Select Measurement",

  //Products  Delete Modal

  DeleteModalH2: "Bist du dir sicher! Löschen möchten",
  DeleteModalPtag:
    "Möchten Sie diese Datensätze wirklich löschen? Sie können dies nicht mehr in Ihrer Liste sehen, wenn Sie es löschen!",
  modalKeepBtn: "Nein, behalte es",
  modalDeletBtn: "Ja, löschen",
  Processing: "wird bearbeitet",

  //Order Invoice Modal
  OrderInvoiceDownload: "Rechnung bestellen & herunterladen",
  PrintReceipt: "Druckeingang",
  DoReStockProducts: "Möchten Sie die Produkte wieder auf Lager haben?",

  //Category
  SelectedAttributes: "Ausgewählte Attribute",
  CategoryPageTitle: "Kategorie",
  CategorySearchplaceholder: "Suche nach Kategorietyp",
  AddCategoryBtn: "Kategorie hinzufügen",
  AddCategory: "Kategorie hinzufügen",
  CatTbName: "Name",
  CatTbDescription: "Description",
  View: "View",
  SearchCategory: "Suchen Sie nach Kategorienamen",
  ParentsOnly: "Nur für Eltern",
  All: " Alle",
  Categoryformenu: "Kategorie für Menü",
  SelectCategory: "Kategorie wählen",
  //CategoryTable

  catIdTbl: "ICH WÜRDE",
  catIconTbl: "Symbol",
  catParentTbl: "ELTERNTEIL",
  catChildrenTbl: "KINDER",
  catTypeTbl: "TYP",
  catPublishedTbl: "VERÖFFENTLICHT",
  catActionsTbl: "AKTIONEN",
  VALUES: "WERTE",
  //Category Drawer

  UpdateCategory: "Kategorie aktualisieren",
  UpdateCategoryDescription: "Aktualisierte Ihre Produktkategorie und die erforderlichen Informationen von hier aus",
  AddCategoryTitle: "Kategorie hinzufügen",
  AddCategoryDescription: "Fügen Sie hier Ihre Produktkategorie und die erforderlichen Informationen hinzu",
  CategoryIcon: "Kategorie-Image",
  ChildCategoryPlaceholder:
    "Untergeordnete Kategorie (Schreiben Sie und drücken Sie dann die Eingabetaste, um eine neue untergeordnete Kategorie hinzuzufügen)",
  ParentCategoryPlaceholder: "Titel der Kategorie",
  CatName: "Name",
  CatDescription: "Description",

  //Coupons

  CouponspageTitle: "Gutscheine",
  SelectedCoupon: "Ausgewählter Gutschein",
  SearchAttributePlaceholder: "Suche nach Attributnamen",
  CouponsAddAttributeBtn: "Attribute hinzufügen",
  AddCouponsBtn: "Gutschein hinzufügen",
  SearchCoupon: "Suche nach Gutscheincode/Name",

  //Coupons table
  Close: "Schließen",
  CoupTblId: "ICH WÜRDE",
  CoupTblStartDate: "ANFANGSDATUM",
  CoupTblEndDate: "ENDTERMIN",
  CoupTblCampaignsName: "KAMPAGNENNAME",
  CoupTblCode: "CODE",
  CoupTblPercentage: "PROZENTSATZ",
  CoupTblProductType: "PRODUKTART",
  CoupTblStatus: "STATUS",
  CoupTblActions: "AKTIONEN",

  //Coupon Drawer
  UpdateCoupon: "Gutschein aktualisieren",
  UpdateCouponDescription: "Aktualisierte Ihren Coupon und die erforderlichen Informationen von hier aus",
  AddCoupon: "Gutschein hinzufügen",
  AddCouponDescription: "Fügen Sie hier Ihren Gutschein und die erforderlichen Informationen hinzu",
  CouponBannerImage: "Gutschein-Banner-Bild",
  CampaignName: "Kampagnenname",
  CampaignCode: "Kampagnencode",
  CouponValidityTime: "Gutschein-Gültigkeitszeit",
  DiscountPercentage: "Rabattprozentsatz",
  MinimumAmount: "Mindestbetrag",
  MinimumAmountPlasholder: "Mindestmenge erforderlich",

  //Attribute table
  Id: "Ausweis",
  AName: "Name",
  ADisplayName: "Anzeigename   ",
  AOption: "Möglichkeit",
  APublished: "veröffentlicht",
  Avalues: "Werte",
  AAction: "Aktion",

  //Attribute Drawer
  Values: "Werte",
  UpdateAttribute: "Update Attribute Value",
  UpdateAttributeDesc: "Updated your attribute values and necessary information from here",
  AddAttribute: "Add Attribute Value",
  AddAttributeDesc: "Add your attribute values and necessary information from here",

  DrawerAttributeTitle: "Attribute Title",
  DisplayName: "Anzeigename",
  DrawerOptions: "Options",
  DrawerSelecttype: "Select type",
  Variants: "Variants",
  AttributeTitle: "Attributes",
  Dropdown: "Dropdown",
  Radio: "Radio",

  //Orders
  OrdersPageTitle: "Aufträge",
  OrdersSearchPhone: "Telefonisch suchen",
  OrderStatus: "Status",
  PageOrderDelivered: "Geliefert",
  PagePageOrderPending: "Ausstehend",
  PageOrderProcessing: "wird bearbeitet",
  OrderCancel: "Absagen",
  Orderlimits: "Bestelllimits",
  DaysOrders5: "Bestellungen der letzten 5 Tage",
  DaysOrders7: "Bestellungen der letzten 7 Tage",
  DaysOrders15: "Bestellungen der letzten 15 Tage",
  DaysOrders30: "Bestellungen der letzten 30 Tage",
  DownloadOrdersBtn: "Alle Bestellungen herunterladen",
  ParkOrders: "Parkbefehle",
  POSCompleted: "POS Completed",
  SearchByInvoice: "Suche nach Rechnung",
  DeleteAllBarOrders: "Delete All Bar Orders",
  DeleteAllKitchenOrders: " Delete All Kitchen Orders",

  //customer orders
  CustomerOrderList: "Kundenauftragsliste",
  CustomerOrderEmpty: "   Dieser Kunde hat noch keine Bestellung!   ",
  CustomerOrderId: "Auftragsnummer",
  CustomerOrderTime: "Zeit",
  CustomerShippingAddress: "Lieferanschrift",
  CustomerOrderMethod: "Methode",
  CustomerOrderStatus: "Status",
  CustomerOrderAction: "CAktion",

  //Park Orders
  ParkOrdersTitle: "Parkbefehle",
  TableName: "Tabellenname",
  SearchCustomerTableName: "Kunden-/Tabellenname suchen",

  //Orders Table
  InvoiceNo: "RECHNUNG NR",
  TimeTbl: "BESTELLZEITPUNKT",
  ShippingAddressTbl: "LIEFERANSCHRIFT",
  PhoneTbl: "TELEFON",
  MethodTbl: "METHODE",
  AmountTbl: "MENGE",
  UserTbl: "Benutzer",
  OderStatusTbl: "STATUS",
  ActionTbl: "AKTION",
  InvoiceTbl: "RECHNUNG",
  ViewInvoice: "Rechnung ansehen",
  CustomerName: "Customer Name",
  OrderNumber: "Order No.",
  SearchOrderNumberCustomer: "Search by Order number or customer",

  //Orders Invoice

  InvoicePageTittle: "Rechnung",
  Order: "Bestellung",
  InvoiceStatus: "Status",
  InvoiceDate: "DATUM",
  InvoiceTo: "RECHNUNG AN",
  Sr: "SR.",
  ProductName: "Produktname",
  Quantity: "MENGE",
  ItemPrice: "Stückpreis",
  Amount: "BETRAG",
  InvoicepaymentMethod: "ZAHLUNGSMETHODE",
  InvoiceDicount: "RABATT",
  InvoiceTotalAmount: "Gesamtmenge",
  DownloadInvoice: "Download Rechnung  ",
  PrintInvoice: "Rechnung drucken",
  ShippingCost: "VERSANDKOSTEN",
  ShippingCostLower: "Versandkosten",
  VATTotal: "MwSt. Gesamt",
  GrossTotal: "Bruttosumme",
  Date: "Datum",
  Item: "Artikel",
  QTY: "Menge",
  // Amount:'Menge',
  ThankYouMsg: "Vielen Dank. Komm wieder...!",
  NoofItems: "Anzahl der Artikel",
  BillNo: "Rechnung Nr",
  Discount: "RABATT",
  DiscountLower: "Rabatt",
  TotalAmount: "GESAMTSUMME",
  DownloadInvoiceBtn: "Download Rechnung",
  OrderHistory: "Bestellverlauf",
  ShippingMethod: "VERSANDART",
  ShippingMethodLower: "Versandart",
  OrderType: "Auftragsart",
  CategorySorting: "Kategoriesortierung",
  TableInfo: "Tabelleninfo",
  StatusChanged: "Status geändert durch",
  Seller: "Verkäufer",
  User: "Benutzer",
  Extras: "Extras",
  Notes: "Anmerkungen",
  SubTotal: "Zwischensumme",
  VAT: "MwSt",
  Total: "Gesamt",
  Return: "Zurückkehren",
  Inuse: "Inuse",
  InuseOrder: "InuseOrder",
  AddExtra: "Zusatz hinzufügen",
  SearchExtraName: "Suche nach zusätzlichem Namen",
  //Returns
  Returns: "Kehrt zurück",
  SearchCodeName: "Suche nach Code/Name",
  SelectClickItemsWantReturn: "Wählen/klicken Sie auf die Artikel, die Sie zurücksenden möchten",
  CartEmpty: "Einkaufswagen ist leer",
  NoItem: "Keine Artikel in Ihrem Warenkorb hinzugefügt.",
  ItemReturned: "In dieser Rechnung zurückgegebener Artikel.",
  ReloadAll: "Alle neu laden",
  DoWantReceiptReturnOrder: "Möchten Sie eine Quittung für diese Rücksendung?",
  NoDontReturn: "Nein, nicht zurück",
  ReturnOrder: "Rückholauftrag",
  TotalItems: "Gesamtanzahl",
  SubTotalWithoutVAT: "Zwischensumme ohne MwSt",
  TotalCost: "Gesamtkosten",
  ThisOrderAlreadyHadReturnitems: "Diese Bestellung hatte bereits Rücksendungen!",

  //Staff
  StaffPageTitle: "Alle Mitarbeiter",
  StaffSearchBy: "Suche nach Name/E-Mail/Telefon",
  StaffRole: "Mitarbeiterrolle",
  StaffRoleAdmin: "Administrator",
  StaffRoleCeo: "Vorsitzender",
  SelectCashiers: "Kassiererin",
  SelectSuperAdmin: "höchster Vorgesetzter",
  StaffRoleManager: "Manager",
  StaffRoleAccountant: "Buchhalter",
  StaffRoleDriver: "Treiber",
  StaffRoleSecurity: "Sicherheitsbeamter",
  StaffRoleDelivery: "Zusteller",
  AddStaff: "Personal hinzufügen",

  //Staff Table

  StaffIdTbl: "ICH WÜRDE",
  StaffNameTbl: "Name",
  StaffEmailTbl: "Email",
  StaffContactTbl: "Kontakt",
  StaffJoiningDateTbl: "Beitrittsdatum",
  StaffRoleTbl: "Rolle",
  StaffShopStatusTbl: "Shop-Status",
  StaffPosStatusTbl: "POS-Status",
  StaffActionsTbl: "AKTIONEN",

  //Staff Drawer

  UpdateStaff: "Mitarbeiter aktualisieren",
  UpdateStaffdescription: "Aktualisierte die notwendigen Informationen Ihres Personals von hier aus",
  AddStaffTitle: "Personal hinzufügen",
  AddStaffdescription: "Fügen Sie von hier aus die erforderlichen Informationen für Ihre Mitarbeiter hinzu",
  StaffImage: "Mitarbeiterbild",
  StaffName: "Name",
  StaffNamePlaceholder: "Personal Name",
  StaffEmail: "Email",
  StaffPassword: "Passwort",
  StaffContactNumber: "Kontakt Nummer",
  StaffJoiningDate: "Beitrittsdatum",
  DrawerStaffRole: "Mitarbeiterrolle",
  WorkingHours: "Arbeitszeit",
  //Customers
  VIEW: "SICHT",
  CustomersPage: "Kunden",
  CustomersPageSearchPlaceholder: "Suche nach Name/E-Mail/Telefon",
  CustomersId: "Ausweis",
  CustomersJoiningDate: "Beitrittsdatum",
  CustomersName: "Name",
  CustomersEmail: "Email",
  CustomersPhone: "Telefon",
  CustomersActions: "Aktionen",

  // Customer Orders List

  CustomerOrderListPageTitle: "Kundenauftragsliste",
  NoOrder: "Dieser Kunde hat noch keine Bestellung!",

  // Customer Orders List Table
  OrderIDTbl: "Auftragsnummer",
  OrderTimeTbl: "Zeit",
  OrderShippingAddressTbl: "Lieferanschrift",
  OrderPhoneTbl: "Telefon",
  OrderMethodTbl: "Methode",
  OrderAmountTbl: "Menge",
  OrderStatusTbl: "Status",
  OrderActionsTbl: "Aktionen",
  ViewOrder: "Bestellung ansehen",

  // Login Box

  LoginTitle: "Anmeldung",
  labelEmail: "Email",
  labelPassword: "Passwort",
  LoginBtn: "Anmeldung",
  ForgotPassword: "Haben Sie Ihr Passwort vergessen",
  CreateAccount: "Konto anlegen",

  //Edit Profile and forget password
  updateProfile: " Profil aktualisieren",
  ProfilePicture: "Bild",
  ProfileName: "Name",
  ProfileEmail: "Email",
  ProfileContactNumber: "Kontakt Nummer  ",
  ProfileYourRole: "Deine Rolle",

  //  Create account
  LoginWithGoogle: "Melden Sie sich mit Google an",
  LoginWithFacebook: "Mit Facebook einloggen",
  CreateAccountTitle: "Konto anlegen",
  CreateAccountName: "Name",
  CreateAccountCompanyName: "Name der Firma",
  CreateAccountEmail: "Email",
  CreateAccountPassword: "Passwort",
  privacyPolicy: "Datenschutz-Bestimmungen",
  TermAndCondition: "Geschäftsbedingungen",
  Iagree: "Ich stimme dem zu",
  CreateAccountBtn: "Konto anlegen",
  AlreadyAccount: "Sie haben bereits ein Konto? Anmeldung",
  Welcome: "Willkommen bei Dashtar!",
  Cookies: "Kekse",
  License: "Lizenz",
  Content: "Haftung für Inhalte",
  Disclaimer: "Haftungsausschluss",
  DisclaimerDes:
    "Soweit nach geltendem Recht zulässig, schließen wir alle Zusicherungen, Gewährleistungen und Bedingungen in Bezug auf unsere Website und die Nutzung dieser Website aus. Nichts in diesem Haftungsausschluss wird:1. unsere oder Ihre Haftung für Tod oder Körperverletzung einschränken oder ausschließen;2. unsere oder Ihre Haftung für Betrug oder betrügerische Falschdarstellung einschränken oder ausschließen;3. unsere oder Ihre Verbindlichkeiten auf eine Weise beschränken, die nach geltendem Recht nicht zulässig ist; oder4. Ausschluss unserer oder Ihrer Verbindlichkeiten, die nach geltendem Recht nicht ausgeschlossen werden können.Die in diesem Abschnitt und an anderer Stelle in diesem Haftungsausschluss festgelegten Haftungsbeschränkungen und -verbote: (a) unterliegen dem vorstehenden Absatz; und (b) regeln alle Verbindlichkeiten, die sich aus dem Haftungsausschluss ergeben, einschließlich Verbindlichkeiten, die sich aus Vertrag, unerlaubter Handlung und Verletzung gesetzlicher Pflichten ergeben. Solange die Website und die Informationen und Dienste auf der Website kostenlos zur Verfügung gestellt werden, haften wir nicht für Verluste oder Schäden jeglicher Art.",
  ContentDescription:
    "Wir übernehmen keine Verantwortung für Inhalte, die auf Ihrer Website erscheinen. Sie stimmen zu, uns gegen alle Ansprüche zu schützen und zu verteidigen, die auf Ihrer Website erhoben werden. Auf einer Website dürfen keine Links erscheinen, die als verleumderisch, obszön oder kriminell ausgelegt werden können oder die Rechte Dritter verletzen, anderweitig verletzen oder deren Verletzung oder sonstige Verletzung befürworten. Ohne vorherige Zustimmung und schriftliche Genehmigung dürfen keine Frames um unsere Webseiten herum erstellen, die die visuelle Darstellung oder das Erscheinungsbild unserer Website in irgendeiner Weise verändern.",
  LicenseDescription:
    "Sofern nicht anders angegeben, besitzen Dashtar und/oder seine Lizenzgeber die geistigen Eigentumsrechte für alle Materialien auf Dashtar. Alle geistigen Eigentumsrechte sind vorbehalten. Sie können von Dashtar für Ihren eigenen persönlichen Gebrauch darauf zugreifen, vorbehaltlich der in diesen Geschäftsbedingungen festgelegten Einschränkungen. Diese Vereinbarung beginnt mit dem Datum dieser Vereinbarung. Unsere AGB wurden mit Hilfe des AGB-Generators erstellt.Du darfst nicht:1. Identifikatoren (z. B. Name, Postanschrift, E-Mail-Adresse, Telefonnummer, Kredit-/Debitkartennummer)2. Merkmale geschützter Klassifikationen (z. B. Geschlecht, Alter)3. Handelsinformationen (z. B. gekaufte Produkte oder Dienstleistungen, Kaufhistorie)4. Internet- oder andere elektronische Netzwerkaktivitäten (z. B. Browser- oder Suchverlauf)5. Geolokalisierungsdaten (z. B. Breiten- oder Längengrad)6. Audio-, elektronische, visuelle oder ähnliche Informationen (z. B. Aufzeichnung von Anrufen des Gästeservices)7. Schlussfolgerungen aus dem Obigen (z. B. Vorlieben oder Eigenschaften)Teile dieser Website bieten Benutzern die Möglichkeit, Meinungen und Informationen in bestimmten Bereichen der Website zu veröffentlichen und auszutauschen. Dashtar filtert, bearbeitet, veröffentlicht oder überprüft Kommentare nicht, bevor sie auf der Website vorhanden sind. Kommentare spiegeln nicht die Ansichten und Meinungen von Dashtar, seinen Vertretern und/oder verbundenen Unternehmen wider. Kommentare spiegeln die Ansichten und Meinungen der Person wider, die ihre Ansichten und Meinungen veröffentlicht. Soweit nach geltendem Recht zulässig, haftet Dashtar nicht für die Kommentare oder für jegliche Haftung, Schäden oder Kosten, die durch die Nutzung und/oder das Posten und/oder Erscheinen der Kommentare dazu verursacht und/oder erlitten werden Webseite.",
  CookiesDescription:
    "Wir verwenden Cookies. Durch den Zugriff auf Dashtar haben Sie der Verwendung von Cookies in Übereinstimmung mit der Datenschutzrichtlinie von Dashtar zugestimmt. Die meisten interaktiven Websites verwenden Cookies, damit wir die Benutzerdaten bei jedem Besuch abrufen können. Cookies werden von unserer Website verwendet, um die Funktionalität bestimmter Bereiche zu ermöglichen und den Besuchern unserer Website den Besuch zu erleichtern. Einige unserer Affiliate-/Werbepartner können ebenfalls Cookies verwenden.",
  WelcomeDescription:
    "Diese Allgemeinen Geschäftsbedingungen beschreiben die Regeln und Vorschriften für die Nutzung der Website von Dashtar, die unter https://dashter.com/ zu finden ist. Durch den Zugriff auf diese Website gehen wir davon aus, dass Sie diese Bedingungen akzeptieren. Verwenden Sie Dashtar nicht weiter, wenn Sie nicht damit einverstanden sind, alle auf dieser Seite aufgeführten Bedingungen zu akzeptieren. Die folgende Terminologie gilt für diese Allgemeinen Geschäftsbedingungen, die Datenschutzerklärung und den Haftungsausschluss sowie alle Vereinbarungen: „Kunde“, „Sie“ und „Ihr“ bezieht sich auf Sie, die Person, die sich auf dieser Website anmeldet und die den Allgemeinen Geschäftsbedingungen des Unternehmens entspricht. „Das Unternehmen“, „uns“, „wir“, „unser“ und „uns“ beziehen sich auf unser Unternehmen. „Partei“, „Parteien“ oder „uns“ bezieht sich sowohl auf den Kunden als auch auf uns selbst. Alle Bedingungen beziehen sich auf das Angebot, die Annahme und die Berücksichtigung der Zahlung, die erforderlich sind, um den Prozess unserer Unterstützung des Kunden auf die am besten geeignete Weise durchzuführen, um ausdrücklich die Bedürfnisse des Kunden in Bezug auf die Erbringung der angegebenen Dienstleistungen des Unternehmens zu erfüllen, in Übereinstimmung mit und unterliegen dem geltenden Recht der Niederlande. Jegliche Verwendung der obigen Terminologie oder anderer Wörter in Singular, Plural, Großschreibung und/oder er/sie oder sie gelten als austauschbar und beziehen sich daher auf dasselbe.",

  //Forgot password

  ForgotpasswordTitle: "Passwort vergessen",
  RecoverpasswordBtn: "Passwort wiederherstellen",

  //POS Setting

  PosSetting: "POS-Einstellung",
  StoreDetails: "Store-Details",
  ShopLogo: "Shop-Logo",
  ShopName: "Name des Shops",
  CompanyName: "Firmenname",
  VATNumber: "Umsatzsteuer-Identifikationsnummer",
  ShopAddressLine1: "Shop-Adresszeile 1",
  ShopAddressLine2: "Shop-Adresszeile 2",
  ShopAddressLine3: "Shop-Adresszeile 3",
  ContactNumber1: "Kontaktnummer 1",
  ContactNumber2: "Kontaktnummer 2",
  Email: "Email",
  WebSite: "Website",
  AnyInfoDisplayinSlip: "Beliebige Infoanzeige im Beleg",
  AnyInfoDisplayinSlip1: "Alle zusätzlichen Informationen werden unten auf dem Beleg angezeigt",
  Configuration: "Konfiguration",
  ShowImages: "Bilder anzeigen",
  DefaultPOSCustomer: "Standard-POS-Kunde",
  EmailInvoiceToAdmin: "E-Mail-Rechnung an Administrator(en)",
  ReceiptSize: "Quittungsgröße (Breite)",
  PreviewReceipt: "Quittungsvorschau",

  Alloworderingofoutofstockproducts: "Bestellung von nicht vorrätigen Produkten zulassen",
  ShowPaymentOptions: "Zahlungsoptionen anzeigen",
  DefaultPaymentMethod: "Standardzahlungsmethode",
  AllowDiscounts: "Rabatte zulassen",
  PrintSlip: "Beleg drucken",
  EmailSlip: "E-Mail-Beleg an den Kunden",

  OrderTypeCapText: "Dies wird in der POS-Produktkarte angezeigt.",
  DefaultCurrency: "Standardwährung",
  DefaultTaxRate: "Standardsteuersatz",
  SaveBtn: "Speichern",
  previous: "Vorherige",
  Next: "Nächste",
  PaymentMethods: "Zahlungsarten",
  Combined: "Kombiniert",
  NumberProductShowPOSHomePage: "Anzahl der auf der POS-Startseite anzuzeigenden Produkte",
  ProductSorting: "Produktsortierung",
  Billing: "Abrechnung",
  NumberOfCategoriestoShowinPOSHomePage: "Anzahl der auf der POS-Startseite anzuzeigenden Kategorien",
  ApplyTheCostOfTheHighestDefinedRange: "Wenden Sie die Kosten des höchsten definierten Bereichs an",
  AddNewRange: "Neuen Bereich hinzufügen",

  ShowStockDetailsinProductPage: "Bestandsdetails auf der Produktseite anzeigen",
  ShowStockDetailsInStore: "Bestandsdetails im Geschäft anzeigen (global)",
  ShowLowStockNotification: "Benachrichtigung über niedrigen Lagerbestand anzeigen",
  LowStockNotificationValue: "Benachrichtigungswert für niedrigen Bestand",
  Poshomepageview: "Ansicht der POS-Homepage",
  Defaultposcategory: "Standard-POS-Kategorie",
  DefaultposcategorySmText: "Produkte in dieser Kategorie werden standardmäßig im POS angezeigt",
  ContactDetails: "Kontaktdetails",
  PostCode: "Postleitzahl",
  Theme: "Theme",
  ICON: "Postleitzahl",
  ThemeSmallText: "Das ausgewählte Thema wird im POS angezeigt",
  EmailRecipients: "E-Mail-Empfänger",

  // International (Localization)
  Languages: "Sprachen",
  Currencies: "Währungen",
  Geolocation: "Geolokalisierung",
  DefaultLanguage: "Standardsprache",
  SetLanguageFromBrowser: "Stellen Sie die Sprache im Browser ein",
  DefaultCountry: "Standardland",
  TimeZone: "Standardzeitzone",
  LocalUnits: "Gewichtseinheit",
  WeightUnit: "Entfernungseinheit",
  DistanceUnit: "Distance Unit",
  VolumeUnit: "Volumeneinheit",
  DimensionUnit: "Maßeinheit",
  UnitChangeBoxTitle: "Möchten Sie dies wirklich ändern?",
  globalDescription:
    "Sie haben bereits untergeordnete Produkte im System. Wenn Sie diese Funktion deaktivieren, wird die Veröffentlichung aufgehoben. Möchten Sie fortfahren ?",
  UnitChangeBoxDiscription:
    "Das Ändern des Einheitensystems kann zu Fehlverhalten bestehender Produkte und Bestände führen. Sind Sie sicher, dass Sie dies tun möchten?",
  YesChangeItBtn: "Ja, ändere es",
  InternationalCustom: "International / Benutzerdefiniert",
  MetricSystemImperialSystem: "Metrisches System / Imperiales System",
  Custom: "Brauch",
  Metric: "Metrisch",
  Imperial: "Kaiserliche",
  Length: "Länge",
  SelectLength: "Wählen Sie Länge",
  Centimeter: "Zentimeter (cm)",
  Meter: "Meter (m)",
  Kilometer: "Kilometer (Km)",
  Weight: "Gewicht",
  SelectWeight: "Gewicht auswählen",
  Gram: "Gram (g)",
  Volume: "Volumen",
  SelectVolume: "Wählen Sie Lautstärke",
  Milliliter: "Milliliter (ml)",
  Liter: "Liter (l)",
  Mile: "Meile",
  Yard: "Hof",
  Foot: "Fuß",
  Inch: "Inch",
  Ounce: "Unze",
  Pound: "Pfund",
  Gallon: "Gallone",
  Pint: "Pint",
  Quart: "Quart",
  TotalVolume: "Volle Lautstärke",
  Width: "Breite",
  Height: "Höhe",
  GenerateVariants: "Varianten generieren",
  ClearVariants: "Klare Varianten",
  AttributesFeatures: "Attribute und Funktionen",
  AddCombinationsDiscription:
    "Bitte fügen Sie Kombinationen hinzu oder aktivieren Sie Ihr Attribut, Sie müssen zuerst die richtigen Attribute und Werte erstellen",
  AddCombinationsDiscriptionTwo:
    'Wenn Sie fertig sind, können Sie die gewünschten Attribute (wie "Größe" oder "Farbe") und ihre jeweiligen Werte ("XS", "Rot", "Alle" usw.) in das Feld darunter eingeben; oder wählen Sie sie einfach aus der rechten Spalte aus. Klicken Sie dann auf „Generieren“: Es werden automatisch alle Kombinationen für Sie erstellt!',
  GenerateExtras: "Extras generieren",
  ClearExtras: "Klare Extras",
  SelectedExtra: "Ausgewähltes Extra",

  // International (Languages)
  LanguagesSr: "SR",
  LanguagesNname: "NAME",
  LanguagesIsoCode: "ISO CODE",
  LanguageCode: "SPRACHENCODE",
  LanguagesFlag: "FLAGGE",
  LanguagesPublished: "VERÖFFENTLICHT",
  LanguagesActions: "AKTIONEN",
  AddLanguageName: "Sprache Name",
  AddLanguagesIsoCode: "Iso-Code",
  AddLanguageCode: "Sprachencode",
  AddLanguagesFlag: "Flagge",
  AddLanguagesPublished: "Veröffentlicht",
  SearchLanguage: "Suche nach Ländernamen und ISO-Code, Sprachcode",

  // International (Currencies)
  CurrenciesName: "Name",
  Currencyisocode: "Iso-Code",
  CurrenciesSymbol: "Symbol",
  CurrenciesIsoCode: "Iso-Code",
  CurrenciesExchangeRate: "Tauschrate",
  CurrenciesEnabled: "Ermöglicht",
  CurrenciesLiveExchangeRates: "Live-Wechselkurse",
  CurrenciesActions: "Aktionen",
  SearchIsoCode: "Suche nach ISO-Code",

  // Language Drawer
  AddLanguage: "Sprache hinzufügen",
  AddLanguageText: "Fügen Sie hier Ihre erforderlichen Sprachinformationen hinzu",
  UpdateLanguage: "Sprache aktualisieren",
  UpdateLanguageText: "Aktualisierte Ihre erforderlichen Sprachinformationen von hier",
  LanguageName: "SPRACHE NAME",

  // Update Drawer
  UpdateCurrency: "Währung aktualisieren",
  UpdateCurrencyText: "Aktualisierte Ihre Währung und die erforderlichen Informationen von hier aus",
  AddCurrency: "Währung hinzufügen",
  AddCurrencyText: "Fügen Sie hier Ihre Währung und die erforderlichen Informationen hinzu",

  // International (Locations)
  TaxRate: "Steuersatz",
  TaxName: "Steuername",
  Zones: "Zones",
  Zone: "Zone",
  Countries: "Länder",
  Rates: "Rates",
  TaxZone: "Steuerzone",
  ShippingZone: "VersandZone",
  SearchBy: "Suche nach Zonenname",
  Show: "Anzeigen",
  Hide: "Ausblenden",
  NameTbl: "NAME",
  SRTbl: "SR",
  IsoCode: "Iso-Code",
  CallPrefix: "Anrufpräfix",
  Country: "Land",
  States: "Zustände",
  SearchByStateNameAndIsoCode: "Suche nach Staatsnamen und ISO-Code",
  UpdatedZone: "Zone aktualisieren",
  UpdatedZoneText: "Aktualisierte Ihre erforderlichen Zoneninformationen von hier aus",
  AddZone: "Zone hinzufügen",
  AddZoneText: "Fügen Sie von hier aus die erforderlichen Informationen für Ihre Zone hinzu",
  ZoneName: "Zonenname",
  UpdateCountry: "Land aktualisieren",
  UpdateCountryText: "Aktualisierte die für Ihr Land erforderlichen Informationen von hier aus",
  AddCountry: "Land hinzufügen",
  AddCountryText: "Fügen Sie hier die erforderlichen Informationen für Ihr Land hinzu",
  CountryName: "Ländername",
  UpdateState: "Status aktualisieren",
  UpdateStateText: "Aktualisierte die für Ihren Staat erforderlichen Informationen von hier aus",
  AddState: "Staat hinzufügen",
  AddStateText: "Fügen Sie hier die erforderlichen Informationen für Ihr Bundesland hinzu",
  StateName: "Staatsname",
  StateBtn: "Bundesland",
  AddTaxZone: "Steuerzone hinzufügen",
  AddTaxZoneText: "Fügen Sie hier die erforderlichen Informationen für Ihre Steuerzone hinzu",
  UpdateTaxZone: "Steuerzone aktualisieren",
  UpdateTaxZoneText: "Aktualisierte die erforderlichen Informationen für Ihre Steuerzone von hier aus",
  TaxZoneName: "Name der Steuerzone",
  State: "Bundesland",
  TaxZoneBtn: "Steuerzone",
  UpdateShippingZone: "Versandzone aktualisieren",
  UpdateShippingZoneText: "Aktualisierte Ihre erforderlichen Informationen für die Versandzone von hier aus",
  AddShippingZone: "Versandzone hinzufügen",
  AddShippingZoneText: "Fügen Sie hier die erforderlichen Informationen für Ihre Versandzone hinzu",
  ShippingZoneName: "Name der Versandzone",
  ShippingRate: "Versandkosten",
  ShippingZoneBtn: "Versandzone",
  AddTaxes: "Steuern hinzufügen",
  AddTaxesText: "Fügen Sie hier Ihre Steuern und erforderlichen Informationen hinzu",
  UpdateTaxes: "Steuern aktualisieren",
  UpdateTaxesText: "Aktualisieren Sie hier Ihre Steuern und erforderlichen Informationen",
  TaxesBtn: "Steuern",
  shippingZoneSearch: "Suchen Sie nach dem Namen der Versandzone",
  taxNameSearch: "Suche nach Steuernamen",
  // Store Customization
  HomeSettings: "Startseiteneinstellungen",
  SingleSetting: "Einstellung einer einzelnen Produktseite",
  AboutUsSetting: "Über uns",
  PrivacyTCSetting: "Datenschutzerklärung und AGB",
  FAQSetting: "FAQs",
  OffersStting: "Bietet an",
  ContactUsStting: "Kontaktiere uns",
  StoreCustomizationPageTitle: "Shop-Anpassungen",
  Header: "Kopfzeile",
  HeaderContacts: "Header-Kontakte",
  HeaderText: "Kopfzeilentext",
  PhoneNumber: "Telefonnummer",
  HeaderLogo: "Header-Logo",
  weAreAvailable: "Wir sind rund um die Uhr für Sie da. Brauchen Sie Hilfe?",
  MainSlider: "Hauptslider",
  Slider: "Schieberegler",
  SliderImages: "Slider-Bilder",
  SliderTitle: "Slider-Titel",
  SliderDescription: "Slider-Beschreibung",
  SliderButtonName: "Schieberegler-Schaltflächenname",
  SliderButtonLink: "Schieberegler-Link",
  Options: "Optionen",
  LeftRighArrows: "Pfeile nach links und rechts",
  BottomDots: "Untere Punkte",
  Both: "Beide",
  DiscountCouponTitle1: "Rabattgutschein-Code-Box",
  DiscountCouponCodeTitle2: "Rabatt-Gutscheincode",
  ShowHide: "Anzeigen / Ausblenden",
  SuperDiscountActiveCouponCode: "Super-Rabatt-Aktiv-Gutscheincode",
  SliderFullWidth: "Schieberegler volle Breite",
  PlaceHolderImage: "Platzhalterbild",
  TableNavigation: "Tabellennavigation",
  PromotionBanner: "Werbebanner",
  EnableThisBlock: "Diesen Block aktivieren",
  Title: "Titel",
  sorryCategoryNotFound: "Entschuldigung, im Moment gibt es keine Kategorien.",
  Description: "Beschreibung",
  DESCRIPTION: "BESCHREIBUNG",
  PromotionDescription: "Angebotsbeschreibung",
  ButtonName: "Button-Name",
  ButtonLink: "Button-Link",
  FeaturedCategories: "Empfohlene Kategorien",
  ProductsLimit: "Produktlimit",
  PopularProductsTitle: "Beliebte Produkte",
  PopularDescription: "Beliebte Beschreibung",
  QuickDeliverySectionTitle: "Quick Delivery Section",
  SubTitle: "Untertitel",
  Image: "Bild",
  LatestDiscountedProductsTitle: "Neueste reduzierte Produkte",
  GetYourDailyNeedsTitle: "Erhalte deine täglichen Bedürfnisse",
  LatestDiscountDescription: "Neueste Rabattbeschreibung",
  DailyNeedDescription: "Beschreibung des täglichen Bedarfs",
  Button1Link: "Schaltfläche 1 Verknüpfung",
  Button2Link: "Schaltfläche 2 Verknüpfung",
  Button1image: "Schaltfläche 1 Bild",
  Button2image: "Schaltfläche 2 Bild",
  ImageLeft: "Bild links",
  ImageRight: "Bild rechts",
  FeaturePromoSectionTitle: "Feature-Promo-Abschnitt",
  FreeShipping: "Kostenloser Versand",
  Support: "Unterstützung",
  SecurePayment: "Sichere Zahlung",
  LatestOffer: "Neuestes Angebot",
  TopCategory: "Top-Kategorie",
  FooterTitle: "Fußzeile",
  Block: "Blockieren",
  Link: "Link",
  FooterLogo: "Footer-Logo",
  FooterAddress: "Adresse",
  FooterPhone: "Telefon",
  FooterEmail: "E-Mail",
  FishAndMeat: "Fisch Fleisch",
  FooterBottomContact: "Fußzeile Unten Kontaktnummer",
  SocialLinksTitle: "Soziale Links",
  PaymentMethod: "Zahlungsmethode",
  Statement: "Statement",
  SearchStaffName: "Suchen Sie nach dem Namen des offenen oder schließenden Personals",
  SoftDrinks: "Alkoholfreie Getränke",
  BabyCare: "Säuglingspflege",
  BeautyAndHealth: "Schönheit und Gesundheit",
  MyAccount: "Mein Konto",
  MyOrders: "Meine Bestellungen",
  RecentOrders: "letzte Bestellungen",
  UpdatedProfile: "Aktualisiertes Profil",
  SocialLinks: "Soziale Links",

  //Online Store Setting
  OnlineStorePageTitle: "Online-Shop-Einstellung",
  General: "Allgemein",
  MainShopActivity: "Hauptgeschäft Aktivität",
  AllowStripe: "Streifen zulassen",
  StripePublicKey: "Öffentlicher Stripe-Schlüssel",
  AllowOrderingOfOutOfStock: "Erlauben Sie die Bestellung von nicht vorrätigen Produkten",
  CustomDomainName: "Benutzerdefinierter Domänenname",
  ShopDomain: "Shop-Domain",
  Verify: "Verifizieren",
  AllowPaypal: "Paypal zulassen",
  PaypalPublicKey: "Öffentlicher Paypal-Schlüssel",

  // Globale Settings
  GlobalSettings: "Globale Einstellungen",
  UseCompositionProduct: "Zusammensetzungsprodukt verwenden",
  CashiersCanOverrideInuseItems: "Kassierer können unbenutzte Gegenstände überschreiben",
  UseMultipleLanguagesInShop: "Ich möchte in meinem Shop, Admin und POS mehrere Sprachen verwenden",
  UseMultipleCurrenciesInShop: "Ich möchte mehrere Währungen in meinem Shop, Admin und POS verwenden",
  NumberOfImagesPerProduct: "Anzahl der Bilder pro Produkt",
  PosView: "POS-Ansicht",
  TodayTime: "Heute Zeit",
  StartDate: "Anfangsdatum",
  EndDate: "Endtermin",
  InvoiceNumberStartValue: "Invoice number start value",
  InvoiceNumberLeadingZeros: "Invoice number leading zeros",
  RetailPOS: "Retail POS",
  Restaurant: "Restaurant",
  Shipping: "Versand",
  AddShipping: "Versand hinzufügen",
  SearchByShippingName: "Suche nach Versandname",
  ShippingName: "Versandname",
  ShippingDescription: "Versandbeschreibung",
  Logo: "Logo",
  AddShippingText: "Fügen Sie hier Ihren Versand und die erforderlichen Informationen hinzu",
  UpdateShipping: "Versand aktualisieren",
  UpdateShippingText: "Aktualisieren Sie Ihre Versand- und erforderlichen Informationen von hier aus",
  CarrierLogo: "Carrier-Logo",
  Reports: "Berichte",
  // Orders: 'Bestellungen',
  Source: "Quelle",
  SHOP: "LADEN",
  Actions: "Aktionen",
  Speedgrade: "Geschwindigkeitsgrad",
  TrackingURL: "Tracking-URL",
  Select: "Wählen",
  ApplyTheCostTheHighestDefinedRange: "Wenden Sie die Kosten des höchsten definierten Bereichs an",
  DisableCarrier: "Mobilfunkanbieter deaktivieren",
  InvoiceNumberType: "Art der Rechnungsnummer",
  AddressLine: "Adresse",
  GlobalContactNumber: "Kontakt",
  InvoiceCompanyName: "Name der Firma",
  VatNumber: "Umsatzsteuer-Identifikationsnummer",
  Vat: "MwSt",
  InvoiceNumberLength: "Länge der Rechnungsnummer",
  DefaultDateFormat: "Standard-Datumsformat",

  // Menu Editor
  MenuEditor: "Menü-Editor",
  Categories: "Kategorien",
  AboutUs: "Über uns",
  ContactUs: "Kontaktiere uns",
  Careers: "Karriere",
  LatestNews: "Neuesten Nachrichten",
  Offers: "Bietet an",
  FAQ: "FAQs",
  PrivacyPolicy: "Datenschutz-Bestimmungen",
  TermsConditions: "Terms & Bedingungen",

  // About Page
  PageHeader: "Seitenkop",
  PageHeaderBg: "Hintergrund der Seitenkopfzeile",
  PageTitle: "Seitentite",
  AboutPageTopContentLeft: "Über Seitenanfang Inhalt links",
  TopTitle: "Top-Titel",
  TopDescription: "Top-Beschreibung",
  BoxOneTitle: "Box Eins Titel",
  BoxOneSubtitle: "Box Eins Untertitel",
  BoxOneDescription: "Box 1 Beschreibung",
  BoxTwoTitle: "Box Two Title",
  BoxTwoSubtitle: "Box 2 Untertitel",
  BoxTwoDescription: "Box 2 Beschreibung",
  PageTopContentRight: "Seitenanfang Inhalt rechts",
  TopContentRightImage: "Top Inhalt Rechtes Bild",
  RightBox: "Rechte Kiste",
  MiddleContentSection: "Inhaltsbereich",
  MiddleDescriptionOne: "Erster Paragraph",
  MiddleDescriptionTwo: "Zweiter Absatz",
  MiddleContentImage: "Inhaltsbild",
  OurFounder: "Unser Team",
  OurFounderTitle: "Unser Teamtitel",
  OurFounderDescription: "Unsere Teambeschreibung",
  OurFounderOneImage: "Unser Team Ein Bild",
  OurFounderOneTitle: "Unser Team One Titel",
  OurFounderOneSubTitle: "Unser Team One Untertitel",
  OurFounderTwoImage: "Unser Team Zwei Bild",
  OurFounderTwoTitle: "Unser Team Zwei Titel",
  OurFounderTwoSubTitle: "Unser Team Two Untertitel",
  OurFounderThreeImage: "Unser Team Drei Bild",
  OurFounderThreeTitle: "Unser Team Drei Titel",
  OurFounderThreeSubTitle: "Unser Team drei Untertitel",
  OurFounderFourImage: "Unser Team Vier Bild",
  OurFounderFourTitle: "Unser Team Four Titel",
  OurFounderFourSubTitle: "Unser Team Four Untertitel",
  OurFounderFiveImage: "Unser Team Five Bild",
  OurFounderFiveTitle: "Unser Team Five Titel",
  OurFounderFiveSubTitle: "Unser Team Five Untertitel",
  OurFounderSixImage: "Unser Team Six Bild",
  OurFounderSixTitle: "Unser Team Six Titel",
  OurFounderSixSubTitle: "Unser Team Six Untertitel",
  OurTeam: "Mitglied",

  PrivacyPolicyTermsTitle: "Datenschutzerklärung und AGB",
  PageText: "Seitentext",
  LeftImage: "Linkes Bild",
  FaqTitleOne: "Faq Titel Eins",
  FaqDescriptionOne: "Faq Beschreibung Eins",
  FaqTitleTwo: "Faq-Titel Zwei",
  FaqDescriptionTwo: "Faq Beschreibung Zwei",
  FaqTitleThree: "Faq Titel Drei",
  FaqDescriptionThree: "Faq Beschreibung Dre",
  FaqTitleFour: "Faq Titel Vier",
  FaqDescriptionFour: "Faq-Beschreibung Vier",
  FaqTitleFive: "Faq Titel Fünf",
  FaqDescriptionFive: "Faq-Beschreibung Fünf",
  FaqTitleSix: "Faq Titel Sechs",
  FaqDescriptionSix: "Faq Beschreibung Six",
  FaqTitleSeven: "Faq Titel Sieben",
  FaqDescriptionSeven: "Faq Beschreibung Seven",
  FaqTitleEight: "Faq Titel Acht",
  FaqDescriptionEight: "Faq Beschreibung Acht",
  FAQPageHeader: "Kopfzeile der FAQ-Seite",
  FaqLeftCol: "FAQs Linke Spalte",

  EmailUs: "Mailen Sie uns Box",
  EboxTitle: "Titel",
  EboxEmail: "E-Mail",
  Eboxtext: "Text",

  CallUs: "Call Us Box",
  CallusboxTitle: "Titel",
  CallUsboxPhone: "Telefonnumme",
  CallUsboxText: "Text",

  Address: "Adressfeld",
  AddressboxTitle: "Titel",
  AddressboxAddressOne: "Adresse",
  AddressboxAddressTwo: "Adresse Zwei",
  AddressboxAddressThree: "Adresse Drei",

  MidLeftCol: "Mittlere linke Spalt",
  MidLeftImage: "Bild Mitte links",

  ContactForm: "Kontaktformular",
  ContactFormTitle: "Titel des Kontaktformulars",
  ContactFormDescription: "Beschreibung des Kontaktformulars",

  // Shipping Page

  Carriers: "Carriers",
  AddNewCarrier: " Add New Carrier",
  GeneralSettings: "Allgemeine Einstellungen",
  ShippingLocationsCosts: "Versandorte und Kosten",
  SizeWeightGroupAccess: "Größe, Gewicht und Gruppenzugang",
  Summary: "Zusammenfassung",
  AddHandlingCosts: "Bearbeitungskosten hinzufügen",
  AccordingToTotalPrice: "Nach Gesamtpreise",
  AccordingToTotalWeight: "Nach Gesamtgewicht",
  Tax: "Tax",
  OutOfRangeBehavior: "Verhalten außerhalb der Reichweite",
  Ranges: "Ranges",
  WillBeAppliedWhenPrice: "Wird angewendet, wenn der Preis ist",
  AddNewRangeBtn: "Neuen Bereich hinzufügen",
  MaximumPackageWidth: "Maximale Paketbreite (cm)",
  MaximumPackageHeight: "Maximale Pakethöhe (cm)",
  MaximumPackageDepth: "Maximale Pakettiefe (cm)",
  MaximumPackageWeight: "Maximales Paketgewicht (kg)",
  EditShipping: "Versand bearbeiten",
  EditShippingDescription: "Bearbeiten Sie hier Ihren Versand und die erforderlichen Informationen",
  CarrierName: "Anbietername",
  ShippingSummary1: "Dieser Spediteur ist kostenlos und die Laufzeit ist",
  ShippingSummary2:
    "Die Versandkosten werden nach dem Gewicht und der Steuerregel berechnet. Es wird keine Steuer erhoben",
  ShippingSummary3: "Dieser Spediteur kann Bestellungen aus liefern",
  ShippingSummary4: "kg zu",
  ShippingSummary5:
    "kg. Wenn der Auftrag außerhalb des Bereichs liegt, werden die Kosten des höchsten definierten Bereichs angewendet.",
  DeliveryZones: "Dieser Spediteur wird für diese Lieferzonen vorgeschlagen",

  // KdsOrdrs
  GoBackKitchenOrders: "Gehen Sie zurück zu Küchenbestellungen",
  SorryNoCompleteKitchenOrderAvailableNow: "Tut mir leid, jetzt sind keine kompletten Küchenbestellungen verfügbar!",

  GoBackPOS: "Gehen Sie zurück zum POS",
  Completed: "Vollendet",
  SorryNoKitchenOrderAvailableNow: "Tut mir leid, jetzt sind keine Küchenbestellungen verfügbar!",

  // BarOrdrs
  SorryNoBarOrderAvailableNow: "Entschuldigung, jetzt sind keine Barbestellungen verfügbar!",
  GoBackBarOrders: "Gehen Sie zurück zu Barbestellungen",
  SorryNoCompleteBarOrderAvailableNow: "Tut mir leid, jetzt sind keine kompletten Bar-Bestellungen verfügbar!",
  StoreCustomization: "Shop-Anpassung",

  //Statement
  CloseTime: "Schließzeit",
  OpeningUser: "Benutzer öffnen",
  ClosingUser: "Benutzer schließen",
  StFloat: "ST. Schweben",
  Sale: "Verkauf",
  CurrFloat: "Curr Float",
  TMWFloat: "TMW-Schwimmer",
  Action: "Aktion",
  Import: "Importieren",
  Export: "Export",
  ImportNow: "Jetzt importieren",
  SelectYourJSON: "Wählen Sie Ihr JSON, CSV, XLS aus",
  File: "datei",
  ProductPageNavigation: "Produktseitennavigation",
  sorryProductNotFound: "Entschuldigung, im Moment gibt es keine Produkte.",
  // Reset password
  ResetPassword: "Passwort zurücksetzen",
  ConfirmPassword: "Bestätige das Passwort",
  Reset: "Zurücksetzen",
  AlreadyHaveAccount: "Sie haben bereits ein Konto? Anmeldung",
  DoesProductHaveCombinations: "Hat dieses Produkt Kombinationen?",

  // Tooltip Translation
  MasterProductTooltipText:
    "Alle Produkte und Kombinationen gelten standardmäßig als Master-Produkte. Sie können Attribute/Kombinationen für Master-Produkte haben.",
  SellIndividualUnitsTooltipText:
    "Aktivieren Sie diese Option, um dieses Produkt als einzelne Einheit zu verkaufen. Beispiel : Flasche Wein, Packung Kekse",
  ProductQuantityTooltipText:
    "Anzahl der Artikel auf Lager. Wenn Sie Kombinationen haben, wird dies automatisch basierend auf jedem Kombinationsbestand berechnet.",
  AllowOutStockOrderTooltipText:
    "Ermöglichen Sie Benutzern, Artikel zu bestellen, wenn der Vorrat am POS und/oder im Geschäft ausverkauft ist",
  AllowNotesOnCheckoutTooltipText: "Erlauben Sie Benutzern, Notizen zum POS und/oder Geschäft hinzuzufügen",
  RelatedProductsAndCategoriesText: "Verwandte Produkte und Kategorien werden im modalen POS/Store angezeigt",
  ProductMeasurementsTooltipText1: "Gesamtlänge/Gewicht/Volumen",
  ProductMeasurementsTooltipText2:
    "Insgesamt verfügbares Guthaben basierend auf der „Produktmenge“ und der ausgewählten „Messung“",
  ProductMeasurementsTooltipText3: "Beispiel: Volumen – 1000 ml, Produktmenge – 2 → Gesamtvolumen = 2000 ml",
  ShippingTooltipText: "Spezifikationen für den Versand.",
  DISPLAYNAME: "ANZEIGENAME",
  OPTION: "MÖGLICHKEIT",
  DisplayNameTooltipText: "Dies wird in POS und Store angezeigt",
  PIN: "PIN",
  PinTooltipText:
    "Diese Nummer wird im POS zusammen mit dem Passwort zur Authentifizierung verwendet. Merken Sie sich diese Nummer unbedingt. Maximal 8 Ziffern.",

  DefaultLanguageTooltipText: "Legen Sie die Standardsprache für POS und Admin fest",
  SetLanguageFromBrowserTooltipText:
    "Wenn auf Ja gesetzt, wird die Sprache automatisch auf die Browsersprache eingestellt (werden unterstützt)",
  InternationalCustomTooltipText:
    "Sie können entweder das internationale metrische/imperiale System oder Ihre eigenen benutzerdefinierten Einheiten verwenden.",

  // RESTAURANT MENU
  RestaurantMenu: "SPEISEKARTE",
};
