import React from "react";
import { Select } from "@windmill/react-ui";

const SelectLocationCategory = ({ field, options, ...props }) => {
  return (
    <>
      <Select
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        className="block w-full px-2 py-1 text-sm dark:text-gray-300 focus:outline-none rounded-md form-select focus:border-gray-200 border-gray-200 dark:border-gray-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-gray-500 dark:focus:ring-gray-300 dark:bg-gray-700 leading-5 border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
      >
        <option value="">Select {props.label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </>
  );
};

export default SelectLocationCategory;
