import React, { useState, useRef, useEffect } from "react";
import LabelArea from "../../../components/form/LabelArea";
import InputArea from "../../../components/form/InputArea";
import Error from "../../../components/form/Error";
import { Button } from "@windmill/react-ui";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import SelectGender from "../../../components/form/SelectGender";
import SelectLocationCategory from "../../../components/form/SelectLocationCategory";
import { postRequest } from "../../../helpers/requests";
import { notifyError, notifySuccess } from "../../../utils/toast";
import FormToolTip from "../../../components/form/FormToolTip";

const SignUpBookingEntity = () => {
  const [isVirtual, setIsVirtual] = useState(false);
  const [isInClinic, setIsInClinic] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      addresses: [
        { buildingName: "", street: "", city: "", country: "", postcode: "", county: "", latitude: "", longitude: "" },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  const onSubmit = async (data) => {
    const bookingTypes = [];
    if (isInClinic) {
      bookingTypes.push("'In-Clinic'");
    }
    if (isVirtual) {
      bookingTypes.push("'Virtual'");
    }
    if (isHome) {
      bookingTypes.push("'Home Visit'");
    }

    const payload = {
      ...data,
      bookingTypes: `[${bookingTypes.join(", ")}]`,
      addresses: data.addresses,
    };

    const response = await postRequest("entities/create", payload);
    if (response.status === "success") {
      notifySuccess("Booking entity successfully created");
      navigate("/");
    } else {
      const errorMessage = response.message || "Failed to create booking entity";
      notifyError(errorMessage);
    }
  };

  const inputFields = [
    {
      title: "",
      fields: [
        {
          label: "Booking Entity Name",
          name: "bookingEntityName",
          description: "",
          type: "text",
          fullWidth: true,
          required: true,
          show: true,
        },
        {
          label: "Clinic Description",
          name: "description",
          description: "",
          type: "text",
          fullWidth: true,
          required: false,
          show: true,
        },
        {
          label: "Latitude",
          name: "latitude",
          description: "This is for the booking entity map marker on the listing page",
          type: "text",
          fullWidth: false,
          required: true,
          show: true,
        },
        {
          label: "Longitude",
          name: "longitude",
          description: "This is for the booking entity map marker on the listing page",
          type: "text",
          fullWidth: false,
          required: true,
          show: true,
        },
        {
          label: "Building Name/Number",
          name: "addresses[0].buildingName",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "Street",
          name: "addresses[0].street",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "City",
          name: "addresses[0].city",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "County",
          name: "addresses[0].county",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "Postcode",
          name: "addresses[0].postcode",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "Country",
          name: "addresses[0].country",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "Latitude",
          name: "addresses[0].latitude",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic || isHome,
          show: isInClinic || isHome,
        },
        {
          label: "Longitude",
          name: "addresses[0].longitude",
          description: "",
          type: "text",
          fullWidth: false,
          required: isInClinic || isHome,
          show: isInClinic || isHome,
        },
        {
          label: "Location Category",
          name: "addresses[0].locationCategoryId",
          description: "",
          type: "selectLocationCategory",
          fullWidth: true,
          required: isInClinic,
          show: isInClinic,
        },
        {
          label: "Public Phone Number",
          name: "phoneNumber",
          description: "",
          type: "text",
          fullWidth: false,
          required: false,
          show: true,
        },
        {
          label: "Public Email",
          name: "email",
          description: "",
          type: "email",
          fullWidth: false,
          required: true,
          show: true,
        },
        {
          label: "Website URL",
          name: "websiteUrl",
          description: "",
          type: "text",
          fullWidth: true,
          required: false,
          show: true,
        },
        {
          label: "Tags",
          name: "tags",
          description: "Up to 5 tags separated by commas",
          type: "text",
          fullWidth: false,
          required: false,
          show: true,
        },
        {
          label: "Ranking",
          name: "ranking",
          description: "",
          type: "number",
          fullWidth: false,
          required: false,
          show: true,
        },
      ],
    },
    {
      title: "Booking User Details",
      fields: [
        {
          label: "Contacts Full Name",
          name: "bookingUser.fullName",
          description: "The full name of the contact person for this clinic",
          type: "text",
          fullWidth: true,
          required: true,
          show: true,
        },
        {
          label: "Gender",
          name: "bookingUser.gender",
          description: "",
          type: "selectGender",
          fullWidth: false,
          required: false,
        },
        {
          label: "Date of Birth",
          name: "bookingUser.dateOfBirth",
          description: "",
          type: "text",
          fullWidth: false,
          required: false,
          show: true,
        },
        {
          label: "Direct Phone Number",
          name: "bookingUser.phoneNumber",
          description: "The direct phone number of the contact person for this clinic",
          type: "text",
          fullWidth: false,
          required: false,
          show: true,
        },
        {
          label: "Direct Email",
          name: "bookingUser.account.username",
          description: "The email address of the contact person for this clinic",
          type: "email",
          fullWidth: false,
          required: true,
          show: true,
        },
        {
          label: "Password",
          name: "bookingUser.account.password",
          description: "",
          type: "password",
          fullWidth: false,
          required: true,
          show: true,
        },
        {
          label: "Confirm Password",
          name: "confirmPassword",
          description: "",
          type: "password",
          fullWidth: false,
          required: true,
          show: true,
        },
      ],
    },
  ];

  const renderCheckboxes = () => (
    <div className="flex items-center space-x-4">
      <div>
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={isInClinic}
            onChange={(e) => setIsInClinic(e.target.checked)}
          />
          <span className="ml-2">In-Clinic</span>
        </label>
      </div>
      <div>
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={isVirtual}
            onChange={(e) => setIsVirtual(e.target.checked)}
          />
          <span className="ml-2">Virtual</span>
        </label>
      </div>
      <div>
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={isHome}
            onChange={(e) => setIsHome(e.target.checked)}
          />
          <span className="ml-2">Home Visit</span>
        </label>
      </div>
    </div>
  );

  const locationOptions = [
    { value: 1, label: "London" },
    { value: 2, label: "Edinburgh" },
    { value: 3, label: "Manchester" },
    { value: 5, label: "Home Visit" },
    { value: 6, label: "Virtual" },
    { value: 7, label: "Glasgow" },
    { value: 9, label: "Cardiff" },
    { value: 10, label: "Belfast" },
    { value: 11, label: "Cambridge" },
  ];

  const renderSections = () => {
    return inputFields.map((section, sectionIndex) => (
      <div key={`section-${sectionIndex}`}>
        <h2 className="mt-6 mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">{section.title}</h2>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          {section.fields.map((field, fieldIndex) => {
            const fieldId = `field-${sectionIndex}-${fieldIndex}`;
            const labelContent = field.description ? (
              <FormToolTip description={field.description}>
                <span>{field.label}</span>
              </FormToolTip>
            ) : (
              <span>{field.label}</span>
            );

            if (field.type === "selectGender") {
              return (
                <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
                  <LabelArea label={labelContent} />
                  <SelectGender
                    register={() =>
                      control.register(field.name, {
                        required: field.required ? `${field.label} is required!` : false,
                      })
                    }
                    name={field.name}
                  />
                  {errors[field.name] && <p className="text-red-500 text-xs italic">{errors[field.name].message}</p>}
                </div>
              );
            } else if (field.name.startsWith("addresses")) {
              return null; // This skips the address fields to avoid duplication, they will be rendered in the renderAddressFields function
            } else {
              return (
                field.show && (
                  <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
                    <LabelArea label={labelContent} />
                    <InputArea
                      register={() =>
                        control.register(field.name, {
                          required: field.required ? `${field.label} is required!` : false,
                        })
                      }
                      defaultValue=""
                      type={field.type}
                      placeholder={`Enter ${field.label.toLowerCase()}`}
                      name={field.name}
                    />
                    {errors[field.name] && (
                      <p className="text-red-500 text-xs italic">
                        {errors[field.name].message || `${field.label} is required!`}
                      </p>
                    )}
                  </div>
                )
              );
            }
          })}
        </div>
        {section.title === "" && isInClinic && (
          <>
            {fields.map((_, index) => (
              <div key={`address-${index}`}>{renderAddressFields(index)}</div>
            ))}
            <div className="flex justify-end mt-4">
              <Button
                type="button"
                className="h-12"
                onClick={() =>
                  append({
                    buildingName: "",
                    street: "",
                    city: "",
                    country: "",
                    postcode: "",
                    county: "",
                  })
                }
              >
                Add Another Address
              </Button>
            </div>
          </>
        )}
      </div>
    ));
  };

  const renderAddressFields = (index) => (
    <div
      className="address-section grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2"
      key={`address-${index}`}
      style={{ border: "2px solid #d1d4d8", margin: "20px -20px", padding: "0 20px 20px", borderRadius: "6px" }}
    >
      <h2 className="mt-6 mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200 sm:col-span-2">
        Address {index + 1}
      </h2>
      {inputFields[0].fields
        .filter((field) => field.name.startsWith("addresses"))
        .map((field, fieldIndex) => {
          const fieldName = `addresses[${index}].${field.name.split("addresses[0].")[1]}`;
          const labelContent = field.description ? (
            <FormToolTip description={field.description}>
              <span>{field.label}</span>
            </FormToolTip>
          ) : (
            <span>{field.label}</span>
          );

          if (field.type === "selectLocationCategory") {
            return (
              <div key={`address-field-${index}-${fieldIndex}`} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
                <LabelArea label={labelContent} />
                <Controller
                  name={fieldName}
                  control={control}
                  rules={{ required: `${field.label} is required!` }}
                  render={({ field }) => {
                    return <SelectLocationCategory field={field} label={field.label} options={locationOptions} />;
                  }}
                />
                {errors[fieldName] && <Error message={errors[fieldName]?.message || `${field.label} is required!`} />}
              </div>
            );
          } else {
            return (
              <div key={`address-field-${index}-${fieldIndex}`} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
                <LabelArea label={labelContent} />
                <InputArea
                  register={() =>
                    control.register(fieldName, {
                      required: field.required ? `${field.label} is required!` : false,
                    })
                  }
                  defaultValue={getValues(fieldName) || ""}
                  type={field.type}
                  placeholder={`Enter ${field.label.toLowerCase()}`}
                  name={fieldName}
                />
                {errors[fieldName] && <Error message={errors[fieldName]?.message || `${field.label} is required!`} />}
              </div>
            );
          }
        })}
      {index > 0 && (
        <div className="flex justify-end sm:col-span-2">
          <Button type="button" className="h-12" onClick={() => remove(index)}>
            Remove Address
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-full">
            <div className="w-full">
              <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Create Booking Entity</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                {renderCheckboxes()}
                {renderSections()}
                <Button type="submit" className="mt-4 h-12 w-full">
                  Create Booking Entity
                </Button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SignUpBookingEntity;
