import React from "react";
import Link from "@mui/material/Link";
import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as DOMPurify from "dompurify";

const FAQ = () => {
  // const [expanded, setExpanded] = useState(false);
  //
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  const faqData = [
    {
      topic: "Nurse",
      questions: [
        {
          question: "What can I access?",
          answer:
            "You will be able to add/view your availabilities. More features will be released in the future. For any enquiries/issues, please reach out to Sirkka Team.",
        },
        {
          question: "How do I add availabilities?",
          answer:
            "Go to Availabilities > Add Availability > Select Date and Time. For more information about adding availability, please refer to the availability section",
        },
      ],
    },
    {
      topic: "Admin",
      questions: [
        {
          question: "What is a booking user?",
          answer:
            "Booking users are users who can manage availabilities (can be viewing/editing or assigned with) on Sirkka Internal Portal. Typically, booking users are nurses, Sirkka staff and doctors, those who can access - staff.sirkkahealth.com. Each booking users must have a role: Clinician, Staff, Scheduler etc.",
        },
        {
          question: "What are booking roles?",
          answer:
            "Booking roles are user groups with specific set of permissions. Sirkka 'Admin' roles will have full permissions on the portal where as 'Clinician' roles can have permission to manage only their availabilities.",
        },
        {
          question: "How do I add availabilities?",
          answer:
            "Go to Availabilities > Add Availability > Select Location > Select doctor OR nurse (if needed) > Select Date and Time. Please note to select 'Home Visit' location when adding availabilities for nurses and 'Virtual' for doctors. <br/>",
        },
        {
          question: "How to upload documents?",
          answer:
            "Please note that in order to use this feature, the customer must have an account with us. To upload documents, got to Documents > Select Customer > Specify Document Type > Allow Customer Access. The documents uploaded will be in Sharepoint and be accessible by customers from their portal. Do not upload files with the same name, even for different customers. It must be unique i.e., document1.pdf and document2.pdf.",
        },
        {
          question: "How to add nurses?",
          answer:
            "Go to Booking users > Fill up the form with nurse details > Get their location's coordinates online (these are important to reduce cost for geolocation searches) > Choose 'Clinician' role > Create Account",
        },
      ],
    },
    // Add more topics and questions as needed
  ];

  const sanitizeData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });

  return (
    <div className="p-4">
      <div className="my-5">
        <h2 className="text-xl font-bold">FAQ</h2>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#" className="text-blue-500">
            Home
          </Link>
          <Typography color="textPrimary">Admin</Typography>
          <Typography color="textPrimary">Clinician</Typography>
          <Typography color="textPrimary">Availability</Typography>
          <Typography color="textPrimary">Appointment Booking</Typography>
        </Breadcrumbs>
      </div>
      {faqData.map((topicData) => (
        <div key={topicData.topic} className="mb-8">
          <h3 className="text-xl font-semibold">{topicData.topic}</h3>
          {topicData.questions.map((qa, index) => (
            <Accordion key={index} className="mb-4">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{qa.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography dangerouslySetInnerHTML={sanitizeData(qa.answer)}></Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
