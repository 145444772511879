import {
  DateField,
  EmailField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";

const PostalShow = ({ props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Delivery">
          <TextField source="id" label="Postal ID" emptyText="-" />
          <NumberField source="customer_id" label="Customer ID" emptyText="-" />
          <TextField source="first_name" emptyText="-" />
          <TextField source="last_name" emptyText="-" />
          <TextField source="service_id" emptyText="-" />
          <TextField source="service_type" emptyText="-" />
          <TextField source="address_line_1" emptyText="-" />
          <TextField source="address_line_2" emptyText="-" />
          <TextField source="city" emptyText="-" />
          <TextField source="postcode" emptyText="-" />
          <TextField source="country" emptyText="-" />
          <TextField source="status" emptyText="-" />
          <DateField source="created_at" label="Purchased at" emptyText="-" />
        </Tab>
        <Tab label="Customer">
          <ReferenceField label="Customer ID" source="customer_id" reference="customers" target="id">
            <TextField source="id" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Full Name" source="customer_id" reference="customers" target="id">
            <FunctionField
              label="Customer Name"
              render={(record) => `${record.first_name} ${record.last_name}`}
              emptyText="-"
            />
          </ReferenceField>
          <ReferenceField label="Gender" source="customer_id" reference="customers" target="id">
            <TextField source="gender" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Date Of Birth" source="customer_id" reference="customers" target="id">
            <DateField source="date_of_birth" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Email Address" source="customer_id" reference="customers" target="id">
            <EmailField label="Email" source="username" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Contact Number" source="customer_id" reference="customers" target="id">
            <TextField source="contact_number" emptyText="-" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PostalShow;
