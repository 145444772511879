// index.jsx

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BookingList from "./BookingList";
import BookingShow from "./BookingShow";
import BookingEdit from "./BookingEdit";
import BookingCreate from "./BookingCreate";

export default {
  list: BookingList,
  show: BookingShow,
  edit: BookingEdit,
  create: BookingCreate,
  icon: CalendarMonthIcon,
};
