import { List, Datagrid, TextField, DateField, BooleanField } from "react-admin";

const Transaction = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="gender" />
      <DateField source="date_of_birth" />
      <TextField source="username" />
      <TextField source="contact_number" />
      <TextField source="account_type" />
      <BooleanField source="verified" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

export default Transaction;
