import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Chip,
} from "@mui/material";
import { patchRequest, postRequest } from "../../helpers/requests";
import { notifyError, notifySuccess } from "../../utils/toast";

const ServiceApprovalModal = ({ open, handleClose, draft, draftId }) => {
  const [serviceData, setServiceData] = useState({});
  const [tags, setTags] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("user_metadata"));

  const parseArrayString = (str) => {
    try {
      return JSON.parse(str.replace(/'/g, '"'));
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (draft && draft.length > 0) {
      const currentServiceData = draft[0].service;
      setServiceData(currentServiceData);
      setServiceTypes(
        Array.isArray(currentServiceData.serviceType)
          ? currentServiceData.serviceType
          : currentServiceData.serviceType.split(", "),
      );
      setTags(
        Array.isArray(currentServiceData.tags) ? currentServiceData.tags : parseArrayString(currentServiceData.tags),
      );
    }
  }, [draft]);

  const handleApproval = async () => {
    try {
      const response = await postRequest("products", draft);
      if (response && response.status === "success") {
        const payload = {
          isApproved: true,
          approvedBy: currentUser ? currentUser.username : null,
        };
        patchRequest(`drafts/approve/${draftId}`, payload);
        notifySuccess("This submission has been approved");
        handleClose();
      } else {
        notifyError("Failed to approve this submission. Please try again");
      }
    } catch (error) {
      notifyError("Technical issue - Please try again in a few minutes!");
      console.error("Error updating service:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          width: {
            xs: "90%",
            sm: "90%",
            md: "90%",
            lg: "1200px",
            xl: "1200px",
          },
          maxWidth: "100%",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          Submitted Request (Non-editable)
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox checked={serviceData.serviceStatus || false} name="service_status" disabled={true} />}
              label="Service Active"
              helperText="Check if the service is currently active"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox checked={serviceData.enableDiscount || false} name="enable_discount" disabled={true} />
              }
              label="Discount Allowed"
              helperText="Check if discounts are allowed for this service"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox checked={serviceData.apptStackable || false} disabled={true} name="appt_stackable" />}
              label="Appointment Stackable"
              helperText="Check if appointments for this service can be stacked in the same timeslot"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox checked={serviceData.bookable || false} disabled={true} name="bookable" />}
              label="Bookable"
              helperText="Check if this service can be booked"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Name"
              name="service_name"
              value={serviceData.serviceName || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="The name of the service"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Service Type</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
              {serviceTypes.map((type) => (
                <Chip key={type} label={type} disabled sx={{ mb: 1 }} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={serviceData.description || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={4}
              helperText="Shown to users on the list page"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Price"
              name="service_price"
              value={serviceData.servicePrice || ""}
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                readOnly: true,
              }}
              helperText="The price in pounds and pence, for example £129.99"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Appointment Duration"
              name="appt_duration"
              value={serviceData.apptDuration || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="Enter the duration of the appointment in minutes"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Post Appointment Buffer"
              name="post_appt_buffer"
              value={serviceData.postApptBuffer || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="Enter the buffer time after the appointment in minutes"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Code"
              name="service_code"
              value={serviceData.serviceCode || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="This must be a unique code in camelCase of the service name (no spaces)"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Icon"
              name="service_icon"
              value={serviceData.serviceIcon || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="The file name of an existing icon - use 'health_screening' for a generic icon"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Bullets"
              name="bullets"
              value={serviceData.bullets || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={4}
              helperText="Shown after the description on the service pop-up. New line = new paragraph."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="First Description"
              name="first_description"
              value={serviceData.firstDescription || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={4}
              helperText="Shown on the service pop-up when a user selects the service. Press enter to start a new paragraph."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description Continued"
              name="description_continued"
              value={serviceData.descriptionContinued || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={4}
              helperText="Optionally show a second paragraph after the bullets on the service pop-up. Press enter to start a new paragraph."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Display Order"
              name="display_order"
              value={serviceData.displayOrder || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="Override with a higher number than other entities, or leave empty for default order"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email Template"
              name="email_template"
              value={serviceData.emailTemplate || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="Enter the email template (Ask devs if unsure)"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Questionnaire Link"
              name="questionnaire_link"
              value={serviceData.questionnaireLink || ""}
              InputProps={{
                readOnly: true,
              }}
              helperText="Optionally enter a link if this service does not go to checkout"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Tags"
              helperText="Press Enter after each tag you input. For example, Wellbeing (enter) International Treatments (enter)."
              InputProps={{
                readOnly: true,
              }}
            />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
              {tags.map((tag) => (
                <Chip key={tag} label={tag} disabled />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Disclaimers"
              name="disclaimers"
              value={serviceData.disclaimers || ""}
              multiline
              rows={4}
              helperText="Disclaimers are shown in a smaller font at the end of the service pop-up. Press enter to start a new paragraph."
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            {serviceData.createdAt && serviceData.updatedAt && (
              <>
                <Typography variant="caption" display="block">
                  Created At: {new Date(serviceData.createdAt).toLocaleString()}
                </Typography>
                <Typography variant="caption" display="block">
                  Updated At: {new Date(serviceData.updatedAt).toLocaleString()}
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Reject
            </Button>
            <Button variant="contained" color="primary" onClick={handleApproval}>
              Approve
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ServiceApprovalModal;
