import { Datagrid, DateField, List, NumberField, ShowButton, SimpleList, TextField } from "react-admin";
import { useMediaQuery } from "@mui/material";

const PostalList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });
  return (
    <List>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.first_name} ${record.last_name}`}
          secondaryText={(record) => record.service_id}
          tertiaryText={(record) => record.created_at}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <TextField source="id" label="Postal ID" emptyText="-" />
          <NumberField source="customer_id" label="Customer ID" emptyText="-" />
          <TextField source="first_name" emptyText="-" />
          <TextField source="last_name" emptyText="-" />
          <TextField source="service_id" emptyText="-" />
          <TextField source="service_type" emptyText="-" />
          <TextField source="address_line_1" emptyText="-" />
          <TextField source="address_line_2" emptyText="-" />
          <TextField source="city" emptyText="-" />
          <TextField source="postcode" emptyText="-" />
          <TextField source="country" emptyText="-" />
          <TextField source="status" emptyText="-" />
          <DateField source="created_at" label="Purchased at" emptyText="-" />
          <ShowButton />
        </Datagrid>
      )}
    </List>
  );
};

export default PostalList;
