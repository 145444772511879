import React from "react";
import { Select } from "@windmill/react-ui";

const SelectGender = ({ setGender, register, name, label }) => {
  return (
    <>
      <Select
        onChange={(e) => setGender(e.target.value)}
        className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
        name={name}
        {...register(`${name}`, {
          required: `${label} is required!`,
        })}
      >
        <option value="" defaultValue hidden>
          Select gender
        </option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </Select>
    </>
  );
};

export default SelectGender;
