// BookingShow.jsx

import {
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  useRecordContext,
} from "react-admin";
import AddCommentButton from "./AddCommentButton";
import { useState } from "react";

const ActionButtons = () => (
  <Toolbar>
    <ListButton label="Bookings" />
    <ShowButton />
  </Toolbar>
);

const NewCommentBox = () => <textarea></textarea>;

const NewCommentArea = () => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const booking = useRecordContext();
  if (!booking) return null;

  return <>{showCommentBox ? <NewCommentBox /> : <AddCommentButton bookingId={booking?.id} />};</>;
};

const BookingShow = ({ props }) => {
  return (
    <>
      <ActionButtons />
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label="Booking Summary">
            <TextField source="id" label="Appointment ID" emptyText="-" />
            <TextField source="service_name" emptyText="-" />
            <TextField source="booking_name" emptyText="-" />
            <TextField source="appt_location" emptyText="-" />
            <DateField source="appt_start_dt" showTime options={{ timeZone: "UTC" }} emptyText="-" />
            <DateField source="appt_end_dt" showTime options={{ timeZone: "UTC" }} emptyText="-" />
            <TextField source="other" label="Notes" emptyText="-" />
            <TextField source="booking_status" emptyText="-" />
          </Tab>
          <Tab label="Customer Details">
            <ReferenceField label="Customer ID" source="customer_id" reference="customers" target="id">
              <TextField source="id" emptyText="-" />
            </ReferenceField>
            <ReferenceField label="Full Name" source="customer_id" reference="customers" target="id">
              <FunctionField
                label="Customer Name"
                render={(record) => `${record.first_name} ${record.last_name}`}
                emptyText="-"
              />
            </ReferenceField>
            <ReferenceField label="Gender" source="customer_id" reference="customers" target="id">
              <TextField source="gender" emptyText="-" />
            </ReferenceField>
            <ReferenceField label="Date Of Birth" source="customer_id" reference="customers" target="id">
              <DateField source="date_of_birth" emptyText="-" />
            </ReferenceField>
            <ReferenceField label="Email Address" source="customer_id" reference="customers" target="id">
              <EmailField label="Email" source="username" emptyText="-" />
            </ReferenceField>
            <ReferenceField label="Contact Number" source="customer_id" reference="customers" target="id">
              <TextField source="contact_number" emptyText="-" />
            </ReferenceField>
          </Tab>
          <Tab label="Comments" path="comments">
            <ReferenceManyField
              addLabel={false}
              reference="comments"
              target="booking_id"
              sort={{ field: "created_at", order: "DESC" }}
            >
              <Datagrid>
                <DateField source="comment_date" emptyText="-" />
                <RichTextField source="comment_content" emptyText="-" />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
            <NewCommentArea />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default BookingShow;
