function convertData(data, type) {
  const convertedData = [];

  if (type === "Availability") {
    data.forEach((slot) => {
      const { startDate, endDate, startTime, endTime, availabilityId } = slot;

      const dateStart = new Date(`${startDate}T${startTime}`);
      const dateEnd = new Date(`${endDate}T${endTime}`);

      convertedData.push({
        label: "Available",
        dateStart,
        dateEnd,
        id: availabilityId,
        class: "Bookable",
        disableDrag: true,
        disableResize: true,
        backgroundColor: "#099949",
      });
    });
  } else {
    // Generate appointment data
    data.forEach((slot) => {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
        appointmentId,
        serviceName,
        bookingName,
        bookingStatus,
        bookingEmail,
      } = slot;

      const dateStart = new Date(`${startDate}T${startTime}`);
      const dateEnd = new Date(`${endDate}T${endTime}`);

      convertedData.push({
        label: bookingStatus === "booked" ? `${serviceName} - ${bookingName}` : `${bookingStatus} - ${bookingName}`,
        dateStart,
        dateEnd,
        class: bookingStatus,
        description: bookingEmail,
        id: appointmentId,
        disableDrag: true,
        disableResize: true,
        backgroundColor: bookingStatus === "booked" ? "#D70040" : "#808080",
      });
    });
  }

  return convertedData;
}

function getPastThreeWeekdays(weekday) {
  let weekdays = [];

  for (let i = 0; i < 3; i++) {
    weekdays.push((weekday - i + 7) % 7);
  }

  return weekdays;
}

export { convertData, getPastThreeWeekdays };
