function convertData(data) {
  const convertedData = [];

  for (const date in data) {
    const { businessOpen, availableTimeSlots } = data[date];

    if (businessOpen) {
      for (let i = 0; i < availableTimeSlots.length - 1; i++) {
        const dateStart = new Date(`${date} ${availableTimeSlots[i]}`);
        const dateEnd = new Date(`${date} ${availableTimeSlots[i + 1]}`);

        convertedData.push({
          label: "Available",
          dateStart,
          dateEnd,
          backgroundColor: "#33B679",
          disableDrag: true,
          disableResize: true,
        });
      }
    }
  }

  return convertedData;
}

export { convertData };
