import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";
import HistoryModal from "../HistoryModal";
import { getRequestWithHeaders } from "../../helpers/requests";

dayjs.extend(customParseFormat);

const BookingEntityAppointments = ({ entity }) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [service, setService] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [clinician, setClinician] = useState(null);
  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [clinicianLoading, setClinicianLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [filters, setFilters] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedBookingName, setSelectedBookingName] = useState("");

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      const queryParams = {
        entity: entity.booking_entity_id,
        limit: rowsPerPage,
        page: page + 1,
        sort: sortBy,
        order: order,
        service: filters.service,
        clinician: filters.clinician,
        location: filters.location,
        appointmentDate: filters.appointmentDate ? dayjs(filters.appointmentDate).format("YYYY-MM-DD") : null,
      };

      const { data, headers } = await getRequestWithHeaders(
        `appointments/entity/${entity.booking_entity_id}`,
        queryParams,
      );

      if (headers.get("Content-Range")) {
        const contentRange = headers.get("Content-Range");
        const [range, total] = contentRange.split("/");
        setTotalRows(parseInt(total, 10));
      } else {
        console.error("Content-Range header is missing");
      }

      setAppointments(data.appointments);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entity) {
      fetchAppointments();
    }
  }, [page, rowsPerPage, order, sortBy, filters, entity]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setFilters({
      service,
      clinician,
      location,
      appointmentDate,
    });
    setPage(0);
  };

  const handleResetFilters = () => {
    setService(null);
    setClinician(null);
    setLocation(null);
    setAppointmentDate(null);
    setFilters({});
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  const handleCustomerClick = (customerId, bookingName) => {
    setSelectedCustomerId(customerId);
    setSelectedBookingName(bookingName);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCustomerId(null);
    setSelectedBookingName("");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Container maxWidth="false" sx={{ marginTop: "18px" }}>
        <Box sx={{ marginBottom: 2, display: "flex", gap: 2 }}>
          <Autocomplete
            options={serviceOptions}
            getOptionLabel={(option) => option.name}
            loading={serviceLoading}
            onChange={(event, newValue) => setService(newValue)}
            sx={{ width: 200 }}
            value={service}
            renderInput={(params) => <TextField {...params} label="Service" />}
          />
          <Autocomplete
            options={clinicianOptions}
            getOptionLabel={(option) => option.name}
            loading={clinicianLoading}
            onChange={(event, newValue) => setClinician(newValue)}
            sx={{ width: 200 }}
            value={clinician}
            renderInput={(params) => <TextField {...params} label="Clinician" />}
          />
          <Autocomplete
            options={locations}
            getOptionLabel={(option) => option}
            loading={locationLoading}
            onChange={(event, newValue) => setLocation(newValue)}
            sx={{ width: 200 }}
            value={location}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
          <DatePicker
            label="Appointment Date"
            value={appointmentDate}
            onChange={(newValue) => setAppointmentDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button variant="contained" onClick={handleSearch} sx={{ margin: "8px 0 4px" }}>
            Apply
          </Button>
          <Button variant="text" onClick={handleResetFilters} sx={{ margin: "8px 0 4px" }}>
            Reset
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={sortBy === "appointment_id" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "appointment_id"}
                    direction={sortBy === "appointment_id" ? order : "asc"}
                    onClick={createSortHandler("appointment_id")}
                  >
                    Appointment ID
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "service_name" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "service_name"}
                    direction={sortBy === "service_name" ? order : "asc"}
                    onClick={createSortHandler("service_name")}
                  >
                    Service Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "booking_name" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "booking_name"}
                    direction={sortBy === "booking_name" ? order : "asc"}
                    onClick={createSortHandler("booking_name")}
                  >
                    Customer
                  </TableSortLabel>
                </TableCell>

                <TableCell sortDirection={sortBy === "start_date" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "start_date"}
                    direction={sortBy === "start_date" ? order : "asc"}
                    onClick={createSortHandler("start_date")}
                  >
                    Appointment Date
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "start_time" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "start_time"}
                    direction={sortBy === "start_time" ? order : "asc"}
                    onClick={createSortHandler("start_time")}
                  >
                    Appointment Time
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "booking_status" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "booking_status"}
                    direction={sortBy === "booking_status" ? order : "asc"}
                    onClick={createSortHandler("booking_status")}
                  >
                    Booking Status
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (
                appointments.map((appointment) => (
                  <TableRow key={appointment.appointmentId}>
                    <TableCell>{appointment.appointmentId || "-"}</TableCell>
                    <TableCell>{appointment.serviceName || "-"}</TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="primary"
                        onClick={
                          () => handleCustomerClick(appointment.customerId, appointment.bookingName) // Pass customerId and bookingName
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {appointment.bookingName || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {appointment.startDate ? new Date(appointment.startDate).toLocaleDateString("en-GB") : "-"}
                    </TableCell>
                    <TableCell>{appointment.startTime || "-"}</TableCell>
                    <TableCell>{appointment.bookingStatus || "-"}</TableCell>
                    {/* <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => console.log("View appointment details", appointment.appointmentId)}
                      >
                        View
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
      <HistoryModal
        open={modalOpen}
        onClose={handleCloseModal}
        customer={{ id: selectedCustomerId, name: selectedBookingName }}
        bookingEntityId={entity.booking_entity_id}
      />
    </LocalizationProvider>
  );
};

export default BookingEntityAppointments;
