import React, { useState } from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BookingEntityEditModal from "../BookingEntityEditModal";

export default function OverviewDetail({ entity, refreshEntity }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const [isTagsExpanded, setTagsExpanded] = useState(false);
  const [isNotesExpanded, setNotesExpanded] = useState(false);

  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  const toggleTags = () => {
    setTagsExpanded(!isTagsExpanded);
  };

  const toggleNotes = () => {
    setNotesExpanded(!isNotesExpanded);
  };

  const getText = (text, isExpanded) => {
    if (text.length > 260 && !isExpanded) {
      return text.substring(0, 260) + "...";
    }
    return text;
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {/* Image */}
        <Grid item xs={12} lg={3} md={4} sm={6}>
          {entity.image_url ? (
            <img
              src={`https://sirkkahealth.com${entity.image_url}`}
              alt={entity.display_name}
              style={{ width: "100%", borderRadius: 8 }}
            />
          ) : (
            <div
              style={{
                height: "100%",
                background: "lightgray",
                borderRadius: "6px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              No image set
            </div>
          )}
        </Grid>
        {/* Top body */}
        <Grid item xs={12} lg={9} md={8} sm={6}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {entity.display_name}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              size="small"
              onClick={handleOpenEditModal}
              sx={{ ml: "12px" }}
            >
              Edit
            </Button>
          </Box>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6} sm={6} lg={4} sx={{ maxWidth: 150 }}>
              <Typography variant="body1" color="textSecondary">
                Booking Entity ID
              </Typography>
              <Typography variant="body1">{entity.booking_entity_id}</Typography>
            </Grid>

            <Grid item xs={6} sm={6} lg={4}>
              <Typography variant="body1" color="textSecondary">
                Is Published
              </Typography>
              <Typography variant="body1">{entity.is_published ? "True" : "False"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={4}>
              <Typography variant="body1" color="textSecondary">
                Rank
              </Typography>
              <Typography variant="body1">{entity.ranking || "-"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={4}>
              <Typography variant="body1" color="textSecondary">
                Type
              </Typography>
              <Typography variant="body1">{entity.type || "-"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={4}>
              <Typography variant="body1" color="textSecondary">
                Latitude
              </Typography>
              <Typography variant="body1">{entity.latitude || "-"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={4}>
              <Typography variant="body1" color="textSecondary">
                Longitude
              </Typography>
              <Typography variant="body1">{entity.longitude || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} lg={4}>
          <Typography variant="body1" color="textSecondary">
            Description
          </Typography>
          <Typography variant="body1">
            {getText(entity.description || "-", isDescriptionExpanded)}{" "}
            {entity.description && entity.description.length > 260 && (
              <span onClick={toggleDescription} style={{ cursor: "pointer", textDecoration: "underline" }}>
                {isDescriptionExpanded ? "Read less" : "Read more"}
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={4}>
          <Typography variant="body1" color="textSecondary">
            Tags
          </Typography>
          <Typography variant="body1">
            {getText(entity.tags || "-", isTagsExpanded)}{" "}
            {entity.tags && entity.tags.length > 260 && (
              <span onClick={toggleTags} style={{ cursor: "pointer", textDecoration: "underline" }}>
                {isTagsExpanded ? "Read less" : "Read more"}
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={4}>
          <Typography variant="body1" color="textSecondary">
            Notes
          </Typography>
          <Typography variant="body1">
            {getText(entity.notes || "-", isNotesExpanded)}{" "}
            {entity.notes && entity.notes.length > 260 && (
              <span onClick={toggleNotes} style={{ cursor: "pointer", textDecoration: "underline" }}>
                {isNotesExpanded ? "Read less" : "Read more"}
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>
      <BookingEntityEditModal
        open={isEditModalOpen}
        handleClose={handleCloseEditModal}
        entity={entity}
        refreshEntity={refreshEntity}
      />
    </Box>
  );
}
