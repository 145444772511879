import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationEditModal from "../LocationEditModal";
import LocationCreateModal from "../LocationCreateModal";
import { getRequestWithHeaders, deleteRequest } from "../../helpers/requests";
import { notifyError, notifySuccess } from "../../utils/toast";

const LocationsList = ({ entity, service }) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({ locationId: null });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const fetchLocations = async (bookingEntityId, page, rowsPerPage) => {
    try {
      setLoading(true);

      const response = await getRequestWithHeaders(`locations`, {
        entity: bookingEntityId,
        limit: rowsPerPage,
        page: page + 1,
        ...(service && { service: service.service_id }),
      });
      const data = response.data;
      const contentRange = response.headers.get("Content-Range");

      if (contentRange) {
        const [range, total] = contentRange.split("/");
        setTotalRows(parseInt(total, 10));
      } else {
        console.error("Content-Range header is missing");
      }

      setLocations(data.locations);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entity && entity.booking_entity_id) {
      fetchLocations(entity.booking_entity_id, page, rowsPerPage);
    }
  }, [entity, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (location) => {
    setCurrentLocation(location);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setCurrentLocation(null);
    fetchLocations(entity.booking_entity_id, page, rowsPerPage);
  };

  const handleDelete = async (locationId) => {
    try {
      const response = await deleteRequest(`staff/entities/delete/${entity.booking_entity_id}`, {
        location: locationId,
        service: service.service_id,
      });
      notifySuccess("Successfully deleted");
      fetchLocations(entity.booking_entity_id, page, rowsPerPage);
    } catch (error) {
      console.error("Error deleting location:", error);
      notifyError("Failed to delete the location");
    }
  };

  const handleNewLocation = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    fetchLocations(entity.booking_entity_id, page, rowsPerPage);
  };

  const handleOpenDeleteDialog = (locationId) => {
    setDeleteInfo({ locationId });
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteInfo({ locationId: null });
  };

  const confirmDelete = () => {
    handleDelete(deleteInfo.locationId);
    handleCloseDeleteDialog();
  };

  return (
    <Container maxWidth={false}>
      {service ? (
        <>
          <Typography variant="h5" style={{ fontWeight: "600", margin: "20px 0" }}>
            Associated Locations
          </Typography>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="h5" style={{ fontWeight: "600", margin: "20px 0" }}>
              Locations
            </Typography>
            <Button variant="contained" onClick={handleNewLocation} startIcon={<AddIcon />} size="small" sx={{ ml: 2 }}>
              Add
            </Button>
          </Box>
        </>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Building Name</TableCell>
              <TableCell>Street</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Postcode</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Latitude</TableCell>
              <TableCell>Longitude</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              locations.map((location) => (
                <TableRow key={location.location_id}>
                  <TableCell>{location.location_id}</TableCell>
                  <TableCell>{location.building_name || "-"}</TableCell>
                  <TableCell>{location.street || "-"}</TableCell>
                  <TableCell>{location.city || "-"}</TableCell>
                  <TableCell>{location.postcode || "-"}</TableCell>
                  <TableCell>{location.country || "-"}</TableCell>
                  <TableCell>{location.latitude ? location.latitude : "-"}</TableCell>
                  <TableCell>{location.longitude ? location.longitude : "-"}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {service && (
                      <Button
                        variant="text"
                        onClick={() => handleOpenDeleteDialog(location.location_id)}
                        startIcon={<DeleteIcon style={{ marginRight: -4 }} />}
                        style={{ marginRight: 8 }}
                      >
                        Remove
                      </Button>
                    )}
                    {!service && (
                      <Button variant="contained" onClick={() => handleEdit(location)}>
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{"Remove location"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to remove this location from {service ? service.service_name : "this service"}. Do you wish to
            continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary" style={{ color: "red" }}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {currentLocation && (
        <LocationEditModal open={openEditModal} handleClose={handleCloseEditModal} location={currentLocation} />
      )}

      <LocationCreateModal open={openCreateModal} handleClose={handleCloseCreateModal} entity={entity} />
    </Container>
  );
};

export default LocationsList;
