import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LabelArea from "../../../components/form/LabelArea";
import InputArea from "../../../components/form/InputArea";
import Error from "../../../components/form/Error";
import { Button } from "@windmill/react-ui";
import SelectLocationCategory from "../../../components/form/SelectLocationCategory";
import SelectServiceType from "../../../components/form/SelectServiceType";
import { getRequest, postRequest } from "../../../helpers/requests";
import { notifyError, notifySuccess } from "../../../utils/toast";
import FormToolTip from "../../../components/form/FormToolTip";

const CreateService = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      service: {
        serviceName: "",
        serviceType: "",
        description: "",
        apptDuration: 0,
        apptStackable: true,
        servicePrice: 0,
        serviceStatus: false,
        discountEnabled: false,
        private: true,
        serviceCode: "",
        serviceIcon: null,
        bullets: "",
        firstDescription: "",
        descriptionContinued: "",
        displayOrder: null,
        emailTemplate: "",
        questionnaireLink: null,
        tags: null,
        disclaimers: null,
        bookable: false,
      },
      locations: [],
      entity: {
        entityId: "",
        entityName: "",
      },
    },
  });

  const [bookingEntities, setBookingEntities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedBookingEntity, setSelectedBookingEntity] = useState(null);

  useEffect(() => {
    const getEntityList = async () => {
      const response = await getRequest("entities/list");
      console.log("entity", response);
      if (response) {
        setBookingEntities(response.entities);
      } else {
        const errorMessage = response.message || "Failed to create service";
        notifyError(errorMessage);
      }
    };
    getEntityList();
  }, []);

  useEffect(() => {
    if (selectedBookingEntity) {
      const getBookingEntityLocation = async () => {
        const bookingEntityId = selectedBookingEntity.booking_entity_id;
        const response = await getRequest(`locations/entity/${bookingEntityId}`);
        if (response) {
          setLocations(response.data);
        }
      };
      getBookingEntityLocation();
    }
  }, [selectedBookingEntity]);

  const onSubmit = async (data) => {
    const payload = [{ ...data }];
    const response = await postRequest("products", payload);
    if (response.status === "success") {
      notifySuccess("Service created successfully");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      const errorMessage = response.message || "Failed to create service";
      notifyError(errorMessage);
    }
  };

  const inputFields = [
    {
      label: "Booking Entity *",
      name: "entity.entityId",
      description: "Select a booking entity",
      type: "text",
      fullWidth: false,
      required: true,
    },
    {
      label: "Location Address *",
      name: "location.locationAddress",
      description:
        "Address of the location for the service. Only locations registered to booking entity will be listed here",
      type: "text",
      fullWidth: false,
      required: true,
    },
    {
      label: "Service Name *",
      name: "service.serviceName",
      description: "Name of the service, shown to the user as listing card title",
      type: "text",
      fullWidth: false,
      required: true,
    },
    {
      label: "Service Code *",
      name: "service.serviceCode",
      description: "Unique code for the service. Should be camelcase i.e. (standardHealthScreening)",
      type: "text",
      fullWidth: false,
      required: true,
    },
    {
      label: "Service Price (in pence) *",
      name: "service.servicePrice",
      description: "Price of the service in pence. Example, £98.00 is 9800 in pence",
      type: "number",
      fullWidth: false,
      required: true,
    },
    {
      label: "Appointment Duration *",
      name: "service.apptDuration",
      description: "Duration of the appointment in minutes",
      type: "number",
      fullWidth: false,
      required: true,
    },
    {
      label: "Service Type *",
      name: "service.serviceType",
      description: "Type of the service - Clinic, Consultation, Postal",
      type: "selectServiceType",
      fullWidth: false,
      required: true,
    },
    {
      label: "Is appointment stackable?",
      name: "service.apptStackable",
      description: "Whether the appointment can be combined with other services",
      type: "checkbox",
      fullWidth: false,
      required: false,
    },
    {
      label: "Is the service private?",
      name: "service.private",
      description: "Private services are for businesses, not available for public.",
      type: "checkbox",
      fullWidth: false,
      required: false,
    },
    {
      label: "Is the service bookable?",
      name: "service.bookable",
      description: "Whether the service is bookable or not. Services will still be displayed",
      type: "checkbox",
      fullWidth: false,
      required: false,
    },
    {
      label: "Should this service be activated/displayed?",
      name: "service.serviceStatus",
      description:
        "Status of the service. Only services that are activated will be displayed except for privatised services",
      type: "checkbox",
      fullWidth: false,
      required: false,
    },
    {
      label: "Enable discount?",
      name: "service.discountEnabled",
      description: "Whether the service has a discount",
      type: "checkbox",
      fullWidth: false,
      required: false,
    },
    {
      label: "Preview Description *",
      name: "service.description",
      description: "The description shown before the modal is opened",
      type: "text",
      fullWidth: true,
      required: true,
    },
    {
      label: "First description *",
      name: "service.firstDescription",
      description: "The Description of the service shown at the top of the modal",
      type: "textarea",
      fullWidth: true,
      required: true,
    },
    {
      label: "Bullets",
      name: "service.bullets",
      description: "Bullet point list shown after the first description on the modal",
      type: "textarea",
      fullWidth: true,
      required: false,
    },
    {
      label: "Description Continued",
      name: "service.descriptionContinued",
      description: "Additional description shown after the bullet list on the modal",
      type: "textarea",
      fullWidth: true,
      required: false,
    },
    {
      label: "Disclaimers",
      name: "service.disclaimers",
      description: "Disclaimers for the service, shown in a smaller font last on the modal",
      type: "textarea",
      fullWidth: true,
      required: false,
    },
    {
      label: "Email Template*",
      name: "service.emailTemplate",
      description: "Email template for the service - please check with IT team for templates",
      type: "text",
      fullWidth: false,
      required: false,
    },
    {
      label: "Questionnaire Link",
      name: "service.questionnaireLink",
      description: "Link to the questionnaire for the service",
      type: "text",
      fullWidth: false,
      required: false,
    },
    {
      label: "Tags",
      name: "service.tags",
      description:
        "Tags for the service. To be shown on a page, add 'Wellbeing', 'International Treatments', 'Group Testing', 'Imaging' in a comma separated list",
      type: "text",
      fullWidth: false,
      required: false,
    },
    {
      label: "Service Icon Path*",
      name: "service.serviceIcon",
      description: "Icon for the service - please check with IT team",
      type: "text",
      fullWidth: false,
      required: false,
    },
  ];

  const handleBookingEntityChange = (event) => {
    const selectedEntityId = event.target.value;
    setSelectedBookingEntity(bookingEntities.find((entity) => entity.booking_entity_id === parseInt(selectedEntityId)));
    setValue("entity.entityId", selectedEntityId);
    setValue(
      "entity.entityName",
      bookingEntities.find((entity) => entity.booking_entity_id === parseInt(selectedEntityId)).display_name,
    );
  };

  const handleLocationChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
    const selectedLocations = locations.filter((loc) => selectedOptions.includes(loc.location));

    const newLocationValues = selectedLocations.map((loc) => ({
      locationId: loc.location_id,
      locationAddress: loc.location,
      locationCategoryId: loc.location_category_id,
    }));

    setValue("locations", newLocationValues);
  };

  const renderFields = () => {
    return inputFields.map((field, index) => {
      const fieldId = `field-${index}`;
      const labelContent = field.description ? (
        <FormToolTip description={field.description}>
          <span>{field.label}</span>
        </FormToolTip>
      ) : (
        <span>{field.label}</span>
      );

      if (field.type === "selectServiceType") {
        return (
          <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
            <LabelArea label={labelContent} />
            <Controller
              name={field.name}
              control={control}
              rules={{ required: `${field.label} is required!` }}
              render={({ field }) => {
                return <SelectServiceType field={field} label={field.label} />;
              }}
            />
            {errors[field.name] && <Error message={errors[field.name]?.message || `${field.label} is required!`} />}
          </div>
        );
      } else if (field.type === "selectLocationCategory") {
        const locationOptions = locations.map((loc) => ({
          value: loc.location,
          label: loc.location,
        }));
        return (
          <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
            <LabelArea label={labelContent} />
            <Controller
              name={field.name}
              control={control}
              rules={{ required: `${field.label} is required!` }}
              render={({ field }) => {
                return <SelectLocationCategory field={field} label={field.label} options={locationOptions} />;
              }}
            />
            {errors[field.name] && <Error message={errors[field.name]?.message || `${field.label} is required!`} />}
          </div>
        );
      } else if (field.name === "entity.entityId") {
        return (
          <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
            <LabelArea label={labelContent} />
            <select
              name={field.name}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              onChange={handleBookingEntityChange}
            >
              <option value="">Select Booking Entity</option>
              {bookingEntities.map((entity) => (
                <option key={entity.booking_entity_id} value={entity.booking_entity_id}>
                  {entity.display_name}
                </option>
              ))}
            </select>
            {errors[field.name] && <Error message={errors[field.name]?.message || `${field.label} is required!`} />}
          </div>
        );
      } else if (field.name === "location.locationAddress") {
        return (
          <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
            <LabelArea label={labelContent} />
            <select
              name={field.name}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              onChange={handleLocationChange}
              multiple
              disabled={!selectedBookingEntity}
            >
              {locations
                .filter((loc) => loc.booking_entity_id === selectedBookingEntity?.booking_entity_id)
                .map((loc) => (
                  <option key={loc.location} value={loc.location}>
                    {loc.location}
                  </option>
                ))}
            </select>
            {errors[field.name] && <Error message={errors[field.name]?.message || `${field.label} is required!`} />}
          </div>
        );
      } else if (field.type === "checkbox") {
        return (
          <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
            <LabelArea label={labelContent} />
            <Controller
              name={field.name}
              control={control}
              rules={{ required: field.required ? `${field.label} is required!` : false }}
              render={({ field: { onChange, onBlur, name, ref } }) => (
                <input
                  type="checkbox"
                  name={name}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.checked)}
                  className="mt-1 block rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              )}
            />
            {errors[field.name] && <Error message={errors[field.name]?.message || `${field.label} is required!`} />}
          </div>
        );
      } else {
        return (
          <div key={fieldId} className={`${field.fullWidth ? "sm:col-span-2" : ""}`}>
            <LabelArea label={labelContent} />
            <InputArea
              register={() =>
                control.register(field.name, {
                  required: field.required ? `${field.label} is required!` : false,
                })
              }
              defaultValue=""
              type={field.type}
              placeholder={`Enter ${field.label.toLowerCase()}`}
              name={field.name}
            />
            {errors[field.name] && <Error message={errors[field.name]?.message || `${field.label} is required!`} />}
          </div>
        );
      }
    });
  };

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-full">
            <div className="w-full">
              <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Create Service</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">{renderFields()}</div>
                <Button type="submit" className="mt-4 h-12 w-full">
                  Create Service
                </Button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CreateService;
