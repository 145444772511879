import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ServiceEditModal from "../ServiceEditModal";
import ServiceCreateModal from "../ServiceCreateModal";
import { getRequestWithHeaders } from "../../helpers/requests";

const ServicesList = ({ entity }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const fetchServices = async (bookingEntityId, page, rowsPerPage) => {
    try {
      setLoading(true);

      const response = await getRequestWithHeaders(`products/entity/${bookingEntityId}`, {
        limit: rowsPerPage,
        page: page + 1,
      });
      const data = response.data;
      const contentRange = response.headers.get("Content-Range");

      if (contentRange) {
        const [range, total] = contentRange.split("/");
        setTotalRows(parseInt(total, 10));
      } else {
        console.error("Content-Range header is missing");
      }

      setServices(data.services);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entity && entity.booking_entity_id) {
      fetchServices(entity.booking_entity_id, page, rowsPerPage);
    }
  }, [entity, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (serviceId) => {
    const service = services.find((s) => s.service_id === serviceId);
    setSelectedService(service);
    setEditModalOpen(true);
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
    setSelectedService(null);
    fetchServices(entity.booking_entity_id, page, rowsPerPage);
  };

  const handleCreateService = () => {
    setCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
    fetchServices(entity.booking_entity_id, page, rowsPerPage);
  };

  return (
    <Container maxWidth={false}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h5" style={{ fontWeight: "600", margin: "20px 0" }}>
          Services
        </Typography>
        <Button variant="contained" onClick={handleCreateService} startIcon={<AddIcon />} size="small" sx={{ ml: 2 }}>
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Service Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Service Status</TableCell>
              <TableCell>Enable Discount</TableCell>
              <TableCell>Appt Stackable</TableCell>
              <TableCell>Service Type</TableCell>
              <TableCell>Bookable</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              services.map((service) => (
                <TableRow key={service.service_id}>
                  <TableCell>{service.service_id}</TableCell>
                  <TableCell>{service.service_name}</TableCell>
                  <TableCell>£{(service.service_price / 100).toFixed(2)}</TableCell>
                  <TableCell>{service.service_status ? "Active" : "Inactive"}</TableCell>
                  <TableCell>{service.enable_discount ? "Yes" : "No"}</TableCell>
                  <TableCell>{service.appt_stackable ? "Yes" : "No"}</TableCell>
                  <TableCell>{service.service_type}</TableCell>
                  <TableCell>{service.bookable ? "Yes" : "No"}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <Button variant="contained" onClick={() => handleEdit(service.service_id)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {selectedService && (
        <ServiceEditModal
          open={editModalOpen}
          handleClose={handleModalClose}
          service={selectedService}
          entity={entity}
        />
      )}
      <ServiceCreateModal
        open={createModalOpen}
        handleClose={handleCreateModalClose}
        bookingEntityId={entity.booking_entity_id}
      />
    </Container>
  );
};

export default ServicesList;
