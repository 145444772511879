import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import { getRequest, postRequest } from "../../helpers/requests";

const AssociatedLocationsSelector = ({ serviceId, entityId }) => {
  const [allLocations, setAllLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const allLocs = await getRequest(`locations?entity=${entityId}&limit=100`);
        const serviceLocs = await getRequest(`locations?entity=${entityId}&service=${serviceId}&limit=100`);
        setAllLocations(allLocs.locations || []);
        setSelectedLocationIds(serviceLocs.locations?.map((loc) => loc.location_id) || []);
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };

    fetchLocations();
  }, [serviceId, entityId]);

  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;

    const newSelectedLocationIds = typeof value === "string" ? value.split(",") : value;

    // Sending the added locations to server
    const newLocationIds = newSelectedLocationIds.filter((x) => !selectedLocationIds.includes(x));
    newLocationIds.forEach(async (locationId) => {
      try {
        await postRequest("/products/relation", {
          serviceId: serviceId,
          locationId: locationId,
          bookingEntityId: entityId,
        });
      } catch (error) {
        console.error("Failed to create location relation:", error);
      }
    });

    setSelectedLocationIds(newSelectedLocationIds);
  };

  const renderValue = (selected) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((locationId) => {
        const location = allLocations.find((loc) => loc.location_id === locationId);
        return (
          <Chip
            key={locationId}
            label={location ? (location.street ? `${location.street}, ${location.city}` : location.city) : locationId}
          />
        );
      })}
    </Box>
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="associated-locations-label">Associated Locations</InputLabel>
      <Select
        labelId="associated-locations-label"
        multiple
        value={selectedLocationIds}
        onChange={handleChange}
        renderValue={renderValue}
      >
        {allLocations.map((location) => (
          <MenuItem key={location.location_id} value={location.location_id}>
            <Checkbox checked={selectedLocationIds.includes(location.location_id)} />
            <ListItemText primary={location.street ? `${location.street}, ${location.city}` : location.city} />
          </MenuItem>
        ))}
      </Select>
      <Typography variant="caption" color="textSecondary">
        Select locations that are associated with this service.
      </Typography>
    </FormControl>
  );
};

export default AssociatedLocationsSelector;
