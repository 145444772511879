import React from "react";
import OverviewDetail from "./OverviewDetail";
import LocationsList from "../LocationsList";
import ServicesList from "./ServicesList";

export default function BookingEntityOverview({ entity, refreshEntity }) {
  return (
    <>
      <OverviewDetail entity={entity} refreshEntity={refreshEntity} />
      <hr />
      <LocationsList entity={entity} />
      <hr />
      <ServicesList entity={entity} />
    </>
  );
}
