import React from "react";

const SelectServiceType = ({ field, label }) => {
  const serviceTypes = ["In-Clinic", "Virtual", "Home Visit", "Postal"];

  // Handle the change in checkboxes
  const handleChange = (type) => {
    const newSelection = [...field.value];
    const index = newSelection.indexOf(type);
    if (index > -1) {
      newSelection.splice(index, 1); // Remove item if already in the array
    } else {
      newSelection.push(type); // Add item if not in the array
    }
    field.onChange(newSelection); // Update form with new array
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        {serviceTypes.map((type, index) => (
          <div key={index} className="flex items-center space-x-2">
            <input
              type="checkbox"
              id={type}
              name="serviceType"
              value={type}
              checked={field.value.includes(type)}
              onChange={() => handleChange(type)}
              className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded"
            />
            <label htmlFor={type} className="min-w-0 flex-1 text-gray-500">
              {type}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectServiceType;
