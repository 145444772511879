import React, { useContext } from "react";
import { AdminContext } from "context/AdminContext";
import { Navigate, useLocation, Route } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const location = useLocation();

  if (!adminInfo?.email) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <Route {...rest} render={({ location }) => children} />;
  // return children;
};

export default PrivateRoute;
