import { patchRequest, postRequest } from "../helpers/requests";

const createTimeBlocks = async (data) => {
  return await postRequest("availabilities", data);
};

const deleteAvailabilitySlot = async (id, data) => {
  return await patchRequest(`availabilities/${id}`, data);
};

export { createTimeBlocks, deleteAvailabilitySlot };
