import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button, Grid } from "@mui/material";
import { postRequest } from "../../helpers/requests";

const LocationCreateModal = ({ open, handleClose, entity }) => {
  const [formData, setFormData] = useState({
    buildingName: "",
    street: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    longitude: "",
    latitude: "",
    bookingEntityId: entity.booking_entity_id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveChanges = async () => {
    try {
      const response = await postRequest("locations", formData);
      if (response.message === "Location created") {
        console.log("Location created successfully");
        handleClose();
      } else {
        console.error("Failed to create location");
      }
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          width: {
            xs: "90%",
            sm: "90%",
            md: "90%",
            lg: "1200px",
            xl: "1200px",
          },
          maxWidth: "100%",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          Create New Location
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Building Name"
              name="buildingName"
              value={formData.buildingName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Street" name="street" value={formData.street} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="City" name="city" value={formData.city} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="County" name="county" value={formData.county} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Postcode" name="postcode" value={formData.postcode} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Country" name="country" value={formData.country} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              value={formData.longitude}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Latitude" name="latitude" value={formData.latitude} onChange={handleChange} />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LocationCreateModal;
