import { useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Button, Card, CardBody } from "@windmill/react-ui";

//internal import
import PageTitle from "components/Typography/PageTitle";
import { notifyError, notifySuccess } from "utils/toast";
import spinnerLoadingImage from "assets/img/spinner.gif";
import { postRequest } from "../../helpers/requests";

const GroupBooking = () => {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const [fileData, setFileData] = useState([]);

  function toCamelCase(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  const preStyle = {
    whiteSpace: "pre-wrap", // Wraps the text and preserves whitespace and line breaks
    wordBreak: "break-word", // Ensures that words break correctly at the end of the line
    // overflowWrap: "break-word", // Allows unbreakable words to be broken
  };

  const handleFileChange = (file) => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "csv") {
        parseCSV(file);
      } else if (fileType === "xlsx") {
        parseXLSX(file);
      } else {
        alert("Unsupported file type. Please upload either a CSV or XLSX file.");
      }
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => toCamelCase(header),
      complete: (results) => {
        setFileData(results.data);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const parseXLSX = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Transform headers
      const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];
      const camelCaseHeaders = headers.map((header) => toCamelCase(header));

      // Parse data with transformed headers
      const dataObj = XLSX.utils.sheet_to_json(sheet, {
        header: camelCaseHeaders,
        skipHeader: true,
        raw: false,
        range: 1, // This skips the original headers row
      });
      setFileData(dataObj);
    };
    reader.readAsArrayBuffer(file);
  };

  const onSubmit = async (data) => {
    setLoadingUpload(true);

    let url;
    if (data.lab === "TDL") {
      url = "labs/TDL";
    }

    if (!url) {
      console.log("Form Data:", data);
      notifyError("Failed to create test request");
    }

    // Process or send data to the server
    const response = await postRequest(`${url}`, fileData);
    if (response.status === "success") {
      notifySuccess("Successfully requested");
      setLoadingUpload(false);
    } else {
      const errorMessage = response.message || "Failed to send test requests for group bookings";
      notifyError(errorMessage);
    }
  };

  return (
    <>
      <PageTitle>{"Group Bookings"}</PageTitle>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Upload a CSV or XLSX File</h1>
            <Controller
              name="file"
              control={control}
              defaultValue={[]}
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  type="file"
                  accept=".csv, .xlsx"
                  onChange={(e) => {
                    field.onChange(e);
                    handleFileChange(e.target.files[0]);
                  }}
                />
              )}
            />
            {errors.file && <p>File is required.</p>}

            <select {...register("lab", { required: true })}>
              <option value="" defaultValue hidden>
                Select lab
              </option>
              <option value="TDL">TDL</option>
            </select>
            {errors.lab && <p>Lab is required.</p>}

            {loadingUpload ? (
              <Button disabled={true} type="button" className=" mt-5 h-12 w-full">
                <img src={spinnerLoadingImage} alt="Loading" width={20} height={10} />{" "}
                <span className="font-serif ml-2 font-light">Processing</span>
              </Button>
            ) : (
              <button
                disabled={!fileData}
                type="submit"
                className={`${
                  !fileData && "opacity-50 cursor-not-allowed bg-red-300"
                } flex items-center justify-center text-sm leading-5 h-12 mt-5 w-full text-center transition-colors duration-150 font-medium focus:outline-none px-6 py-2 rounded-md text-white bg-green-500 border border-transparent active:bg-green-600 hover:bg-green-600 focus:ring focus:ring-purple-300`}
              >
                Submit
                <span className="ml-2 text-base">
                  <IoCloudUploadOutline />
                </span>
              </button>
            )}
          </form>
        </CardBody>
      </Card>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardBody>
          <h2>
            <b>File preview</b>
          </h2>
          <pre style={preStyle}>{fileData && fileData.length > 0 ? JSON.stringify(fileData, null, 2) : null}</pre>
        </CardBody>
      </Card>
    </>
  );
};

export default GroupBooking;
