import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "rc-tree/assets/index.css";
import "@pathofdev/react-tag-input/build/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Windmill } from "@windmill/react-ui";
import "./assets/css/custom.css";
import "./assets/css/tailwind.css";
import "./assets/css/tailwind.output.css";
import "./index.css";
import App from "./App";
import myTheme from "./assets/theme/myTheme";
import { AdminProvider } from "./context/AdminContext";
import { SidebarProvider } from "./context/SidebarContext";
import reportWebVitals from "./reportWebVitals";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import { Provider } from "react-redux";
import store from "./redux/Store";
import "./i18n";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AdminProvider>
      <SidebarProvider>
        <Provider store={store}>
          <Suspense fallback={<ThemeSuspense />}>
            <Windmill usePreferences theme={myTheme}>
              <App />
            </Windmill>
          </Suspense>
        </Provider>
      </SidebarProvider>
    </AdminProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

// if (process.env.NODE_ENV !== "production") {
//   const axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }
