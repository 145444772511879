import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { convertUnderscoresToCamelCase } from "../../helpers/convertToCamelCase";
import { notifyError } from "../../utils/toast";
import { patchRequest } from "../../helpers/requests";

const BookingEntityEditModal = ({ open, handleClose, entity, refreshEntity }) => {
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    if (entity) {
      setFormData(entity);
      setInitialData(entity);
    }
  }, [entity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveChanges = async () => {
    const changedData = Object.keys(formData).reduce((acc, key) => {
      const camelCaseKey = convertUnderscoresToCamelCase(key);
      if (formData[key] !== initialData[key]) {
        acc[camelCaseKey] = formData[key];
      }
      return acc;
    }, {});
    if (typeof changedData === "object" && Object.keys(changedData).length > 0) {
      try {
        const response = await patchRequest(`entities/${entity.booking_entity_id}`, changedData);
        console.log("response", response);
        if (response && response.message === "Booking entity updated") {
          console.log("Entity updated successfully");
          refreshEntity();
          handleClose();
        } else {
          notifyError("Failed to update entity");
        }
      } catch (error) {
        notifyError("Internal Server Error");
        console.error("Error updating entity:", error);
      }
    } else {
      notifyError("Nothing to update");
    }
  };

  const handleCancel = () => {
    setFormData(initialData);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: "600" }}>
          Edit Booking Entity
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Display Name"
              name="display_name"
              value={formData.display_name || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="is-published-label">Is Published</InputLabel>
              <Select
                labelId="is-published-label"
                id="is_published"
                name="is_published"
                value={formData.is_published || ""}
                label="Is Published"
                onChange={handleChange}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Email" name="email" value={formData.email || ""} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Website URL"
              name="website_url"
              value={formData.website_url || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Latitude"
              name="latitude"
              value={formData.latitude || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              value={formData.longitude || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Tags" name="tags" value={formData.tags || ""} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Type" name="type" value={formData.type || ""} onChange={handleChange} />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Ranking"
              name="ranking"
              value={formData.ranking || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={formData.phone_number || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description || ""}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              name="internal_notes"
              value={formData.internal_notes || ""}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Image URL"
              name="image_url"
              value={formData.image_url || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="caption" display="block">
            Created At: {new Date(entity.created_at).toLocaleString()}
          </Typography>
          <Typography variant="caption" display="block">
            Updated At: {new Date(entity.updated_at).toLocaleString()}
          </Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleCancel} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BookingEntityEditModal;
