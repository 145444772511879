import React from "react";
import { Select } from "@windmill/react-ui";

const SelectNameTitle = ({ setValue, register, name, label }) => {
  return (
    <>
      <Select
        onChange={(e) => setValue(e.target.value)}
        className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-white border-transparent focus:bg-white"
        name={name}
        {...register(`${name}`, {
          required: `${label} is required!`,
        })}
      >
        <option value="" defaultValue hidden>
          Please select one
        </option>
        <option value="Mr">Mr</option>
        <option value="Miss">Miss</option>
        <option value="Mrs">Mrs</option>
        <option value="Other">Other</option>
      </Select>
    </>
  );
};

export default SelectNameTitle;
