import { DateField, EmailField, Show, Tab, TabbedShowLayout, TextField } from "react-admin";

const CorporateShow = ({ ...props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Customer">
          <TextField source="id" label="Customer ID" emptyText="-" />
          <TextField source="first_name" emptyText="-" />
          <TextField source="last_name" emptyText="-" />
          <TextField source="gender" emptyText="-" />
          <DateField source="date_of_birth" emptyText="-" />
          <EmailField source="email_address" emptyText="-" />
          <TextField source="contact_number" emptyText="-" />
          <TextField source="company" emptyText="-" />
          <TextField source="occupation" emptyText="-" />
          <TextField source="department_name" emptyText="-" />
          <TextField source="notes" emptyText="-" />
          <DateField source="created_at" emptyText="-" />
        </Tab>
        <Tab label="Membership">
          <TextField source="membership_id" />
          <TextField source="membership_type" />
          <DateField source="membership_start" />
          <DateField source="membership_end" />
          <TextField source="membership_status" />
          <TextField source="membership_price" />
          <TextField source="billing_period" />
          <TextField source="internal_notes" />
          <DateField source="created_at" />
        </Tab>
        <Tab label="Company">
          <TextField source="company_id" label="Company ID" emptyText="-" />
          <TextField source="company_name" emptyText="-" />
          <TextField source="company_code" emptyText="-" />
          <TextField source="location" emptyText="-" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CorporateShow;
