import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { patchRequest, postRequest } from "../../helpers/requests";
import { notifyError, notifySuccess } from "../../utils/toast";

const constructAddressString = (address) => {
  const { buildingName, street, city, county, postcode, country } = address;
  const parts = [buildingName, street, city, county, postcode, country];
  return parts.filter((part) => part).join(", ");
};

const EntityApprovalModal = ({ open, handleClose, draft, draftId }) => {
  const [entityData, setEntityData] = useState({});
  const [addressString, setAddressString] = useState("");

  const currentUser = JSON.parse(localStorage.getItem("user_metadata"));

  useEffect(() => {
    if (draft && Object.keys(draft).length > 0) {
      setEntityData(draft);

      if (draft.addresses && draft.addresses.length > 0) {
        const addressObject = draft.addresses[0];
        const addressStr = constructAddressString(addressObject);
        setAddressString(addressStr);
      }
    }
  }, [draft]);

  const handleApproval = async () => {
    try {
      const response = await postRequest("entities/create", draft);
      if (response && response.status === "success") {
        const payload = {
          isApproved: true,
          approvedBy: currentUser ? currentUser.username : null,
        };
        patchRequest(`drafts/approve/${draftId}`, payload);
        notifySuccess("Entity has been approved and published");
        handleClose();
      } else {
        notifyError("Failed to approve this submission. Please try again");
      }
    } catch (error) {
      notifyError("Technical issue - Please try again in a few minutes!");
      console.error("Error updating service:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          Submitted Request (Non-editable)
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Booking Entity"
              name="display_name"
              value={entityData.bookingEntityName || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={entityData.isPublished || false} name="service_status" disabled={true} />}
              label="Is Published?"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={entityData.email || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Website URL"
              name="website_url"
              value={entityData.websiteUrl || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Latitude"
              name="latitude"
              value={entityData.latitude || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              value={entityData.longitude || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Tags"
              name="tags"
              value={entityData.tags || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Type"
              name="type"
              value={entityData.bookingTypes || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Ranking"
              name="ranking"
              value={entityData.ranking || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={entityData.phoneNumber || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={addressString || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={entityData.description || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              name="internal_notes"
              value={entityData.internalNotes || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Image URL"
              name="image_url"
              value={entityData.imageUrl || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              User Account Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Account Owner"
              name="full_name"
              value={entityData.bookingUser?.fullName || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Gender"
              name="gender"
              value={entityData.bookingUser?.gender || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Username"
              name="username"
              value={entityData.bookingUser?.account.username || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Reject
          </Button>
          <Button variant="contained" color="primary" onClick={handleApproval}>
            Approve
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EntityApprovalModal;
