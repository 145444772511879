import { redirect } from "react-router-dom";
import { postRequest } from "./requests";

const login = async ({ email, password }) => {
  const response = await postRequest("auth/login", { email, password });
  if (response.status === "success") {
    localStorage.setItem("email", email);
    localStorage.setItem("user_metadata", JSON.stringify(response.user)); // Improvement: use sessionStorage
    localStorage.setItem("permissions", JSON.stringify(response.access));
    return Promise.resolve();
  }

  return Promise.reject();
};

const logout = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("user_metadata");
  localStorage.removeItem("permissions");
  redirect("/login");
  return Promise.resolve();
};

const checkError = ({ status }) => {
  if (status === 401 || status === 403) {
    localStorage.removeItem("email");
    localStorage.removeItem("user_metadata");
    localStorage.removeItem("permissions");
    return Promise.reject();
  }
  return Promise.resolve();
};

const checkAuth = () => {
  return localStorage.getItem("email") ? Promise.resolve() : Promise.reject();
};

const getPermissions = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  return Promise.resolve(permissions);
};

const authProvider = {
  // called when the user attempts to log in
  login,
  // called when the user clicks on the logout button
  logout,
  // called when the API returns an error
  checkError,
  // called when the user navigates to a new location, to check for authentication
  checkAuth,
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions,
};

export default authProvider;
