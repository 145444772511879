import React from "react";
import { DateField, ReferenceField, Show, SimpleShowLayout, TextField, RichTextField } from "react-admin";

const CommentShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="booking_id" reference="bookings">
        <TextField source="title" />
      </ReferenceField>
      <DateField source="comment_date" />
      <RichTextField source="comment_content" />
    </SimpleShowLayout>
  </Show>
);

export default CommentShow;
