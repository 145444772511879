// AddCommentButton.jsx

import { Link } from "react-router-dom";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Button } from "react-admin";

const AddCommentButton = ({ classes, record, bookingId }) => (
  <Button
    className={classes}
    variant="raised"
    component={Link}
    to={`/comments/create?booking_id=${bookingId}`}
    label="Add a note"
    title="Add a comment"
  >
    <ChatBubbleIcon />
  </Button>
);

export default AddCommentButton;
