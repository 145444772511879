import { Datagrid, DateField, List, ShowButton, SimpleList, TextField } from "react-admin";
import { useMediaQuery } from "@mui/material";

const MembershipList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });
  return (
    <List>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.booking_name}
          secondaryText={(record) => record.membership_type}
          tertiaryText={(record) => record.membership_status}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <TextField source="id" label="Membership ID" />
          <TextField source="membership_type" />
          <DateField source="membership_start" />
          <DateField source="membership_end" />
          <TextField source="membership_status" />
          <TextField source="membership_price" />
          <TextField source="billing_period" />
          <DateField source="created_at" />
          <ShowButton />
        </Datagrid>
      )}
    </List>
  );
};

export default MembershipList;
