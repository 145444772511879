import { Admin, CustomRoutes, Resource } from "react-admin";
import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import simpleRestProvider from "ra-data-simple-rest";
import Users from "./components/users";
import Booking from "./components/booking";
import Postal from "./components/postal";
import Membership from "./components/memberships";
import Corporate from "./components/corporate";
import Transactions from "./components/transaction/transaction";
import authProvider from "./helpers/authProvider";
import Dashboard from "./pages/Dashboard";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import comments from "./components/comments";
import Calendar from "./components/calendar/admin";
import ClinicianCalendar from "./components/calendar/clinician";
import Documents from "./pages/Document/Documents";
import ComingSoon from "./pages/ComingSoon";
import ClinicianAvailability from "./pages/ClinicianAvailability";
import FAQs from "./pages/FAQs";
import SignUpBookingUser from "./pages/Register/BookingUser";
import SignUpBookingEntity from "./pages/Register/BookingEntity";
import BookingEntities from "./pages/BookingEntityManagement";
import BookingEntity from "./pages/BookingEntity";
import CreateService from "./pages/Register/CreateService";
import GroupBooking from "./pages/GroupBooking";
import Drafts from "./pages/DraftManagement";

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

const dataProvider = simpleRestProvider(`${process.env.REACT_APP_API_URL}`);

const Orders = () => <></>;

const App = () => {
  return (
    <>
      <ToastContainer />
      <Admin
        loginPage={Login}
        authProvider={authProvider}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        basename="/admin"
        layout={Layout}
      >
        {(permissions) => (
          <>
            {permissions.role === "Staff" && (
              <>
                <Resource name="customers" {...Users} />
                <Resource name="bookings/appointments" options={{ label: "Appointments" }} {...Booking} />
                <Resource name="customers/corporate" {...Corporate} />
                <Resource name="memberships" {...Membership} />
                <Resource name="comments" {...comments} />
                <Resource name="postals" options={{ label: "Postal" }} {...Postal} />
                <Resource name="payments/transactions" options={{ label: "Transactions" }} list={Transactions} />
                <Resource name="orders" list={Orders} />
                <Resource name="availability" list={Calendar} />
                <CustomRoutes>
                  <Route path="/drafts" element={<Drafts />} />
                  <Route path="/booking-entities" element={<BookingEntities />} />
                  <Route path="/booking-entities/:id" element={<BookingEntity />} />
                  <Route path="/availability/clinician" element={<ClinicianAvailability />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/booking-users" element={<SignUpBookingUser />} />
                  <Route path="/create-booking-entity" element={<SignUpBookingEntity />} />
                  <Route path="/create-service" element={<CreateService />} />
                  <Route path="/create-group-booking" element={<GroupBooking />} />
                  <Route path="/faqs" element={<FAQs />} />
                </CustomRoutes>
              </>
            )}
            {permissions.role === "Scheduler" && (
              <>
                <Resource name="bookings/appointments" options={{ label: "Appointments" }} {...Booking} />
                <Resource name="availability" list={Calendar} />
                <Resource name="postals" options={{ label: "Postal" }} {...Postal} />
                <CustomRoutes>
                  <Route path="/drafts" element={<Drafts />} />
                  <Route path="/booking-entities" element={<BookingEntities />} />
                  <Route path="/booking-entities/:id" element={<BookingEntity />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/availability/clinician" element={<ClinicianAvailability />} />
                  <Route path="/create-booking-entity" element={<SignUpBookingEntity />} />
                  <Route path="/create-service" element={<CreateService />} />
                  <Route path="/create-group-booking" element={<GroupBooking />} />
                  <Route path="/faqs" element={<FAQs />} />
                </CustomRoutes>
              </>
            )}
            {permissions.role === "Clinician" && (
              <>
                <Resource name="availability/clinician" list={ClinicianCalendar} />
                <CustomRoutes>
                  <Route path="/faqs" element={<ComingSoon />} />
                  <Route path="/booking-entity" element={<BookingEntity />} />
                </CustomRoutes>
              </>
            )}
          </>
        )}
      </Admin>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Routes>
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/alt"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
