import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { getRequest, postRequest } from "../../helpers/requests";

const HistoryModal = ({ open, onClose, customer, bookingEntityId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [comment, setComment] = useState("");
  const [operationalHistory, setOperationalHistory] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [medicalDocuments, setMedicalDocuments] = useState([]);

  useEffect(() => {
    if (open) {
      fetchOperationalHistory();
      fetchMedicalHistory();
      fetchMedicalDocuments();
    }
  }, [open]);

  const fetchOperationalHistory = async () => {
    const response = await getRequest(`customers/operation-notes/${customer.id}`);
    if (response.message === "Operation notes found") {
      setOperationalHistory(response.notes);
    } else {
      console.error("Error fetching operational notes:", response.message);
      setOperationalHistory([]);
    }
  };

  const fetchMedicalHistory = async () => {
    const response = await getRequest(`customers/medical-notes/${customer.id}`);
    if (response.message === "Medical notes found") {
      setMedicalHistory(response.notes);
    } else {
      console.error("Error fetching medical notes:", response.message);
      setMedicalHistory([]);
    }
  };

  const fetchMedicalDocuments = async () => {
    const response = await getRequest(`customers/documents/${customer.id}`);
    if (response.message === "Document records found") {
      setMedicalDocuments(response.notes);
    } else {
      console.error("Error fetching medical documents:", response.message);
      setMedicalDocuments([]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSend = async () => {
    let endpoint;
    let data;

    if (activeTab === 0) {
      endpoint = "customers/operation-notes/create";
      data = {
        customerId: customer.id,
        bookingEntityId: bookingEntityId,
        staffName: JSON.parse(localStorage.getItem("user_metadata"))?.full_name || "User",
        messageText: comment,
      };
    } else if (activeTab === 1) {
      endpoint = "customers/medical-notes/create";
      data = {
        customerId: customer.id,
        bookingEntityId: bookingEntityId,
        notes: comment,
      };
    } else if (activeTab === 2) {
      endpoint = "customers/documents/create";
      // TODO: File upload
    }

    await postRequest(endpoint, data);
    setComment("");

    if (activeTab === 0) {
      fetchOperationalHistory();
    } else if (activeTab === 1) {
      fetchMedicalHistory();
    } else if (activeTab === 2) {
      fetchMedicalDocuments();
    }
  };

  const renderHistoryItem = (item, index) => (
    <ListItem key={index} alignItems="flex-start" sx={{ mb: 2, px: 0 }}>
      <ListItemAvatar>
        {item.avatar ? (
          <Avatar src={item.avatar} alt={item.sender} />
        ) : (
          <Avatar>
            <AccountCircle />
          </Avatar>
        )}
      </ListItemAvatar>
      <Box sx={{ ml: 0, flexGrow: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {item.staff_name || item.sender_name}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          Sent{" "}
          {new Date(item.created_at).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}{" "}
          at{" "}
          {new Date(item.created_at).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            p: 2,
            borderRadius: 1,
            mt: 1,
          }}
        >
          <Typography variant="body2">{item.message_text || item.notes}</Typography>
        </Box>
      </Box>
    </ListItem>
  );

  const renderDocumentItem = (item, index) => (
    <ListItem key={index} alignItems="flex-start" sx={{ mb: 2, px: 0 }}>
      <ListItemAvatar>
        <Avatar>
          <AccountCircle />
        </Avatar>
      </ListItemAvatar>
      <Box sx={{ ml: 0, flexGrow: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {item.file_name}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          Type: {item.document_type}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          Created at:{" "}
          {new Date(item.created_at).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </Typography>
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            p: 2,
            borderRadius: 1,
            mt: 1,
          }}
        >
          {console.log("item", item.customer_access)}
          <Typography variant="body2">Customer access: {item.customer_access ? "True" : "False"}</Typography>
          <Typography variant="body2">Deletion Request: {item.deletion_request ? "True" : "False"}</Typography>
        </Box>
      </Box>
    </ListItem>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="history-modal-title"
      aria-describedby="history-modal-description"
    >
      <Box
        sx={{
          width: 600,
          bgcolor: "background.paper",
          p: 4,
          mx: "auto",
          mt: "10%",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Typography id="history-modal-title" variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          History of {customer.name}
        </Typography>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="Operational History" />
          <Tab label="Medical History" />
          <Tab label="Medical Documents" />
        </Tabs>
        <Box sx={{ mt: 2, maxHeight: 400, overflowY: "auto" }}>
          {activeTab === 0 &&
            (operationalHistory.length > 0 ? (
              <List>{operationalHistory.map((item, index) => renderHistoryItem(item, index))}</List>
            ) : (
              <Typography sx={{ py: 5, color: "gray", textAlign: "center" }}>
                This customer doesn't have any operational notes yet.
              </Typography>
            ))}
          {activeTab === 1 &&
            (medicalHistory.length > 0 ? (
              <List>{medicalHistory.map((item, index) => renderHistoryItem(item, index))}</List>
            ) : (
              <Typography sx={{ py: 5, color: "gray", textAlign: "center" }}>
                This customer doesn't have any medical notes yet.
              </Typography>
            ))}
          {activeTab === 2 &&
            (medicalDocuments.length > 0 ? (
              <List>{medicalDocuments.map((item, index) => renderDocumentItem(item, index))}</List>
            ) : (
              <Typography sx={{ py: 5, color: "gray", textAlign: "center" }}>
                This customer doesn't have any medical documents yet.
              </Typography>
            ))}
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Type a comment"
            value={comment}
            onChange={handleCommentChange}
            sx={{ mb: 2 }}
            multiline
            rows={3}
            disabled={activeTab === 2}
          />
          <Button variant="contained" color="primary" onClick={handleSend} disabled={activeTab === 2}>
            Send
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default HistoryModal;
