import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const appointmentMapper = (data) => {
  let bookingLocation;
  if (data.selectedLocation.city === "Virtual") {
    bookingLocation = "Virtual";
  } else if (data.selectedLocation.city === "Postal") {
    bookingLocation = "Postal";
  } else if (data.selectedLocation.city === "Home Visit") {
    bookingLocation = "Home Visit";
  } else {
    bookingLocation = [
      data.selectedLocation.building_name,
      data.selectedLocation.street,
      data.selectedLocation.city,
      data.selectedLocation.postcode,
      data.selectedLocation.country,
    ].join(", ");
  }
  return {
    isLocationOnline: data.selectedLocation.city === "Virtual",
    serviceId: data.selectedService.service_code,
    emailTemplate: data.selectedService.email_template,
    serviceName: data.selectedService.service_name,
    serviceType: data.selectedService.service_type,
    price: data.selectedService.service_price / 100,
    serviceNotes: data.appointmentNotes || "",
    clinician: {
      clinicianName: data.selectedClinician ? data.selectedClinician.first_name : null,
      clinicianId: data.selectedClinician ? data.selectedClinician.user_id : null,
      clinicianEmail: data.selectedClinician ? data.selectedClinician.username : null,
    },
    doctor: {
      doctorId: data.doctorId || null,
    },
    startDateTime: {
      dateTime: dayjs(data.startTime).format("YYYY-MM-DDTHH:mm:ss"),
      timeZone: "Europe/London",
    },
    endDateTime: {
      dateTime: dayjs(data.endTime).format("YYYY-MM-DDTHH:mm:ss"),
      timeZone: "Europe/London",
    },
    serviceLocation: {
      displayName: bookingLocation,
      regionId: data.selectedLocation.location_category_id,
      locationId: data.selectedLocation.location_id,
    },
    bookingEntity: {
      entityId: data.selectedLocation.booking_entity_id || null,
    },
    customers: [
      {
        name: data.selectedCustomer.first_name + " " + data.selectedCustomer.last_name,
        emailAddress: data.selectedCustomer.email_address,
        gender: data.selectedCustomer.gender,
        dateOfBirth: data.selectedCustomer.date_of_birth,
        phone: data.selectedCustomer.contact_number || "",
        customerNotes: data.appointmentNotes || "",
      },
    ],
  };
};

export default appointmentMapper;
