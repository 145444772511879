import React, { useState } from "react";
import { Select } from "@windmill/react-ui";
import moment from "moment-timezone";
import { postRequest } from "../../../helpers/requests";
import { notifyError, notifySuccess } from "../../../utils/toast";
import "./availability.css";

const timeZone = "Europe/London";
const OpeningHoursForm = ({ closeForm }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(5);
  const [repeatWeeks, setRepeatWeeks] = useState(0);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setEndDate("");
    setStartTime("");
    setEndTime("");
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleRepeatWeekChange = (e) => {
    setRepeatWeeks(e.target.value);
  };

  const handleCreateAvailability = async () => {
    try {
      const weeksToRepeat = parseInt(repeatWeeks, 10);
      const shouldRepeat = !isNaN(weeksToRepeat) && weeksToRepeat > 0;

      const user_metadata = JSON.parse(localStorage.getItem("user_metadata"));
      const userId = user_metadata.user_id;
      const roleId = user_metadata.role_id;
      const payloads = [];

      // Calculate the number of iterations based on whether we need to repeat or not
      const totalIterations = shouldRepeat ? weeksToRepeat + 1 : 1;

      for (let week = 0; week < totalIterations; week++) {
        let weekStartDate, weekEndDate;

        if (week > 0) {
          // If repeating, calculate new dates
          const convertedWeekStartDate = moment.tz(startDate, timeZone);
          weekStartDate = convertedWeekStartDate.add(week * 7, "days").format("YYYY-MM-DD"); // Adding 7 days
          const convertedWeekEndDate = moment.tz(endDate, timeZone);
          weekEndDate = convertedWeekEndDate.add(week * 7, "days").format("YYYY-MM-DD"); // Adding 7 days
        } else {
          // If not repeating, use the provided startDate and endDate
          weekStartDate = moment.tz(startDate, timeZone).format("YYYY-MM-DD");
          weekEndDate = moment.tz(endDate, timeZone).format("YYYY-MM-DD");
        }

        const payload = {
          startDate: weekStartDate,
          endDate: weekEndDate,
          startTime,
          endTime,
          locationCategoryID: selectedLocation,
          doctorID: roleId === 4 ? userId : null,
          clinicianID: roleId === 2 ? userId : null,
          bookingEntityID: user_metadata.booking_entity_id || null,
        };

        payloads.push(payload);
      }

      const response = await postRequest("bookings/availability", payloads);

      if (response) {
        notifySuccess("Availability created");
        closeForm();
      }
    } catch (error) {
      console.log(error);
      notifyError("Failed to create availability");
    }
  };

  const handleCancel = () => {
    if (closeForm) closeForm();
  };

  return (
    <div className="availability-container">
      <div>
        <div className="date-picker">
          <label>Start Date:</label>
          <input type="date" value={startDate} onChange={handleStartDateChange} />
        </div>
        <div className="date-picker">
          <label>End Date:</label>
          <input type="date" value={endDate} onChange={handleEndDateChange} />
        </div>
        <div className="date-picker">
          <label>Start Time:</label>
          <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
        </div>
        <div className="date-picker">
          <label>End Time:</label>
          <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
        </div>
      </div>
      <div className="date-picker">
        <label>Repeat For:</label>
        <Select value={repeatWeeks} onChange={handleRepeatWeekChange}>
          <option value="0">No repeats</option>
          <option value="1">Repeat for following week</option>
          <option value="2">Repeat for 2 weeks</option>
          <option value="3">Repeat for 3 weeks</option>
          <option value="4">Repeat for 4 weeks</option>
          <option value="5">Repeat for 5 weeks</option>
        </Select>
      </div>
      <div className="mb-5">
        <h6>Please note the repeats will be based on the days and time entered above</h6>
      </div>

      <div className="availability-buttons">
        <button
          type="submit"
          className="h-12 bg-green w-full rounded-t-lg border-b-2 text-green-600 border-green-600 dark:text-green-500 dark:border-green-500"
          onClick={handleCreateAvailability}
        >
          Create Availability
        </button>
        <button
          className="h-12 bg-white w-full text-red-500 hover:bg-red-50 hover:border-red-100 hover:text-red-600 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-500 dark:hover:bg-gray-800 dark:hover:text-red-700"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default OpeningHoursForm;
