import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { postRequest } from "../../helpers/requests";
import { notifyError, notifySuccess } from "../../utils/toast";

const BookingEntityCreateModal = ({ open, handleClose }) => {
  const [isVirtual, setIsVirtual] = useState(false);
  const [isInClinic, setIsInClinic] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("user_metadata"));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      addresses: [
        { buildingName: "", street: "", city: "", country: "", postcode: "", county: "", latitude: "", longitude: "" },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  const onSubmit = async (data) => {
    const bookingTypes = [];
    if (isInClinic) {
      bookingTypes.push("In-Clinic");
    }
    if (isVirtual) {
      bookingTypes.push("Virtual");
    }
    if (isHome) {
      bookingTypes.push("Home Visit");
    }

    const payload = {
      draftType: "entity",
      action: "CREATE",
      draft: {
        ...data,
        bookingTypes: bookingTypes,
        addresses: data.addresses,
      },
      createdBy: currentUser ? currentUser.username : null,
    };

    const response = await postRequest("drafts/create", payload);
    if (response.status === "success") {
      notifySuccess("Booking entity creation submitted - Pending Approval");
      handleClose();
    } else {
      const errorMessage = response.message || "Failed to create booking entity";
      notifyError(errorMessage);
    }
  };

  const locationOptions = [
    { value: 1, label: "London" },
    { value: 2, label: "Edinburgh" },
    { value: 3, label: "Manchester" },
    { value: 5, label: "Home Visit" },
    { value: 6, label: "Virtual" },
    { value: 7, label: "Glasgow" },
    { value: 9, label: "Cardiff" },
    { value: 10, label: "Belfast" },
    { value: 11, label: "Cambridge" },
  ];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          width: {
            xs: "90%",
            sm: "90%",
            md: "90%",
            lg: "1200px",
            xl: "1200px",
          },
          maxWidth: "100%",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          Create Booking Entity
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox checked={isInClinic} onChange={(e) => setIsInClinic(e.target.checked)} />}
                label="In-Clinic"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox checked={isVirtual} onChange={(e) => setIsVirtual(e.target.checked)} />}
                label="Virtual"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox checked={isHome} onChange={(e) => setIsHome(e.target.checked)} />}
                label="Home Visit"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="bookingEntityName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Booking Entity Name"
                    error={!!errors.bookingEntityName}
                    helperText={errors.bookingEntityName ? "Booking Entity Name is required" : ""}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Clinic Description"
                    multiline
                    rows={4}
                    error={!!errors.description}
                    helperText={errors.description ? "Description is required" : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="latitude"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Latitude"
                    error={!!errors.latitude}
                    helperText={errors.latitude ? "Latitude is required" : "For the booking entity map marker"}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="longitude"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Longitude"
                    error={!!errors.longitude}
                    helperText={errors.longitude ? "Longitude is required" : "For the booking entity map marker"}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Public Phone Number"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber ? "Phone Number is required" : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Public Email"
                    error={!!errors.email}
                    helperText={errors.email ? "Email is required" : ""}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="websiteUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Website URL"
                    error={!!errors.websiteUrl}
                    helperText={errors.websiteUrl ? "Website URL is required" : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <TextField {...field} fullWidth label="Tags" helperText="Up to 5 tags separated by commas" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="ranking"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Ranking"
                    type="number"
                    error={!!errors.ranking}
                    helperText={errors.ranking ? "Ranking is required" : ""}
                  />
                )}
              />
            </Grid>
            {fields.map((_, index) => (
              <Box
                key={`address-${index}`}
                sx={{
                  border: "2px solid #d1d4d8",
                  padding: 2,
                  marginTop: 2,
                  borderRadius: 2,
                  width: "100%",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Address {index + 1}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].buildingName`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Building Name/Number"
                          error={!!errors?.addresses?.[index]?.buildingName}
                          helperText={
                            errors?.addresses?.[index]?.buildingName ? "Building Name/Number is required" : ""
                          }
                        />
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].street`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Street"
                          error={!!errors?.addresses?.[index]?.street}
                          helperText={errors?.addresses?.[index]?.street ? "Street is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].city`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="City"
                          error={!!errors?.addresses?.[index]?.city}
                          helperText={errors?.addresses?.[index]?.city ? "City is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].county`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="County"
                          error={!!errors?.addresses?.[index]?.county}
                          helperText={errors?.addresses?.[index]?.county ? "County is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].postcode`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Postcode"
                          error={!!errors?.addresses?.[index]?.postcode}
                          helperText={errors?.addresses?.[index]?.postcode ? "Postcode is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].country`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Country"
                          error={!!errors?.addresses?.[index]?.country}
                          helperText={errors?.addresses?.[index]?.country ? "Country is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].latitude`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Latitude"
                          error={!!errors?.addresses?.[index]?.latitude}
                          helperText={errors?.addresses?.[index]?.latitude ? "Latitude is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic || isHome }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`addresses[${index}].longitude`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Longitude"
                          error={!!errors?.addresses?.[index]?.longitude}
                          helperText={errors?.addresses?.[index]?.longitude ? "Longitude is required" : ""}
                        />
                      )}
                      rules={{ required: isInClinic || isHome }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`addresses[${index}].locationCategoryId`}
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel>Location Category</InputLabel>
                          <Select {...field} error={!!errors?.addresses?.[index]?.locationCategoryId}>
                            {locationOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      rules={{ required: isInClinic }}
                    />
                  </Grid>
                  {index > 0 && (
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <Button onClick={() => remove(index)}>Remove Address</Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={() =>
                  append({
                    buildingName: "",
                    street: "",
                    city: "",
                    country: "",
                    postcode: "",
                    county: "",
                    latitude: "",
                    longitude: "",
                  })
                }
              >
                Add Another Address
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Booking User Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="bookingUser.fullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Contacts Full Name"
                    error={!!errors.bookingUser?.fullName}
                    helperText={errors.bookingUser?.fullName ? "Full Name is required" : ""}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="bookingUser.gender"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select {...field} error={!!errors.bookingUser?.gender}>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="bookingUser.dateOfBirth"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Date of Birth"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="bookingUser.phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Direct Phone Number"
                    error={!!errors.bookingUser?.phoneNumber}
                    helperText={errors.bookingUser?.phoneNumber ? "Phone Number is required" : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="bookingUser.account.username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Direct Email"
                    error={!!errors.bookingUser?.account?.username}
                    helperText={errors.bookingUser?.account?.username ? "Email is required" : ""}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="bookingUser.account.password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Password"
                    type="password"
                    error={!!errors.bookingUser?.account?.password}
                    helperText={errors.bookingUser?.account?.password ? "Password is required" : ""}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? "Password confirmation is required" : ""}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Create Booking Entity
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default BookingEntityCreateModal;
