import React, { useEffect, useState } from "react";
import { Title } from "react-admin";
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { getRequestWithHeaders, getRequest } from "../../helpers/requests";
import ServiceApprovalModal from "../../components/ApprovalModal/ServiceApprovalModal";
import EntityApprovalModal from "../../components/ApprovalModal/EntityApprovalModal";

const Drafts = () => {
  const [drafts, setDrafts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [draftType, setDraftType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const fetchDrafts = async (page, rowsPerPage) => {
    try {
      setLoading(true);
      const response = await getRequestWithHeaders(`drafts`, { limit: rowsPerPage, page: page + 1 });
      const data = response.data;
      const contentRange = response.headers.get("Content-Range");

      if (contentRange) {
        const [range, total] = contentRange.split("/");
        setTotalRows(parseInt(total, 10));
      } else {
        console.error("Content-Range header is missing");
      }

      setDrafts(data.drafts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching drafts: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDrafts(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDraft = (draftId) => {
    const fetchSingleDraft = async (draftId) => {
      try {
        const response = await getRequest(`/drafts/${draftId}`);
        setSelectedDraft(response.draft);
        setDraftType(response.draft.draft_type);
        setModalOpen(true);
      } catch (error) {
        console.error("Error fetching draft details: ", error);
      }
    };
    fetchSingleDraft(draftId);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedDraft(null);
    fetchDrafts(page, rowsPerPage);
  };

  return (
    <>
      <Title title="Listing submissions for approval" />
      <Container maxWidth="false" sx={{ marginTop: "18px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Action Performed</TableCell>
                <TableCell>Draft Type</TableCell>
                <TableCell>Is Approved?</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (
                drafts.map((draft) => (
                  <TableRow key={draft.draft_id}>
                    <TableCell>{draft.draft_id || "-"}</TableCell>
                    <TableCell>{draft.action || "-"}</TableCell>
                    <TableCell>{draft.draft_type || "-"}</TableCell>
                    <TableCell>{draft.is_approved !== null ? (draft.is_approved ? "Yes" : "No") : "-"}</TableCell>
                    <TableCell>{draft.created_by || "-"}</TableCell>
                    <TableCell>{draft.created_at}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleOpenDraft(draft.draft_id)}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
      {selectedDraft && draftType === "service" && (
        <ServiceApprovalModal
          open={modalOpen}
          handleClose={handleClose}
          draft={selectedDraft.draft}
          draftId={selectedDraft.draft_id}
        />
      )}
      {selectedDraft && draftType === "entity" && (
        <EntityApprovalModal
          open={modalOpen}
          handleClose={handleClose}
          draft={selectedDraft.draft}
          draftId={selectedDraft.draft_id}
        />
      )}
    </>
  );
};

export default Drafts;
