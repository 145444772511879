import { useEffect, useRef, useState } from "react";
import "./styles.css";

const SearchableDropdown = ({ options, id, selectedVal, handleChange, dropdownType }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) {
      if (dropdownType === "customer") {
        return `${selectedVal["customer_id"]} - ${selectedVal["first_name"]} ${selectedVal["last_name"]}`;
      }
      if (dropdownType === "services") {
        return `${selectedVal.service_id} - ${selectedVal.service_name}`;
      }
      if (dropdownType === "location") {
        if (selectedVal.is_custom) {
          return "Custom Address";
        } else {
          return `${selectedVal["location_id"]} - ${selectedVal["city"]}, ${selectedVal["postcode"]}`;
        }
      }
      if (dropdownType === "clinician") {
        const displayName = selectedVal["full_name"] || `${selectedVal["first_name"]} ${selectedVal["last_name"]}`;
        return `${selectedVal["user_id"]} - ${displayName}`;
      }
    }

    return "";
  };

  const filter = (options) => {
    if (dropdownType === "customer") {
      return options.filter((option) => option["first_name"].toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
    if (dropdownType === "services") {
      return options.filter((option) => option["service_name"].toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
    if (dropdownType === "location") {
      return options.filter((option) =>
        [option["city"], option["building_name"], option["street"], option["postcode"]]
          .filter(Boolean) // Filters out any null, undefined, or empty string values
          .some((value) => value.toLowerCase().indexOf(query.toLowerCase()) > -1),
      );
    }
    if (dropdownType === "clinician") {
      return options.filter((option) => {
        const fullName = option["full_name"] || `${option["first_name"]} ${option["last_name"]}`;
        return fullName.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
    }
  };

  return (
    <div className="searchable-dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            autoComplete="off"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
            placeholder="Search something"
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {dropdownType === "customer" && (
          <div className={`option default-option ${!selectedVal ? "selected" : ""}`} onClick={() => selectOption(null)}>
            Select a customer
          </div>
        )}
        {dropdownType === "services" && (
          <div className={`option default-option ${!selectedVal ? "selected" : ""}`} onClick={() => selectOption(null)}>
            Select a service
          </div>
        )}
        {dropdownType === "location" && (
          <div className={`option default-option ${!selectedVal ? "selected" : ""}`} onClick={() => selectOption(null)}>
            Select a location
          </div>
        )}
        {dropdownType === "clinician" && (
          <div className={`option default-option ${!selectedVal ? "selected" : ""}`} onClick={() => selectOption(null)}>
            Select a clinician (Only for home visits)
          </div>
        )}
        {filter(options).map((option, index) => {
          return (
            <>
              {dropdownType === "customer" && (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${option["first_name"] === selectedVal ? "selected" : ""}`}
                  key={`${id}-${index}`}
                >
                  {`${option["first_name"]} ${option["last_name"]} (${option["email_address"]})`}
                </div>
              )}
              {dropdownType === "services" && (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${option.service_name === selectedVal ? "selected" : ""}`}
                  key={`${id}-${index}`}
                >
                  {`${option["service_id"]} - ${option["service_name"]}`}
                </div>
              )}
              {dropdownType === "location" && (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${option["city"] === selectedVal ? "selected" : ""}`}
                  key={`${id}-${index}`}
                >
                  {[
                    option["building_name"],
                    option["street"],
                    option["city"],
                    option["postcode"],
                    option["display_name"] && <b key="displayName">({option["display_name"]})</b>,
                  ]
                    .filter(Boolean) // Filters out null, undefined and empty string
                    .reduce((acc, curr) => (acc === null ? [curr] : [...acc, ", ", curr]), null)}
                </div>
              )}
              {dropdownType === "clinician" && (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${
                    (option["full_name"] && option["full_name"] === selectedVal) ||
                    `${option["first_name"]} ${option["last_name"]}` === selectedVal
                      ? "selected"
                      : ""
                  }`}
                  key={`${id}-${index}`}
                >
                  {`${option["user_id"]} - ${option["full_name"] || `${option["first_name"]} ${option["last_name"]}`}`}
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
