import { Create, DateInput, TextInput, SimpleForm } from "react-admin";
import { useSearchParams } from "react-router-dom";
import { RichTextInput } from "ra-input-rich-text";

const today = new Date();

const CommentCreate = () => {
  const [searchParams] = useSearchParams();
  const booking_id = searchParams.get("booking_id");

  const redirect = booking_id ? `/comments/${booking_id}/show/comments` : false;

  return (
    <Create>
      <SimpleForm
        defaultValue={{ comment_date: today, comment_root_id: booking_id, comment_type: "appointment" }}
        redirect={redirect}
      >
        <TextInput source="comment_root_id" defaultValue={booking_id} label="Booking ID" disabled />
        <TextInput disabled source="comment_type" defaultValue="appointment" />
        <TextInput source="user_id" defaultValue="12" disabled />
        <DateInput source="comment_date" defaultValue={today} />
        <RichTextInput multiline source="comment_content" label="Note Content" />
      </SimpleForm>
    </Create>
  );
};

export default CommentCreate;
