import { ListButton, Toolbar } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Button, Input, Label, Select } from "@windmill/react-ui";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../helpers/requests";
import SearchableDropdown from "../searchable-dropdown";
import appointmentMapper from "helpers/bookingMapper";
import { notifyError } from "../../utils/toast";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

const ActionButtons = () => (
  <Toolbar>
    <ListButton label="Bookings" />
  </Toolbar>
);
const BookingCreate = (props) => {
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);
  const [clinicians, setClinicians] = useState([]);
  const [customAddressEnabled, setCustomAddressEnabled] = useState(false);
  const [customAddress, setCustomAddress] = useState("");

  const [formData, setFormData] = useState({
    selectedCustomer: null,
    selectedService: null,
    selectedLocation: null,
    selectedClinician: null,
    startTime: "",
    endTime: "",
    bookingStatus: "",
  });

  useEffect(() => {
    const fetchLocationList = async () => {
      const response = await getRequest("bookings/location");
      if (response) {
        setLocations([{ id: "custom", city: "Enter a Custom Address" }, ...response]); // Add custom address option
      }
    };
    fetchLocationList();
  }, []);

  useEffect(() => {
    const fetchCustomerList = async () => {
      const response = await getRequest("customers/list");
      if (response) {
        setCustomers(response);
      }
    };
    fetchCustomerList();
  }, []);

  useEffect(() => {
    const fetchServiceList = async () => {
      const response = await getRequest("products/all");
      if (response) {
        setServices(response.services);
      }
    };
    fetchServiceList();
  }, []);

  useEffect(() => {
    const fetchClinicianList = async () => {
      try {
        const response = await getRequest("users/clinicians");
        if (response) {
          setClinicians(response.clinician);
        }
      } catch (error) {
        notifyError("Failed to fetch clinicians");
      }
    };
    fetchClinicianList();
  }, []);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    // Handle custom address logic
    if (field === "selectedLocation" && value && value.id === "custom") {
      setCustomAddressEnabled(true);
      // Set a placeholder or default for custom address instead of undefined
      setFormData((prevData) => ({
        ...prevData,
        selectedLocation: { is_custom: true }, // or any label prefer for custom address
      }));
    } else if (field === "selectedLocation") {
      setCustomAddressEnabled(false);
      setCustomAddress(""); // Clear custom address if a predefined location is selected
    }
  };

  const handleCustomAddressChange = (value) => {
    setCustomAddress(value); // Update the custom address state when the user types in the input field
  };

  const handleSubmit = () => {
    const appointmentRequest = {};
    appointmentRequest["requestBooking"] = appointmentMapper(formData);

    // If custom address is entered, replace location in formData with the custom address
    if (customAddressEnabled) {
      appointmentRequest["requestBooking"]["serviceLocation"]["displayName"] = customAddress;
    } else {
      appointmentRequest["locationId"] = formData.selectedLocation.id;
    }

    appointmentRequest["customerId"] = formData.selectedCustomer.customer_id;
    const response = postRequest("bookings/appointments", appointmentRequest);
    if (response) {
      return navigate("/bookings/appointments");
    }
  };

  return (
    <>
      <ActionButtons />
      <div className="mt-5 mb-5">
        <Label>Customer*</Label>
        <SearchableDropdown
          options={customers}
          label="name"
          id="id"
          selectedVal={formData.selectedCustomer}
          handleChange={(val) => handleChange("selectedCustomer", val)}
          dropdownType="customer"
        />
      </div>
      <div className="mb-5">
        <Label>Services*</Label>
        <SearchableDropdown
          options={services}
          label="service"
          id="id"
          selectedVal={formData.selectedService}
          handleChange={(val) => handleChange("selectedService", val)}
          dropdownType="services"
        />
      </div>
      <div className="mb-5">
        <Label>Location*</Label>
        <SearchableDropdown
          options={locations}
          label="location"
          id="id"
          selectedVal={formData.selectedLocation}
          handleChange={(val) => handleChange("selectedLocation", val)}
          dropdownType="location"
        />
      </div>

      {/* Conditionally render the custom address input field */}
      {customAddressEnabled && (
        <div className="mb-5">
          <Label>
            <span>Custom Address</span>
            <Input
              type="text"
              value={customAddress}
              onChange={(e) => handleCustomAddressChange(e.target.value)}
              disabled={!customAddressEnabled}
              placeholder="Enter the custom address"
            />
          </Label>
        </div>
      )}

      <div className="mb-5">
        <Label>Clinician (For home visit only)</Label>
        <SearchableDropdown
          options={clinicians}
          label="clinician"
          id="id"
          selectedVal={formData.selectedClinician}
          handleChange={(val) => handleChange("selectedClinician", val)}
          dropdownType="clinician"
        />
      </div>
      <div className="mb-5">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DateTimePicker
            label="Appointment Start Time*"
            slotProps={{ textField: { fullWidth: true } }}
            value={formData.startTime ? dayjs(formData.startTime) : null}
            ampm={false}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDateTime = dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss");
                handleChange("startTime", formattedDateTime);
              }
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="mb-5">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DateTimePicker
            label="Appointment End Time*"
            slotProps={{ textField: { fullWidth: true } }}
            value={formData.endTime ? dayjs(formData.endTime) : null}
            ampm={false}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDateTime = dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss");
                handleChange("endTime", formattedDateTime);
              }
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="mb-5">
        <Label>Booking Status*</Label>
        <Select
          className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
          value={formData.bookingStatus}
          onChange={(e) => handleChange("bookingStatus", e.target.value)}
        >
          <option value="status" defaultValue hidden>
            {"Status"}
          </option>
          <option value="booked">{"Booked"}</option>
          <option value="pending">{"Pending Payment"}</option>
          <option value="canceled">{"Canceled"}</option>
          <option value="completed">{"Completed"}</option>
        </Select>
      </div>
      <div className="mb-5">
        <Button block onClick={handleSubmit}>
          Create appointment
        </Button>
      </div>
    </>
  );
};
export default BookingCreate;
