import {
  BooleanField,
  DateField,
  EmailField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";

const MembershipShow = ({ props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Benefits">
          <TextField source="benefit_id" />
          <TextField source="membership_id" />
          <NumberField source="service_discount" label="Service discount (%)" emptyText="0" />
          <TextField source="free_gp_consultation" emptyText="0" />
          <TextField source="specialist_imaging_referral" emptyText="0" />
          <TextField source="medical_interpreter_used" emptyText="0" />
          <TextField source="medical_insurance_claim" emptyText="0" />
          <BooleanField source="benefit_status" />
          <BooleanField source="allow_sharing" />
          <DateField source="created_at" />
        </Tab>
        <Tab label="Customer">
          <ReferenceField label="Customer ID" source="customer_id" reference="customers" target="id">
            <TextField source="id" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Full Name" source="customer_id" reference="customers" target="id">
            <FunctionField
              label="Customer Name"
              render={(record) => `${record.first_name} ${record.last_name}`}
              emptyText="-"
            />
          </ReferenceField>
          <ReferenceField label="Gender" source="customer_id" reference="customers" target="id">
            <TextField source="gender" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Date Of Birth" source="customer_id" reference="customers" target="id">
            <DateField source="date_of_birth" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Email Address" source="customer_id" reference="customers" target="id">
            <EmailField label="Email" source="username" emptyText="-" />
          </ReferenceField>
          <ReferenceField label="Contact Number" source="customer_id" reference="customers" target="id">
            <TextField source="contact_number" emptyText="-" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default MembershipShow;
