// BookingEdit.jsx

import { DateTimeInput, Edit, SelectInput, SimpleForm, TextInput } from "react-admin";

const BookingEdit = (props) => (
  <Edit {...props} redirect="show">
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="booking_name" disabled />
      <TextInput source="service_name" disabled />
      {/*<ReferenceInput source="service_id" reference="services">*/}
      {/*  <SelectInput optionText="name" />*/}
      {/*</ReferenceInput>*/}
      <TextInput source="appt_location" />
      <DateTimeInput source="appt_start_dt" />
      <DateTimeInput source="appt_end_dt" />
      {/* <TextInput source="order_item_id" /> */}
      {/* <BooleanInput source="is_confirmed" />
      <BooleanInput source="is_paid" /> */}
      <SelectInput
        source="booking_status"
        choices={[
          { id: "booked", name: "Booked" },
          { id: "pending", name: "Pending Payment" },
          { id: "canceled", name: "Canceled" },
          { id: "completed", name: "Completed" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default BookingEdit;
